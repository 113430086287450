import React from 'react';
import { Typography, Avatar, Box, Button, Switch } from '@material-ui/core';
import useStyles from './IconActionList.styles';
import useBreakpoints from 'src/hooks/useBreakpoints';

const IconActionList = ({
  icon,
  label,
  subLabel,
  useSwitch,
  switchChecked,
  onSwitchChange,
  verified,
  onChangeClick,
}) => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();

  const renderButton = () => {
    return (
      <>
        {useSwitch && (
          <Switch
            color="primary"
            checked={switchChecked}
            onChange={onSwitchChange}
          />
        )}
        {!useSwitch && (
          <Button className={classes.changeButton} onClick={onChangeClick}>
            Change
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <Box p={5} display="flex" width="100%">
        <>
          <Avatar
            style={{
              width: '50px',
              height: '50px',
              color: 'black',
              backgroundColor: '#F2F3FA',
            }}
          >
            {icon}
          </Avatar>

          <Box pl={2} display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <Typography variant="h4" className={classes.label}>
                {label}
              </Typography>
              {verified > 0 && (
                <>
                  <Box style={{ paddingRight: 10 }} />
                  <Typography
                    variant="caption"
                    align="left"
                    style={{
                      paddingLeft: 10,
                      paddingRight: 10,
                      borderRadius: 5,
                      color: 'white',
                      backgroundColor: '#43D249',
                      height: 18,
                    }}
                  >
                    {useSwitch ? 'Enable' : 'Verified'}
                  </Typography>
                </>
              )}
            </Box>
            <Typography variant="body2" className={classes.subLabel}>
              {subLabel}
            </Typography>
            {smBelow && <Box pt={1}>{renderButton()}</Box>}
          </Box>
        </>
        {!smBelow && (
          <>
            <div style={{ flexGrow: 1 }} />
            {renderButton()}
          </>
        )}
      </Box>
    </>
  );
};

export default IconActionList;
