import { useContext, useState, createContext } from 'react';
import { APIRequest } from 'src/utils/api';
import { Config } from 'src/config';

const captableContext = createContext({
  error: '',
  loading: false,
  submitting: false,
  ListCapTables: () => Promise.resolve({}),
  ListCapTableShareholders: () => Promise.resolve({}),
});

export function useProvideCapTable() {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [capTables, setCapTables] = useState([]);
  const [capTableShareholders, setCapTableShareholders] = useState();

  const ListCapTables = async (companyId) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `${Config.shareholderURL}/companies/${companyId}/shareholders`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCapTables(res.body.shares);
    }
    return res;
  };

  const ListCapTableShareholders = async (companyId) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setLoading,
      path: `${Config.sharesURL}/companies/${companyId}/shares`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCapTableShareholders(res.body.shares);
    }
    return res;
  };

  return {
    error,
    loading,
    submitting,
    capTables,
    capTableShareholders,
    ListCapTables,
    ListCapTableShareholders,
  };
}

export const useCapTableContext = () => useContext(captableContext);

export function ProvideCapTable({ children }) {
  const captable = useProvideCapTable();
  return (
    <captableContext.Provider value={captable}>
      {children}
    </captableContext.Provider>
  );
}
