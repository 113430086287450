import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Paper, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import MzButton from 'src/components/MzButton';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import { FormDatePicker } from 'src/components/Form';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { ConfigContext } from 'src/contexts';
import useSnackbar from 'src/hooks/useSnackbar';
import { useProvideRequest } from 'src/hooks/useRequests';
import { useProvideProduct } from 'src/hooks/useProduct';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';

const ResignDirectorForm = ({ toggleShowMenu }) => {
  const history = useHistory();
  const { smBelow } = useBreakpoints();
  const {
    company,
    addCart,
    user,
    cartSubmitting,
    setConfirmationDialog,
  } = useContext(ConfigContext);
  const { showErrorSnackbar } = useSnackbar();

  const {
    CreateCessationApplication,
    requestSubmitting,
    requestError,
  } = useProvideRequest();
  const { ReadProduct, product, productError } = useProvideProduct();

  useEffect(() => {
    // get latest product
    ReadProduct(2);
  }, []);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const validationSchema = Yup.object({
    cessation_date: Yup.string().required('Resignation date is required'),
    cessation_reason: Yup.string().required(
      'Reason for Resignation is required',
    ),
  });

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.company_id;
    values.company_application_status_id = 11; // in cart status
    let res = await CreateCessationApplication(values);
    if (res.status === 'success') {
      res = await addCart({
        type: 'Resignation of Director',
        price: product.price,
        quantity: 1,
        user_id: user.user_id,
        product_id: product.id,
        product_detail_id: res.body.applications.cessation_application_id,
        company_id: company.company_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setConfirmationDialog({
          isVisible: true,
          title: 'Item added to cart',
          type: 'success',
          cancelButtonLabel: 'Back to Request',
          confirmButtonLabel: 'View your cart',
          confirmButtonAction: () => {
            history.push(ROUTES.PAYMENTCART);
            setConfirmationDialog({
              isVisible: false,
            });
          },
          cancelButtonAction: () => {
            history.push(ROUTES.CORPSEC_REQ);
            setConfirmationDialog({
              isVisible: false,
            });
          },
        });
      }
    }
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={2}
        setStep={() => {
          history.push(ROUTES.CORPSEC_REQ_RESGN_DIRECTOR);
        }}
        header={
          <>
            <Typography variant="h2">
              Fill in Information for Resignation Director
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Request', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change of Officers',
                  route: ROUTES.CORPSEC_REQ_CHANGE_OFFICER,
                },
                {
                  path: 'Resignation of Director',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
          <Formik
            initialValues={{
              cessation_date: new Date(),
              cessation_reason: '',
              user_company_role_id: 5,
            }}
            onSubmit={formikOnSubmit}
            validationSchema={validationSchema}
          >
            {(props) => (
              <Form>
                <FormDatePicker
                  containerWidth={smBelow ? '100%' : '50%'}
                  name="cessation_date"
                  labelText="Date of Resignation *"
                  format="dd/MM/yyyy"
                  variant="outlined"
                  inputVariant="outlined"
                />
                <FormTextField
                  name="cessation_reason"
                  labelText="Reason for Resignation *"
                  fullWidth={smBelow ? true : false}
                  shrink
                  variant="outlined"
                />
                <MzButton
                  fullWidth={smBelow ? true : false}
                  type="submit"
                  title="Submit"
                  loading={requestSubmitting || cartSubmitting}
                  onClick={() => {}} // must put onClick else cannot trigger formik
                />
              </Form>
            )}
          </Formik>
        </Box>
      </GetStartedLayout>
    </>
  );
};

export default ResignDirectorForm;
