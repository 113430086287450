import React from 'react';
import logo from '../assets/homepage/confirmation.png';
import './message.css';
import { Typography, Box, Container } from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import ROUTES from '../constants/routes';
import useBreakpoints from '../hooks/useBreakpoints';

const Messagepage = () => {
  const { xsBelow } = useBreakpoints();
  return (
    <Box
      style={{
        display: 'flex',
        backgroundColor: '#282c34',
        height: '100vh',
        paddingBottom: xsBelow ? 30 : 0,
        alignItems: 'center',
      }}
    >
      <Container>
        <img src={logo} height={300} width={xsBelow ? '100%' : 'auto'} />
        <Typography
          variant="h4"
          style={{
            color: '#FFFFFF',
            fontWeight: 600,
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          Phone number verified!
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: '#FFFFFF',
            paddingBottom: 80,
          }}
        >
          Success! You’re on your way to opening an account with us.
        </Typography>
        <MzButton page={ROUTES.DASHBOARD} title={'Continue'} />
      </Container>
    </Box>
  );
};

export default Messagepage;
