import React, { useState, useEffect, useContext } from 'react';
import { Typography, Box, Paper, Button, Menu } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import useBreakpoints from 'src/hooks/useBreakpoints';
import TitleLayout from 'src/components/TitleLayout';
import { ConfigContext } from 'src/contexts';
import useStyles from './ManageUser.styles';
import companySvc from 'src/services/company';
import ROUTES from '../../../constants/routes';

const columns = [
  {
    title: 'ID',
    field: 'onboard_profile_id',
  },
  {
    title: 'COMPANY NAME',
    field: 'company_name',
  },
  {
    title: 'CREATED',
    field: 'created_date',
    render: (rowData) => {
      return (
        <>
          {rowData['created_date']
            ? moment(rowData['created_date']).format('DD/MM/YYYY')
            : '-'}
        </>
      );
    },
  },
  {
    title: 'STATUS',
    field: 'status_name',
    render: (rowData) => {
      return <>{rowData['status_name'] ? rowData['status_name'] : '-'}</>;
    },
  },
];

const CompanyAccountSettings = () => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  const { company } = useContext(ConfigContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [inviteNewUserModal, setInviteNewUserModal] = useState(false);
  const [changeRoleModal, setChangeRoleModal] = useState(false);
  const [userRole, setUserRole] = useState('0');
  const [data, setData] = useState([]);
  const routeHistory = useHistory();

  useEffect(() => {
    companySvc
      .getOnBoardProfilesByUserId(1)
      .then((result) => {
        console.log('resultresult', result);
        setData(result);
      })
      .catch((error) => {
        setData([]);
      });
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TitleLayout
        title="Company Account Settings"
        pathList={[]}
        // pathList={[
        //   { path: 'Home', route: ROUTES.DASHBOARD },
        //   {
        //     path: 'Company Account Settings',
        //     color: 'primary',
        //   },
        // ]}
      >
        <Paper
          style={{
            width: '100%',
            borderRadius: 10,
            padding: smBelow ? 20 : 40,
          }}
        >
          <Box display="flex" alignItems="center">
            <Typography variant="h4">{company.company_name}</Typography>
            <Box flexGrow={1} />
            <Button
              variant="contained"
              size="large"
              color="secondary"
              style={{
                textTransform: 'initial',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() => {
                routeHistory.push(ROUTES.ONBOARDING_COMPANY);
                localStorage.removeItem('onboarding-company');
              }}
            >
              <AddIcon fontSize="small" /> New Company Account
            </Button>
          </Box>
          <Box p={1} />
          <MaterialTable
            localization={{
              header: {
                actions: 'ACTION',
              },
            }}
            options={{
              filtering: false,
              paging: false,
              toolbar: false,
              draggable: false,
              sorting: false,
              headerStyle: {
                backgroundColor: '#FAFAFB',
                paddingRight: 100,
              },
              rowStyle: { backgroundColor: '#FEFEFE' },
              actionsColumnIndex: -1,
            }}
            style={{ width: '100%' }}
            columns={columns}
            data={data}
            // actions={[
            //   {
            //     icon: MoreVertIcon,
            //     onClick: (event) => {
            //       event.preventDefault();
            //       setAnchorEl(event.currentTarget);
            //     },
            //   },
            // ]}
          />
          <Box p={1} />
          <Typography variant="h6" className={classes.fontWeight}>
            {data.length} Accounts
          </Typography>
          <Box p={smBelow ? 1 : 5} />
        </Paper>
      </TitleLayout>
    </>
  );
};

export default CompanyAccountSettings;
