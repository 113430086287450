import React from 'react';
import { Box, Typography, Checkbox, Paper } from '@material-ui/core';
import useStyles from './CheckboxField.styles';
import DetailLabel from 'src/components/DetailLabel';

const CheckboxField = ({
  checkboxLabel,
  checkboxList,
  checked,
  onCheckedChange,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={checked}
          onChange={onCheckedChange}
          color="primary"
        />
        <Typography
          variant="h6"
          align="center"
          style={{
            color: '#808191',
          }}
        >
          {checkboxLabel}
        </Typography>
      </Box>

      <Paper
        variant="outlined"
        style={{
          width: '100%',
          padding: 30,
          borderRadius: 10,
        }}
      >
        {checkboxList.map((item, index) => (
          <Box
            key={`${item.currentLabel}${index}`}
            display="flex"
            flexDirection={item.vertical ? 'column' : 'row'}
            width="100%"
          >
            {!!item.currentLabel && (
              <>
                <DetailLabel
                  labelColor="#808191"
                  valueColor="black"
                  withoutTopBox
                  label={item.currentLabel}
                  value={item.currentValue ? item.currentValue : 'N/A'}
                />
                <div style={{ flexGrow: 1 }} />
              </>
            )}

            {item.child}
          </Box>
        ))}
      </Paper>
      <Box p={1} />
    </>
  );
};

export default CheckboxField;
