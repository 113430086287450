import React from 'react';
import PropTypes from 'prop-types';

import SignInLayout from '../components/SignIn/Layout';
import SignInForm from '../components/SignIn/SignInForm';

function Signinpage(props) {
  return (
    <SignInLayout>
      <SignInForm />
    </SignInLayout>
  );
}

Signinpage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Signinpage;
