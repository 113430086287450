import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Field } from 'formik';

import FormFieldContainer from '../FormFieldContainer';

const StyledTextField = withStyles((theme) => ({
  root: {
    '& .MuiInputBase-root': {
      // '& fieldset': {
      borderRadius: `4px`,
      // },
      backgroundColor: '#fff',
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0',
    },
  },
}))(TextField);

const CustomTextField = (props) => {
  const {
    label,
    name,
    containerProps: defaultContainerProps = {},
    helperText,
    children,
    ...restProps
  } = props;

  let fieldProps = {
    name: name,
    variant: 'outlined',
    fullWidth: true,
    size: 'small',
    ...restProps,
  };

  return (
    <Field name={name}>
      {({ field, meta }) => {
        let error = meta.touched && !!meta.error;
        return (
          <FormFieldContainer
            label={label}
            error={error}
            helperText={meta.touched && meta.error ? meta.error : ''}
            {...defaultContainerProps}
          >
            <StyledTextField {...field} error={error} {...fieldProps} />
            {children}
          </FormFieldContainer>
        );
      }}
    </Field>
  );
};

export default CustomTextField;
