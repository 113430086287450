import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

const useBreakpoints = () => {
  const theme = useTheme();

  return {
    mdAbove: useMediaQuery(theme.breakpoints.up('md')),
    mdBelow: useMediaQuery(theme.breakpoints.down('md')),
    smAbove: useMediaQuery(theme.breakpoints.up('sm')),
    smBelow: useMediaQuery(theme.breakpoints.down('sm')),
    xsBelow: useMediaQuery(theme.breakpoints.down('xs')),
  };
};

export default useBreakpoints;
