export const numberOfEmployees = [
  {
    label: '1',
  },
  {
    label: '2-10',
  },
  {
    label: '11-50',
  },
  {
    label: '51-200',
  },
  {
    label: '201-500',
  },
  {
    label: '500+',
  },
];

export const annualRevenues = [
  {
    label: 'S$0 - S$50,000',
  },
  {
    label: 'S$50,000 - S$200,000',
  },
  {
    label: 'S$200,000 - S$500,000',
  },
  {
    label: 'S$500,000 - S$2,000,000',
  },
  {
    label: 'S$2,000,000 - S$5,000,000',
  },
  {
    label: 'S$5,000,000 - S$20,000,000',
  },
  {
    label: '>S$20,000,000',
  },
];

export const totalMonthlyCardSpends = [
  {
    label: 'SGD 0',
  },
  {
    label: 'SGD 1 - 10,000',
  },
  {
    label: 'SGD 10,000 - 30,000',
  },
  {
    label: 'SGD 30,000 - 100,000',
  },
  {
    label: 'SGD 100,000 - 250,000',
  },
  {
    label: '> SGD 250,000',
  },
];

export const entityCategories = [
  {
    label: 'Limited companies',
    children: [
      {
        label: 'Limited Liability Company (LLC)',
      },
      {
        label: 'Limited Liability Partnership',
      },
      {
        label: 'Public Limited Company (PLC)',
      },
      {
        label: 'Limited partnership',
      },
      {
        label: 'Private Limited Company (PTE LTD)',
      },
    ],
  },
  {
    label: 'Non profit organization',
    children: [
      {
        label: 'Charities',
      },
      {
        label: 'Clubs and societies',
      },
      {
        label: 'Trust',
      },
    ],
  },
  {
    label: 'Sole prop & unlimited companies',
    children: [
      {
        label: 'Sole proprietorship',
      },
      {
        label: 'Partnership',
      },
      {
        label: 'Unlimited private company',
      },
    ],
  },
];

export const industries = [
  {
    label: 'Charity & Non-for-profit',
    children: [
      {
        label: 'Charities, Non-for-profits & NGOs',
      },
      {
        label: 'Governmental Organisations & Embassies',
      },
    ],
  },
  {
    label: 'Eyewear & Contact Lenses',
    children: [],
  },
  {
    label: 'Goods',
    children: [
      {
        label: 'Agricultural & Industrial Machinery',
      },
      {
        label: 'Agricultural Commodities, Seeds, and Plants',
      },
      {
        label: 'Alcohol',
      },
      {
        label: 'Arts, Craftmanship (< 500 USD per piece)',
      },
      {
        label: 'Arts, Fine ( > 500 USD per piece)',
      },
      {
        label: 'Automotive, Cars, Boats, & Planes',
      },
      {
        label: 'Beauty & Wellbeing',
      },
      {
        label: 'Book Store',
      },
      {
        label: 'Content for Adults',
      },
      {
        label: 'Defence (excl Weapons)',
      },
      {
        label: 'Electronics & Household Appliances',
      },
      {
        label: 'Furniture & Home Deco',
      },
      {
        label: 'Groceries & Consumables (No Alcohol)',
      },
      {
        label: 'Groceries & Consumables (With Alcohol or Tobacco)',
      },
      {
        label: 'Industrial Chemicals & Plastics',
      },
      {
        label: 'Medical Equipment',
      },
      {
        label: 'NFTs',
      },
      {
        label: 'Others',
      },
      {
        label: 'Pet Food & Products',
      },
      {
        label: 'Pharmaceuticals & Dietary Supplements',
      },
      {
        label: 'Precious Minerals, Metals, & Stones',
      },
      {
        label: 'Shoes & Apparel',
      },
      {
        label: 'Sports & Fitness Equipment (excl. Weapons)',
      },
      {
        label: 'Tobacco & (e)Cigarettes',
      },
      {
        label: 'Toys & Games',
      },
      {
        label: 'Vehicles Spare Parts & Accessories',
      },
      {
        label: 'Watches & Jewellery, Fashion (< 500 USD per piece)',
      },
      {
        label: 'Watches & Jewellery, Fashion (> 500 USD per piece)',
      },
      {
        label: 'Weapon, Knives & Military',
      },
    ],
  },
  {
    label: 'Marketplaces & Aggregators',
    children: [
      {
        label: 'Marketplaces & Aggregators',
      },
    ],
  },
  {
    label: 'Services',
    children: [
      {
        label: 'Accommodation & Hotels',
      },
      {
        label: 'Aircon, Cleaning, Laundry & Pest Control Services',
      },
      {
        label: 'Architecture & Interior Design',
      },
      {
        label: 'Auction & Valuation Services',
      },
      {
        label: 'Audio & Video Content',
      },
      {
        label: 'Automotive, Cars, Boats, & Planes',
      },
      {
        label: 'Banking, Investments, Lending',
      },
      {
        label: 'Beauty & Wellbeing',
      },
      {
        label: 'Company Establishment / Formation Services',
      },
      {
        label: 'Construction',
      },
      {
        label: 'Content for Adults',
      },
      {
        label: 'Corporate Strategy Consulting',
      },
      {
        label: 'Cryptocurrency Services',
      },
      {
        label: 'Debt Collection & Settlement',
      },
      {
        label: 'Defence (excl Weapons)',
      },
      {
        label: 'Dental Services',
      },
      {
        label: 'Development, Cybersecurity, & Other IT Consulting',
      },
      {
        label: 'Engineering, Scientific & Technical Consulting',
      },
      {
        label: 'Events & Entertainment',
      },
      {
        label: 'F&B & Catering (No Alcohol)',
      },
      {
        label: 'F&B & Catering (With Alcohol)',
      },
      {
        label: 'Financial Consulting, Audit, Accounting, Tax',
      },
      {
        label: 'Financial Trading',
      },
      {
        label: 'Gambling & Betting',
      },
      {
        label: 'Government Services',
      },
      {
        label: 'Graphic & Product Design',
      },
      {
        label: 'HR Consulting',
      },
      {
        label: 'HR Recruitment',
      },
      {
        label: 'Healthcare & Medical Services',
      },
      {
        label: 'Holding Companies',
      },
      {
        label: 'IPTV & VOIP',
      },
      {
        label: 'Insurance Provider & Broker',
      },
      {
        label: 'Leasing',
      },
      {
        label: 'Legal Services',
      },
      {
        label: 'Logistic, Warehousing & Freight',
      },
      {
        label: 'Marketing & Advertising Agency',
      },
      {
        label: 'Marketing Consulting',
      },
      {
        label: 'Mining & Oil Extraction',
      },
      {
        label: 'Mining Incidental Services',
      },
      {
        label: 'Nursing Homes',
      },
      {
        label: 'Others',
      },
      {
        label: 'Passenger Transportation Services',
      },
      {
        label: 'Payment & Remittance Services',
      },
      {
        label: 'Performing Arts (Singing, Acting, Painting, Writing)',
      },
      {
        label: 'Pet Care Services',
      },
      {
        label: 'Pharmaceutical & Dietary Supplements',
      },
      {
        label: 'Photography',
      },
      {
        label: 'Precious Minerals, Metals & Stones',
      },
      {
        label: 'Property Management & Brokerage',
      },
      {
        label: 'Psychic Services',
      },
      {
        label: 'Publishing & Printing, of Blogs & Written Content',
      },
      {
        label: 'SaaS (Software as a Service)',
      },
      {
        label: 'Schools & Universities (Full Time Curriculum)',
      },
      {
        label: 'Schools (Part Time), Private Teaching & Tutoring',
      },
      {
        label: 'Shell Companies & Correspondent Banks',
      },
      {
        label: 'Sport & Fitness',
      },
      {
        label: 'Telecommunications',
      },
      {
        label: 'Translation & Language Services',
      },
      {
        label: 'Transportation Shared Services',
      },
      {
        label: 'Travel Agency',
      },
      {
        label: 'Utilities',
      },
      {
        label: 'Video Gaming',
      },
    ],
  },
];
