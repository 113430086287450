import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  gradientBackground: {
    backgroundImage: 'linear-gradient(to left, #760000 1.54%, #FFB800 101.02%)',
    height: 30,
  },
  gradientOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: 'linear-gradient(to bottom, #FFFFFF00 60%, #FFFFFF 100%)',
    height: 30,
  },
  topButton: {
    minWidth: '160px',
    minHeight: '50px',
  },
  botButton: {
    minWidth: '160px',
    minHeight: '50px',
    marginTop: theme.spacing(3),
  },
}));
