import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
  },
}));

function FormCheckbox({ name, label, ...props }) {
  const classes = useStyles();
  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <>
            <FormControl fullWidth error={meta.touched && !!meta.error}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    color="primary"
                    style={{ textAlign: 'left' }}
                    {...field}
                    {...props}
                  />
                }
                label={label}
                classes={{
                  root: classes.root,
                }}
              />
              {meta.touched && !!meta.error && (
                <FormHelperText>{meta.error}</FormHelperText>
              )}
            </FormControl>
          </>
        );
      }}
    </Field>
  );
}

export default FormCheckbox;
