import { Avatar, Box, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import companySvc from 'src/services/company';
import useStyles from './NameBox.styles';
const CorpNameBox = () => {
  const classes = useStyles();
  const history = useHistory();
  const company = companySvc.getCompany();

  if (!company) {
    history.push(ROUTES.CORPSEC_PRO);
    return null;
  }

  return (
    <>
      <Box className={classes.box}>
        <Box className={classes.avatarBorder}>
          <Avatar />
        </Box>
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Typography
            variant="h5"
            align="left"
            style={{ color: 'white', fontWeight: 600 }}
          >
            {company?.companyName}
          </Typography>
          <Typography variant="subtitle2" style={{ color: 'white' }}>
            UEN: {company?.uen}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default CorpNameBox;
