import { useContext, useState, createContext } from 'react';
import { Config } from 'src/config';
import { APIRequest } from 'src/utils/api';

const checkoutContext = createContext({
  checkouts: [],
  checkoutError: '',
  checkoutLoading: false,
  checkoutSubmitting: false,
  paymentError: '',
  GetToken: () => Promise.resolve({}),
  PaymentByToken: () => Promise.resolve({}),
  PaymentByLink: () => Promise.resolve({}),
});

export function useProvideCheckout() {
  const [checkout, setCheckout] = useState({});
  const [payment, setPayment] = useState({});
  const [checkoutError, setCheckoutError] = useState();
  const [paymentError, setPaymentError] = useState();
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [checkoutSubmitting, setCheckoutSubmitting] = useState(false);

  const GetToken = async (paymentType = {}) => {
    // "type": "card",
    // "number": "4242424242424242",
    // "expiry_month": "6",
    // "expiry_year": "2028",
    // "cvv": "100"
    const res = await APIRequest({
      setError: setCheckoutError,
      setLoading: setCheckoutSubmitting,
      path: `${Config.checkoutURL}/tokens`,
      method: 'post',
      body: paymentType,
      headers: {
        Authorization: Config.checkoutAuthToken,
      },
    });
    // "type": "card",
    // "token": "tok_d5rx6ldctuyu3jx23u2qqsslsa",
    // "expires_on": "2021-10-15T15:13:25Z",
    // "expiry_month": 6,
    // "expiry_year": 2028,
    // "scheme": "Visa",
    // "last4": "4242",
    // "bin": "424242",
    // "card_type": "Credit",
    // "card_category": "Consumer",
    // "issuer": "JPMORGAN CHASE BANK NA",
    // "issuer_country": "US",
    // "product_id": "A",
    // "product_type": "Visa Traditional"
    if (res.status === 'success') {
      setCheckout(res.body);
    }
    return res;
  };

  const PaymentByToken = async (payment = {}) => {
    // "source": {
    //     "type": "token",
    //     "token": "tok_d6glyysjdvnupoh3birkbad3xe"
    // },
    // "customer": {
    //     "email": "user@email.com",
    //     "name": "James Bond"
    // },
    // "currency": "EUR",
    // "amount": 1000,
    // "reference": "ORDER123"
    const res = await APIRequest({
      setError: setPaymentError,
      setLoading: setCheckoutSubmitting,
      path: `${Config.checkoutURL}/payments`,
      method: 'post',
      body: payment,
      headers: {
        Authorization: Config.checkoutPaymentAuthToken,
      },
    });
    // "id": "pay_cldbp6r7pewk5mcsugg4mrtaru",
    // "action_id": "act_cldbp6r7pewk5mcsugg4mrtaru",
    // "amount": 1000,
    // "currency": "EUR",
    // "approved": true,
    // "status": "Authorized",
    // "auth_code": "727198",
    // "eci": "05",
    // "scheme_id": "654274203703436",
    // "response_code": "10000",
    // "response_summary": "Approved",
    // "risk": {
    //     "flagged": false
    // },
    // "source": {
    //     "id": "src_2yitlejgdc3ubesnivmq2xalpi",
    //     "type": "card",
    //     "expiry_month": 6,
    //     "expiry_year": 2028,
    //     "scheme": "Visa",
    //     "last4": "4242",
    //     "fingerprint": "35D40AFFDC82BCAC9890181E14655B05D8924C0B4986D29F99D13946A3B59513",
    //     "bin": "424242",
    //     "card_type": "Credit",
    //     "card_category": "Consumer",
    //     "issuer": "JPMORGAN CHASE BANK NA",
    //     "issuer_country": "US",
    //     "product_id": "A",
    //     "product_type": "Visa Traditional",
    //     "avs_check": "S",
    //     "cvv_check": "Y",
    //     "payouts": true,
    //     "fast_funds": "d"
    // },
    // "customer": {
    //     "id": "cus_nltmpmhnd47etdlgkhfei7cjq4",
    //     "email": "user@email.com",
    //     "name": "James Bond"
    // },
    // "processed_on": "2021-10-15T15:01:19Z",
    // "reference": "ORDER123",
    // "processing": {
    //     "acquirer_transaction_id": "5776879467",
    //     "retrieval_reference_number": "033844448576"
    // },
    // "_links": {
    //     "self": {
    //         "href": "https://api.sandbox.checkout.com/payments/pay_cldbp6r7pewk5mcsugg4mrtaru"
    //     },
    //     "actions": {
    //         "href": "https://api.sandbox.checkout.com/payments/pay_cldbp6r7pewk5mcsugg4mrtaru/actions"
    //     },
    //     "capture": {
    //         "href": "https://api.sandbox.checkout.com/payments/pay_cldbp6r7pewk5mcsugg4mrtaru/captures"
    //     },
    //     "void": {
    //         "href": "https://api.sandbox.checkout.com/payments/pay_cldbp6r7pewk5mcsugg4mrtaru/voids"
    //     }
    // }
    if (res.status === 'success' && res.body.status === 'Authorized') {
      setPayment(res.body);
      return res;
    }
    res.status = 'rejected';
    return res;
  };

  const PaymentByLink = async (paymentType = {}) => {
    // {
    //   "amount": 10359,
    //   "currency": "EUR",
    //   "billing": {
    //     "address": {
    //       "country": "DE"
    //     }
    //   },
    //   "products": [
    //     {
    //       "name": "Moonlight blue lightsaber",
    //       "quantity": 2,
    //       "price": 3999
    //     },
    //     {
    //       "name": "Stainless steel watch strap",
    //       "quantity": 1,
    //       "price": 2361
    //     }
    //   ],
    //   "reference": "cko_plink_f0a99481-df7d-4e2d-94b7-88f6f22c9137",
    //   "return_url": "https://stg.meyzer360.com/orderhistory"
    //     }

    const res = await APIRequest({
      setError: setCheckoutError,
      setLoading: setCheckoutSubmitting,
      path: `${Config.checkoutURL}/payment-links`,
      method: 'post',
      body: paymentType,
      headers: {
        Authorization: Config.checkoutPaymentAuthToken,
      },
    });
    if (res.status === 'success') {
      setCheckout(res.body);
    }
    return res;
  };

  return {
    checkout,
    checkoutError,
    checkoutLoading,
    checkoutSubmitting,
    payment,
    paymentError,
    GetToken,
    PaymentByLink,
    PaymentByToken,
  };
}

export const useCheckoutContext = () => useContext(checkoutContext);

export function ProvideCheckout({ children }) {
  const checkout = useProvideCheckout();
  return (
    <checkoutContext.Provider value={checkout}>
      {children}
    </checkoutContext.Provider>
  );
}
