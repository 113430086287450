import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Typography,
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import useStyles from './AlertBox.styles';

const AlertBox = ({
  title,
  isVisible,
  onBackdropPress,
  topButtonAction,
  botButtonAction,
  topButtonLabel,
  botButtonLabel,
  iconImg,
  iconColor,
  topButtonStyles,
  botButtonStyles,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={isVisible} onClose={onBackdropPress}>
      <div className={classes.gradientOverlay} />
      <div className={classes.gradientBackground} />
      <Box
        padding={5}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Icon icon={iconImg} color={iconColor} width="50" height="50" />
        <Box p={1} />
        <Typography variant="h5" align="center">
          {title}
        </Typography>
        <Box p={2} />
        <DialogActions
          disableSpacing
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Button
            variant="contained"
            size="large"
            className={classes.topButton}
            onClick={topButtonAction}
            style={
              topButtonStyles
                ? topButtonStyles
                : {
                    color: '#FFFFFF',
                    backgroundColor: '#FFB800',
                  }
            }
          >
            {topButtonLabel}
          </Button>

          {botButtonLabel && (
            <Button
              variant="contained"
              size="large"
              className={classes.botButton}
              onClick={botButtonAction}
              style={
                botButtonStyles
                  ? botButtonStyles
                  : {
                      color: '#000000',
                      backgroundColor: '#FFFFFF',
                      border: '1px solid #000000',
                    }
              }
            >
              {botButtonLabel}
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default AlertBox;
