import React, { useEffect, useState, useContext } from 'react';
import { Box, Container, InputAdornment, Button } from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import ServiceLayout from 'src/components/ServiceLayout';
import Stepper from 'src/components/Stepper';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormTextField from 'src/components/FormTextField/FormTextField';
import domainCheck from 'src/services/godaddyApi';
import { useHistory, useParams } from 'react-router-dom';
import { ConfigContext } from 'src/contexts';
import { useProvideWebDev } from 'src/hooks/useWebDev';
import { Icon } from '@iconify/react';

const useStyles = makeStyles((theme) => ({}));

const tempSteps = [
  {
    id: 1,
    label: 'Domain',
    route: ROUTES.CREATEWEBSITE,
  },
  {
    id: 2,
    label: 'Content',
    route: ROUTES.CREATEWEBSITE_CONTENT,
  },
  {
    id: 3,
    label: 'Corporate Identity',
    route: ROUTES.CREATEWEBSITE_CORPIDENTITY,
  },
  {
    id: 4,
    label: 'Images',
    route: ROUTES.CREATEWEBSITE_IMAGES,
  },
];

const validationSchema = Yup.object({
  name: Yup.string()
    .matches(/[a-z0-9]+(\.com)$/, 'Must end with .com')
    .required('Business name is required'),
});

const Domain = () => {
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const history = useHistory();
  const params = useParams();
  const { addWebInfo, webInfo } = useContext(ConfigContext);
  const {
    DomainChecker,
    domainAvail,
    ListWebDevById,
    webdev,
  } = useProvideWebDev();
  const [submitedName, setSubmitedName] = useState();

  const formikOnSubmit = (values, actions) => {
    addWebInfo({ domain_name: values.name });
    history.push(ROUTES.CREATEWEBSITE_CONTENT(params.webdevid));
  };

  const checkDomain = (domainname) => {
    DomainChecker(domainname);
  };

  useEffect(() => {
    if (!webInfo.webdev_id) {
      ListWebDevById(params.webdevid);
    }
  }, []);

  const renderChild = () => {
    return (
      <Container>
        <Box p={3} />
        <Stepper
          steps={tempSteps}
          providedStep={step}
          setProvidedStep={(step) => {
            setStep(step);
            history.push(tempSteps[step].route(params.webdevid));
          }}
        />
        <Box p={5} />
        <Formik
          initialValues={{
            name: '',
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <FormTextField
                name="name"
                icon="cil:pen"
                labelText="Add your business or website name"
                shrink
                variant="standard"
                placeholder="domain.com"
                onChange={(event) => {
                  props.setFieldValue('name', event.target.value);
                }}
                value={props.values.name}
                InputProps={{
                  endAdornment: (
                    <>
                      {domainAvail !== undefined &&
                        submitedName === props.values.name && (
                          <InputAdornment position="end">
                            {domainAvail ? (
                              <Icon
                                icon="teenyicons:tick-circle-solid"
                                color="green"
                                width="20"
                                height="20"
                              />
                            ) : (
                              <Icon
                                icon="entypo:circle-with-cross"
                                color="red"
                                width="20"
                                height="20"
                              />
                            )}
                          </InputAdornment>
                        )}
                    </>
                  ),
                }}
              />
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                onClick={() => {
                  if (props.isValid && props.dirty) {
                    setSubmitedName(props.values.name);
                    checkDomain(props.values.name);
                  }
                }}
              >
                Check availablity{' '}
              </Button>
              <Box p={8} />
              <MzButton
                title={'Continue'}
                disabled={!(props.isValid && props.dirty && domainAvail)}
                type="submit"
                onClick={() => {}} // must put onClick else cannot trigger formik
              />
            </Form>
          )}
        </Formik>
        <Box p={3} />
      </Container>
    );
  };

  return <ServiceLayout title="Create Website" child={renderChild()} />;
};

export default Domain;
