import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/Onboarding/Layout';
import Onboarding from '../components/Onboarding';

function Signinpage(props) {
  return <Onboarding />;
}

Signinpage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Signinpage;
