import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  profileBox: {
    display: 'flex',
    width: '100%',
    borderRadius: 25,
    boxShadow: '2px 4px 4px #ccc, -2px 4px 4px #ccc',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  boxColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  fontWeight: {
    fontWeight: 600,
  },
  singleButton: {
    color: '#000000',
    textTransform: 'initial',
    minWidth: 120,
    borderRadius: 0,
    '&:focus': {
      backgroundColor: '#DBDBDB',
      color: '#000000',
      borderRight: '1px solid #000000',
      borderLeft: '1px solid #000000',
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 90,
    },
  },
  tabs: {
    border: 'none',
    borderRight: '1px solid #000000',
    borderLeft: '1px solid #000000',
    '&:first-child': {
      borderLeft: 'none',
    },
    '&:last-child': {
      borderRight: 'none',
    },
  },
  products: {
    display: 'flex',
    margin: 'auto',
  },
  product: {
    width: '200px',
    height: '200px',
    margin: '50px',
    border: 'grey solid 1px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  selectedProd: {
    border: '#cbcb25 solid 2px',
  },
}));
