import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  TextField,
  CssBaseline,
  Divider,
  Grid,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
} from '@material-ui/core';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import logo from '../../../assets/m360.png';
import ROUTES from '../../../constants/routes';

function Layout({ subHeader, children }) {
  const routeHistory = useHistory();
  return (
    <main>
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="flex-nowrap"
        height="100vh"
        width="100%"
        style={
          {
            // backgroundColor: '#fff',
          }
        }
      >
        <CssBaseline />
        <Box
          style={{
            backgroundColor: '#fff',
          }}
          display="flex"
          flexWrap="no-wrap"
          alignItems="center"
          py={2}
        >
          <Box px={2} style={{ height: '40px' }}>
            <img src={logo} style={{ height: '100%' }} />
          </Box>
          <Divider orientation="vertical" />
          <Box flexGrow={1} px={2}>
            <Typography variant="h5">Company Account</Typography>
          </Box>
          <Box px={2}>
            <Button
              onClick={() => {
                routeHistory.goBack();
                // routeHistory.replace(ROUTES.CORPSEC_COMPANY_ACCOUNT_SETTINGS);
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
        </Box>
        {subHeader && (
          <Box
            style={{
              backgroundColor: '#fff',
            }}
          >
            {subHeader}
          </Box>
        )}
        <Box display="flex" flexDirection="column" flexGrow={1}>
          <Box flexGrow={1} display="flex" flexWrap="no-wrap" pt={3}>
            {children}
          </Box>
          <Box textAlign="center" mt={2} p={2}>
            <Typography color="textSecondary" variant="caption">
              © 2021 Meyzer Group &#8226; Terms of Service &#8226; Privacy
              Notice &#8226; Licenses
            </Typography>
          </Box>
        </Box>
      </Box>
    </main>
  );
}

Layout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Layout;
