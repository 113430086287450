import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    background: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      zIndex: 1,
    },
    backdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: 'rgba(118, 118, 118, 0.5)',
      zIndex: 2,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
      },
    },
    mainTitle: {
      paddingBottom: 25,
      fontWeight: 600,
    },
    title: {
      color: '#FFFFFF',
      textAlign: 'left',
      fontWeight: 'bold',
      zIndex: 3,
      whiteSpace: 'normal',
      fontSize: 20,
      [theme.breakpoints.up('sm')]: {
        minWidth: 800,
      },
      [theme.breakpoints.down('sm')]: {
        minWidth: 400,
      },
      [theme.breakpoints.down('xs')]: {
        minWidth: 200,
      },
    },
    centeredTitle: {
      color: '#FFFFFF',
      textAlign: 'center',
      paddingTop: 120,
      fontWeight: 'bold',
      zIndex: 3,
      whiteSpace: 'normal',
      fontSize: 20,
    },
    titleBar: {
      position: 'relative',
      background: 'transparent',
      zIndex: 3,
    },
    hover: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 2,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
    selected: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      zIndex: 2,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }),
);
