import React, { useState, useEffect, useContext } from 'react';
import './message.css';
import {
  Typography,
  Box,
  Container,
  Button,
  ButtonBase,
  Divider,
  Paper,
  Badge,
  IconButton,
  List,
  ListSubheader,
  ListItemSecondaryAction,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MzButton from 'src/components/MzButton';
import ROUTES from '../constants/routes';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import useBreakpoints from '../hooks/useBreakpoints';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Formik, Form } from 'formik';
import { Icon } from '@iconify/react';
import { useHistory } from 'react-router-dom';
import { AllServices } from 'src/constants/list';
import { CorpsecNameBox } from 'src/components/Corpsec';
import ContentLayout from 'src/components/ContentLayout';
import { ConfigContext } from 'src/contexts';
import useSnackbar from 'src/hooks/useSnackbar';
import { FormFileDropzone, FormDatePicker } from 'src/components/Form';
import moment from 'moment';
import useButtonStyle from 'src/styles/button';
import { useProvideCheckout } from 'src/hooks/useCheckoutIntegration';
import { useProvideOrder } from 'src/hooks/useOrder';
import { useProvideWebDev } from 'src/hooks/useWebDev';
import { FormRadioGroup } from 'src/components/Form';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
  },
  gradientButton: {
    minWidth: '230px',
    minHeight: '50px',
    border: '2px solid transparent',
    borderRadius: '30px',
    backgroundImage:
      'linear-gradient(white, white), linear-gradient(to left, #760000 1.54%, #FFB800 101.02%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
  },
  fontWeight: {
    fontWeight: 600,
  },

  appbar: {
    backgroundImage: `url(${AllServices[0].img})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  toolbar: {
    minHeight: 200,
    alignSelf: 'baseline',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      // mobile view
      flexDirection: 'column',
      flexGrow: 0,
    },
  },
  paymentButton: {
    width: 130,
    textTransform: 'initial',
  },
  paymentActiveButton: {
    width: 130,
    textTransform: 'initial',
    backgroundColor: '#C5C5C5',
  },
}));

const PaymentCart = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { smBelow } = useBreakpoints();
  const [step, setStep] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [totalPrice, calTotalPrice] = useState(0);
  const { cartList, cart, removeCart, webInfo, user, fetchCart } = useContext(
    ConfigContext,
  );
  const {
    PaymentByLink,
    checkoutError,
    checkoutSubmitting,
  } = useProvideCheckout();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const buttonClasses = useButtonStyle();
  const { AddWebDev } = useProvideWebDev();
  const { CreateOrder, orderError, orderSubmitting } = useProvideOrder();

  useEffect(() => {
    let total = 0;
    for (let i = 0; i < cartList.length; i++) {
      total += Number(cartList[i].price);
    }
    calTotalPrice(total);
  }, [JSON.stringify(cartList)]);

  useEffect(() => {
    if (checkoutError) {
      showErrorSnackbar(`Checkout failed. ${checkoutError}`);
    }
  }, [checkoutError]);

  useEffect(() => {
    if (orderError) {
      showErrorSnackbar(`Checkout failed. ${orderError}`);
    }
  }, [orderError]);

  const postPaymentFlow = async () => {
    if (webInfo.length !== 0) {
      await AddWebDev(webInfo);
    }
  };

  const formikOnSubmit = async (values, actions) => {
    const products = cartList.map((item) => {
      return {
        name: item.product.name,
        price: item.price * 100,
        quantity: item.quantity,
      };
    });
    if (!cart || !cart.id) {
      showErrorSnackbar(
        `Problem checking out your cart. Please refresh and try again.`,
      );
    }

    if (totalPrice === 0) {
      const res = await CreateOrder({ user_id: user.user_id });
      if (res.status === 'success') {
        fetchCart(user);
        showSuccessSnackbar('Order submitted');
        history.push(ROUTES.ORDERHISTORY);
      }
      return;
    }

    // request payment link
    const res = await PaymentByLink({
      billing: {
        address: {
          country: 'SG',
        },
      },
      products,
      currency: 'SGD',
      amount: totalPrice * 100,
      reference: String(cart.id),
      return_url: `${window.location.origin}/orderhistory?redirect=checkout`,
    });
    if (res.status === 'success') {
      showSuccessSnackbar('Redirecting to payment...');
      window.location.replace(res.body._links.redirect.href);
    }
  };

  const renderStep1 = (totalPrice) => {
    return (
      <Paper
        elevation={0}
        variant="outlined"
        style={{
          borderRadius: smBelow ? '0px' : '12px',
        }}
      >
        <Box p={4}>
          <Typography variant="h2">My Shopping Cart</Typography>
          <Typography variant="h6" color="textSecondary">
            Total {cartList.length} items
          </Typography>
        </Box>
        <Divider />

        <List
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
          }}
          subheader={<li />}
        >
          {cartList.length !== 0 &&
            cartList.map((item, index) => (
              <>
                <Box p={3} px={8}>
                  <ListItem key={item.id}>
                    {/* <ListItemAvatar>
                      <Avatar
                        variant="square"
                        style={{
                          backgroundColor: '#F4F4F4',
                          width: theme.spacing(10),
                          height: theme.spacing(10),
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                        >
                          <Typography
                            variant="h5"
                            style={{
                              textTransform: 'uppercase',
                              fontWeight: 'bold',
                            }}
                            color="textPrimary"
                          >
                            {moment(item.created_at).format('MMM')}
                          </Typography>
                          <Typography
                            variant="h2"
                            color="textPrimary"
                            style={{
                              textTransform: 'uppercase',
                              fontWeight: 'bold',
                              // fontSize: 18,
                              lineHeight: 1,
                            }}
                          >
                            {moment(item.created_at).format('DD')}
                          </Typography>
                        </Box>
                      </Avatar>
                    </ListItemAvatar> */}
                    <Box p={1} />
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                        },
                      }}
                      primary={
                        <Box display="flex" style={{ width: '100%' }}>
                          <Typography style={{ flex: '1 1 0%' }} variant="h4">
                            {item?.product?.name}
                          </Typography>
                          <Typography style={{ flex: '1 1 0%' }} variant="h4">
                            {moment(item.created_at).format('DD/MM/YYYY')}
                          </Typography>
                          <Typography style={{ flex: '1 1 0%' }} variant="h4">
                            S$ {item.price}
                          </Typography>
                        </Box>
                      }
                      // secondary={
                      //   <Box display="flex" alignItems="center">
                      //     <QueryBuilderIcon />
                      //     {moment(item.created_at).format('h:mm A')}
                      //   </Box>
                      // }
                    />

                    <ListItemSecondaryAction>
                      <IconButton
                        onClick={() => {
                          removeCart(cartList, index);
                        }}
                      >
                        <CloseIcon fontSize="large" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Box>
                <Divider />
              </>
            ))}
        </List>
        <Box px={3} pt={4}>
          <Paper
            elevation={0}
            style={{
              borderRadius: smBelow ? '0px' : '12px',
              width: '100%',
              backgroundColor: '#F5F5F5',
            }}
          >
            <Box display="flex" p={3} justifyContent="flex-end" px={8}>
              <Typography variant="h3" style={{ fontWeight: 'bold' }}>
                TOTAL: S$ {totalPrice}
              </Typography>
            </Box>
            <Box display="flex" pb={4} justifyContent="space-between" px={8}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#979797',
                  color: theme.palette.common.white,
                }}
                onClick={() => history.push(ROUTES.DASHBOARD)}
                startIcon={<ArrowBackIcon />}
              >
                Continue Shopping
              </Button>
              {cartList.length > 0 && (
                <MzButton
                  title="Proceed to Payment"
                  onClick={() => setStep(2)}
                />
              )}
            </Box>
          </Paper>
        </Box>
        <Box pb={3} />
      </Paper>
    );
  };

  const renderStep2 = (totalPrice) => {
    return (
      <>
        <Paper
          elevation={0}
          variant="outlined"
          style={{
            borderRadius: smBelow ? '0px' : '12px',
          }}
        >
          <Box p={4}>
            <Typography variant="h2">Checkout</Typography>
          </Box>
          <Divider />
          <Box
            p={smBelow ? 0 : 3}
            px={smBelow ? 0 : 8}
            display="flex"
            flexDirection={smBelow ? 'column' : 'row'}
            width="100%"
            style={{
              gap: 50,
            }}
          >
            <Paper
              elevation={0}
              variant={smBelow ? 'elevation' : 'outlined'}
              style={{
                borderRadius: smBelow ? '0px' : '4px',
                flex: '1 1 0%',
              }}
            >
              <List subheader={<li />}>
                <ListItem
                  style={{
                    backgroundColor: '#F5F5F5',
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        whiteSpace: 'normal',
                        fontWeight: 'bold',
                      },
                      variant: 'body2',
                    }}
                    primary={'Order Summary'}
                  />
                </ListItem>
                <Divider />
                {cartList.length !== 0 &&
                  cartList.map((item, index) => (
                    <>
                      <ListItem key={item.id}>
                        <ListItemText
                          primaryTypographyProps={{
                            style: {
                              whiteSpace: 'normal',
                            },
                            variant: 'body2',
                          }}
                          primary={`${item?.product?.name}`}
                        />

                        <ListItemSecondaryAction>
                          <Typography variant="body2">
                            S$ {item.price}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </>
                  ))}
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        whiteSpace: 'normal',
                        fontWeight: 'bold',
                      },
                      variant: 'body2',
                    }}
                    primary={'Total'}
                  />
                  <ListItemSecondaryAction>
                    <Typography style={{ fontWeight: 'bold' }} variant="body2">
                      S$ {totalPrice}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Paper>
            <Box
              style={{
                flex: '1 1 0%',
              }}
            >
              {smBelow && <Divider />}
              <Paper
                elevation={0}
                variant={smBelow ? 'elevation' : 'outlined'}
                style={{
                  borderRadius: smBelow ? '0px' : '4px',
                }}
              >
                <List subheader={<li />}>
                  <ListItem
                    style={{
                      backgroundColor: '#F5F5F5',
                    }}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          whiteSpace: 'normal',
                          fontWeight: 'bold',
                        },
                        variant: 'body2',
                      }}
                      primary={'Select Payment Method'}
                    />
                  </ListItem>
                  <Divider />
                  <Formik
                    initialValues={
                      {
                        // email: '',
                        // cardExpiry: new Date(),
                      }
                    }
                    onSubmit={formikOnSubmit}
                    // validationSchema={validationSchema}
                  >
                    {(props) => (
                      <Form>
                        <ListItem>
                          <FormRadioGroup
                            name="paymentMethod"
                            options={[
                              { value: 'paynow', label: 'PayNow' },
                              { value: 'card', label: 'Card' },
                            ]}
                            onChange={() => {
                              setPaymentMethod(event.target.value);
                            }}
                          />
                        </ListItem>
                        <ListItem>
                          {paymentMethod === 'card' && (
                            <>
                              <MzButton
                                loading={checkoutSubmitting || orderSubmitting}
                                // disabled={!(props.isValid && props.dirty)}
                                fullWidth
                                title={'Checkout'}
                                size="large"
                                style={{ width: 180 }}
                                onClick={() => {
                                  formikOnSubmit();
                                }}
                              />
                              <Box pb={2} />
                            </>
                          )}
                        </ListItem>
                      </Form>
                    )}
                  </Formik>
                </List>
              </Paper>
            </Box>
          </Box>
          <Box
            p={smBelow ? 2 : 3}
            px={smBelow ? 2 : 8}
            display="flex"
            width="100%"
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: '#979797',
                color: theme.palette.common.white,
              }}
              onClick={() => {
                setStep(1);
                setPaymentMethod('');
              }}
              startIcon={<ArrowBackIcon />}
              size="large"
            >
              Cancel Payment
            </Button>
          </Box>
        </Paper>
      </>
    );
  };

  return (
    <ContentLayout
      header={
        <>
          <Typography variant="h2">Cart</Typography>
          <BreadcrumbsNav
            pathList={[
              { path: 'Home', route: ROUTES.DASHBOARD },
              {
                path: 'Cart',
                color: 'primary',
              },
            ]}
          />
        </>
      }
    >
      {step === 1 && renderStep1(totalPrice)}
      {step === 2 && renderStep2(totalPrice)}
    </ContentLayout>
  );
};

export default PaymentCart;
