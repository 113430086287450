import React, { useState, useContext } from 'react';
import { Box, Container } from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import { WebServicePackage } from 'src/constants/list';
import ServiceLayout from 'src/components/ServiceLayout';
import ImageTitleList from 'src/components/ImageTitleList/ImageTitleList';
import { Theme } from 'src/constants/list';
import { useParams, useHistory } from 'react-router-dom';
import { ConfigContext } from 'src/contexts';

const useStyles = makeStyles((theme) => ({}));

const WebServiceTheme = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { xsBelow } = useBreakpoints();
  const [selected, setSelected] = useState();
  const { addWebInfo } = useContext(ConfigContext);

  const renderChild = () => {
    return (
      <Container>
        <Box p={4} />
        <ImageTitleList
          title="Theme"
          itemData={Theme}
          overlay
          onClick={(item) => {
            setSelected(item.id);
          }}
          onView={(item) => {
            history.push(
              ROUTES.WEBSERVICE_THEME_INFO(
                params.packageid,
                params.categoryid,
                item.id,
              ),
            );
          }}
        />
        <Box p={3} />
        <MzButton
          title={'Continue'}
          disabled={!selected}
          onClick={() => {
            addWebInfo({ theme: selected });
            history.push(
              ROUTES.WEBSERVICE_PLAN(
                params.packageid,
                params.categoryid,
                selected,
              ),
            );
          }}
        />
        <Box p={3} />
      </Container>
    );
  };

  return <ServiceLayout title="Web Services" child={renderChild()} />;
};

export default WebServiceTheme;
