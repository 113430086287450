import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  button: {
    minHeight: '50px',
    width: '100%',
    color: '#FFFFFF',
    backgroundColor: '#A21D23',
    textTransform: 'initial',
  },
  fontWeight: {
    fontWeight: 600,
  },
}));
