import { makeStyles } from '@material-ui/core/styles';
import { AllServices } from 'src/constants/list';

export default makeStyles((theme) => ({
  button: {
    minWidth: '35px',
    height: '35px',
    color: '#FFB800',
    border: '1px solid #FFB800',
    margin: '10px',
  },
}));
