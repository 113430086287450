import SvgIcon from '@material-ui/core/SvgIcon';
import PersonIcon from '@material-ui/icons/Person';

// nav list
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

// top nav
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';

// dashboard cards
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';

// content
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

const PersonSettingIcon = (props) => {
  return (
    <SvgIcon>
      <PersonIcon />
    </SvgIcon>
  );
};
export const navGroups = [
  {
    title: 'Main',
    children: [
      {
        title: 'Overview',
        icon: DashboardOutlinedIcon,
        path: '/overview',
      },
    ],
  },
  {
    title: 'Complicane & Corporate',
    children: [
      {
        title: 'Secretarial',
        icon: AccountBalanceIcon,
        children: [
          {
            title: 'Dashboard',
            path: '/',
          },
          {
            title: 'Request',
            path: '/request',
          },
          {
            title: 'Document',
            path: '/document',
          },
          {
            title: 'Product',
            path: '/product',
          },
          {
            title: 'Tasklist',
            path: '/tasklist',
          },
          {
            title: 'Boardroom',
            path: '/boardroom',
          },
          {
            title: 'Cap Table',
            path: '/cap-table',
          },
          {
            title: 'Manage User',
            path: '/manage_user',
          },
          {
            title: 'Company Account Settings',
            path: '/company-account-settings',
          },
        ],
      },
    ],
  },
  {
    title: 'Business Banking',
    children: [
      {
        title: 'Bank Account',
        icon: LocalAtmIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Human Capital',
    children: [
      {
        title: 'Human Resource Management',
        icon: SupervisorAccountOutlinedIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
  {
    title: 'Virtual C-Suite',
    children: [
      {
        title: 'Virtual Chief Financial Officer',
        icon: PersonSettingIcon,
        path: '',
        children: [
          {
            title: 'Nav 1',
            path: '/',
          },
        ],
      },
    ],
  },
];
