import React from 'react';
import Modal from 'react-modal';
import FileViewer from 'react-file-viewer';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    width: '70%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function MyModalComponent(props) {
  console.log('modal', props.filetype);
  function afterOpenModal(e) {
    props.onAfterOpen(e, 'After Modal Opened');
  }

  function onModalClose(event) {
    let data = { name: 'example', type: 'closed from child' };
    props.onCloseModal(event, data);
  }
  const onError = (e) => {
    console.log(e, 'error in file-viewer');
  };

  return (
    <div>
      <Modal
        style={{
          overlay: {
            zIndex: 10000,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
          },
          content: {
            position: 'absolute',
            top: '40px',
            left: '40px',
            right: '40px',
            bottom: '40px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px',
          },
        }}
        isOpen={props.IsModalOpened}
        // onRequestClose={toggleModal}
        onAfterOpen={(e) => afterOpenModal(e)}
        contentLabel="Doc dialog"
        ariaHideApp={false}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={(e) => onModalClose(e)}>
            <CloseIcon />
          </IconButton>
          {/* <button
            style={{ borderRadius: 200 }}
            onClick={(e) => onModalClose(e)}
          >
            CLOSE
          </button> */}
        </div>
        {/* {isLoading ? <CircularProgress /> : <Table data={companyList} />} */}
        <FileViewer
          fileType={props.filetype}
          filePath={props.fileurl}
          onError={onError}
        />
      </Modal>
      {/* <Modal
        isOpen={props.IsModalOpened}
        onAfterOpen={(e) => afterOpenModal(e)}
        style={customStyles}
        ariaHideApp={false}
      >
        <h2>{props.dynData.title}</h2>
        <button onClick={(e) => onModalClose(e)}>close</button>
        <div>
          <ul>
            {props.dynData.body.map((fr) => (
              <li>{fr}</li>
            ))}
          </ul>
        </div>
      </Modal> */}
    </div>
  );
}

export default MyModalComponent;
