import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/Onboarding/Layout';
import OnboardingCompany from '../components/OnboardingCompany';

function OnboardingCompanyPage(props) {
  return <OnboardingCompany />;
}

OnboardingCompanyPage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default OnboardingCompanyPage;
