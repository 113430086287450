import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    list: {
      width: 250,
    },
    tab: {
      '& .MuiListItemText-root': {
        textAlign: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        border: `1px solid ${theme.palette.divider}`,
      },
    },
  }),
);
