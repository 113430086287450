import React from 'react';
import PropTypes from 'prop-types';

import SignInLayout from '../components/SignIn/Layout';
import SignUpForm from '../components/SignUp/SignUpForm';
import ResetPasswordForm from '../components/SignUp/ResetPasswordForm';
import SendVerificationEmailForm from '../components/SignUp/SendVerificationEmailForm';
import OTPVerificationForm from '../components/SignUp/OTPVerificationForm';

function Signinpage(props) {
  return (
    <SignInLayout>
      <SignUpForm />
      {/* <ResetPasswordForm /> */}
      {/* <SendVerificationEmailForm /> */}
      {/* <OTPVerificationForm /> */}
    </SignInLayout>
  );
}

Signinpage.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Signinpage;
