import axios from 'axios';

// Response data: {
//     available: true,
//     currency: 'USD',
//     definitive: false,
//     domain: 'sdfsdfscdscdscdssdf.com',
//     period: 1,
//     price: 8490000
//   }

function domainCheck(domainName) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `https://api.godaddy.com/v1/domains/available?domain=${domainName}&checkType=FAST&forTransfer=false`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `sso-key fYLbTTAgHdPV_WyYBAYg7An4KkKwBfGb6Gv:Rj2bt1qbhW2WZmABBWSTjm`,
          },
        },
      )
      .then(
        (rs) => {
          resolve(rs);
        },
        (err) => {
          reject(err);
        },
      );
  });
}

export default domainCheck;
