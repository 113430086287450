import React from 'react';
import { RadioGroup } from '@material-ui/core';
import { Box, Typography } from '@material-ui/core';
import useBreakpoints from 'src/hooks/useBreakpoints';
import useStyles from './RadioButton.styles';

const RadioButton = ({
  label,
  value,
  onChange,
  child,
  verified,
  unverified,
}) => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();

  return (
    <Box width="30%" paddingLeft="5px">
      <Box style={{ display: 'flex' }}>
        <Typography variant="body2" align="left" style={{ paddingRight: 5 }}>
          {label}
        </Typography>
        {verified && (
          <Typography
            variant="caption"
            align="left"
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 5,
              color: 'white',
              backgroundColor: '#43D249',
              height: 18,
            }}
          >
            {'Verified'}
          </Typography>
        )}
        {unverified && (
          <Typography
            variant="caption"
            align="left"
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              borderRadius: 5,
              color: 'white',
              backgroundColor: '#F54848',
              height: 18,
            }}
          >
            {'unverified'}
          </Typography>
        )}
      </Box>
      <RadioGroup
        name="gender"
        value={value}
        onChange={onChange}
        style={{ display: 'flex', flexDirection: 'row', flexWrap: 'initial' }}
      >
        {child}
      </RadioGroup>
      <Box p={1} />
    </Box>
  );
};

export default RadioButton;
