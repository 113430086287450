import React from 'react';

import SignInLayout from '../components/SignIn/Layout';
import ResetPasswordForm from '../components/SignUp/ResetPasswordForm';

function ResetPassword(props) {
  return (
    <SignInLayout>
      <ResetPasswordForm />
    </SignInLayout>
  );
}

export default ResetPassword;
