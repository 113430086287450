import React, { useState, useEffect, useRef } from 'react';
import * as faceapi from 'face-api.js';
import { Box } from '@material-ui/core';
// import useFaceAPI from './useFaceAPI';
import { useCamera } from './useCamera';

function startVideo(videoElement) {
  navigator.getUserMedia =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia;

  navigator.getUserMedia(
    { video: {} },
    (stream) => (videoElement.srcObject = stream),
    (err) => console.error(err),
  );
}

function getTop(l) {
  return l.map((a) => a.y).reduce((a, b) => Math.min(a, b));
}

function getMeanPosition(l) {
  return l
    .map((a) => [a.x, a.y])
    .reduce((a, b) => [a[0] + b[0], a[1] + b[1]])
    .map((a) => a / l.length);
}

const FaceDetectContainer = (props) => {
  const videoRef = useRef();
  const canvasRef = useRef();
  const videoContainerRef = useRef();
  const [facePosition, setFacePosition] = useState({
    centerValue: '',
    nose: '',
    distanceOfEyes: '',
    isLeft: false,
    isRight: false,
    isCenter: false,
  });
  const [isPlaying, setIsPlaying] = useState(false);
  // const [videooo, { setPlaying }] = useCamera(videoRef);
  // const { init } = useFaceAPI();
  // console.log('faceapi', {
  //   detectedLeft,
  //   detectedRight,
  // });

  const startDetect = async (videoElement, canvasElement) => {
    // const detections = await faceapi
    //   .detectAllFaces(videoElement, new faceapi.TinyFaceDetectorOptions())
    //   .withFaceLandmarks()
    //   .withFaceExpressions();
    const detections = await faceapi
      .detectAllFaces(videoElement)
      .withFaceLandmarks()
      .then((result) => {
        // Face is detected
        if (result && result.length > 0) {
          let res = result[0];
          var eye_right = getMeanPosition(res.landmarks.getRightEye());
          var eye_left = getMeanPosition(res.landmarks.getLeftEye());
          var nose = getMeanPosition(res.landmarks.getNose());
          var mouth = getMeanPosition(res.landmarks.getMouth());
          var jaw = getTop(res.landmarks.getJawOutline());

          var rx = (jaw - mouth[1]) / res.detection.box.height;
          var ry =
            (eye_left[0] + (eye_right[0] - eye_left[0]) / 2 - nose[0]) /
            res.detection.box.width;

          let eyesDistance = Math.hypot(
            eye_right[0] - eye_left[0],
            eye_right[1] - eye_left[1],
          );
          // console.log({
          //   eyesDistance,
          //   nose,
          // });
          // console.log(res.landmarks.getNose());
          // if (ry < 0) {
          //   setDetectedLeft((prev) => {
          //     if (!prev) return true;
          //   });
          // }
          // if (ry > 0) {
          //   setDetectedRight((prev) => {
          //     if (!prev) return true;
          //   });
          // }
          // console.log({
          //   score: res.detection.score, //Face detection score
          //   forward: ry, //Closest to 0 is looking forward; left (negative); right (positive)
          //   up: rx, // Closest to 0.5 is looking forward, closest to 0 is looking up
          // });
        } else {
          console.log('Face was not detected');
          // Face was not detected
        }
      });

    if (detections && detections.length > 0) {
      // const resizedDetections = faceapi.resizeResults(detections, displaySize);
      const dims = faceapi.matchDimensions(canvasElement, videoElement, true);
      const resizedDetections = faceapi.resizeResults(detections, dims);
      canvasElement
        .getContext('2d')
        .clearRect(0, 0, canvasElement.width, canvasElement.height);
      faceapi.draw.drawDetections(canvasElement, resizedDetections);
      faceapi.draw.drawFaceLandmarks(canvasElement, resizedDetections);
      faceapi.draw.drawFaceExpressions(canvasElement, resizedDetections);
    }
  };

  const onPlay = async () => {
    let videoElement = videoRef?.current;
    let canvasElement = canvasRef?.current;
    console.log('videoElement', videoElement);
    if (videoElement && canvasElement) {
      // const canvasElement = faceapi.createCanvasFromMedia(videoElement);
      // videoContainerRef?.current.append(canvasElement);
      // const displaySize = {
      //   width: videoElement.width,
      //   height: videoElement.height,
      // };
      // const dims = faceapi.matchDimensions(canvasElement, displaySize);

      const dims = faceapi.matchDimensions(canvasElement, videoElement, true);

      setInterval(async () => {
        // const detections = await faceapi
        //   .detectAllFaces(videoElement, new faceapi.TinyFaceDetectorOptions())
        //   .withFaceLandmarks()
        //   .withFaceExpressions();
        const detections = await faceapi
          .detectAllFaces(videoElement)
          .withFaceLandmarks();

        console.log('detections', detections);
        if (detections && detections.length > 0) {
          let result = detections;
          // Face is detected
          if (result && result.length > 0) {
            let res = result[0];
            var eye_right = getMeanPosition(res.landmarks.getRightEye());
            var eye_left = getMeanPosition(res.landmarks.getLeftEye());
            var nose = getMeanPosition(res.landmarks.getNose());
            var mouth = getMeanPosition(res.landmarks.getMouth());
            var jaw = getTop(res.landmarks.getJawOutline());

            var rx = (jaw - mouth[1]) / res.detection.box.height;
            var ry =
              (eye_left[0] + (eye_right[0] - eye_left[0]) / 2 - nose[0]) /
              res.detection.box.width;

            let eyesDistance = Math.hypot(
              eye_right[0] - eye_left[0],
              eye_right[1] - eye_left[1],
            );
            // console.log({
            //   eyesDistance,
            //   nose,
            // });
            // console.log(res.landmarks.getNose());
            // if (ry < 0) {
            //   setDetectedLeft((prev) => {
            //     if (!prev) return true;
            //   });
            // }
            // if (ry > 0) {
            //   setDetectedRight((prev) => {
            //     if (!prev) return true;
            //   });
            // }
            // console.log({
            //   score: res.detection.score, //Face detection score
            //   forward: ry, //Closest to 0 is looking forward; left (negative); right (positive)
            //   up: rx, // Closest to 0.5 is looking forward, closest to 0 is looking up
            // });
            setFacePosition((prev) => {
              let center = videoElement.clientWidth / 2;
              return {
                ...prev,
                centerValue: center,
                nose,
                isLeft: nose < center,
                isRight: nose > center,
              };
            });
          } else {
            console.log('Face was not detected');
            // Face was not detected
          }
          // const resizedDetections = faceapi.resizeResults(
          //   detections,
          //   displaySize,
          // );
          const resizedDetections = faceapi.resizeResults(detections, dims);

          canvasElement
            .getContext('2d')
            .clearRect(0, 0, canvasElement.width, canvasElement.height);
          faceapi.draw.drawDetections(canvasElement, resizedDetections);
          faceapi.draw.drawFaceLandmarks(canvasElement, resizedDetections);
          // faceapi.draw.drawFaceExpressions(canvasElement, resizedDetections);
        }
        // await startDetect(videoElement, canvasElement);
      }, 100);
    }
  };

  useEffect(() => {
    let videoElement = videoRef?.current;
    if (videoElement) {
      // init().then(() => startVideo(videoElement));
      Promise.all([
        faceapi.nets.ssdMobilenetv1.loadFromUri('/models'),
        faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
        faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
        faceapi.nets.faceLandmark68TinyNet.loadFromUri('/models'),
        faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
        // faceapi.nets.faceExpressionNet.loadFromUri('/models'),
        // faceapi.nets.mtcnn.loadFromUri('/models'),
        // faceapi.loadSsdMobilenetv1Model('/models'),
        // faceapi.loadTinyFaceDetectorModel('/models'),
        // faceapi.loadFaceLandmarkModel('/models'),
        // faceapi.loadFaceLandmarkTinyModel('/models'),
        // faceapi.loadFaceRecognitionModel('/models'),
        // faceapi.loadFaceExpressionModel('/models'),
        // faceapi.loadFaceDetectionModel('/models'),
      ]).then(() => {
        // setPlaying(true);
        // startVideo(videoElement);
      });

      // videoElement.addEventListener('play', onPlay);
      // return () => {
      //   videoElement.removeEventListener('play', onPlay);
      //   // videoElement.pause();
      //   setIsPlaying(false);
      // };
    }
  }, [videoRef, canvasRef]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (isPlaying) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  }, [isPlaying, videoRef]);

  const displaySize = {
    width: '256',
    height: '347',
  };
  return (
    <Box display="flex" justifyContent="center">
      <Box
        position="fixed"
        top={20}
        right={0}
        width={'200px'}
        border={1}
        style={{ backgroundColor: 'white' }}
      >
        <div>Status:</div>
        <div>
          <Box display="flex" border={1}>
            <div>Center value</div>
            <div>{facePosition.centerValue}</div>
          </Box>
          <Box display="flex" border={1}>
            <div>Nose position</div>
            <div>{facePosition.nose}</div>
          </Box>
          <Box display="flex" border={1}>
            <div>is left</div>
            <Box
              width="20px"
              height="20px"
              borderRadius="50%"
              style={{ backgroundColor: facePosition.isLeft ? 'green' : 'red' }}
            />
          </Box>
          <Box display="flex" border={1}>
            <div>is right</div>
            <Box
              width="20px"
              height="20px"
              borderRadius="50%"
              style={{
                backgroundColor: facePosition.isRight ? 'green' : 'red',
              }}
            />
          </Box>
        </div>
      </Box>
      <Box
        ref={videoContainerRef}
        position="relative"
        style={{
          width: displaySize.width + 'px',
          height: displaySize.height + 'px',
        }}
        display="flex"
        justifyContent="center"
        overflow="hidden"
      >
        <video
          ref={videoRef}
          // onLoadedMetadata={onPlay}
          autoPlay={true}
          muted
          // playsInline
          style={{
            height: '100%',
            transform: 'scaleX(-1)',
          }}
        />
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
          }}
        />
        <Box
          position="absolute"
          top={0}
          bottom={0}
          display="flex"
          justifyContent="center"
        >
          <canvas
            ref={canvasRef}
            {...displaySize}
            style={{
              transform: 'scaleX(-1)',
              // width: '100%',
              heigth: '100%',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FaceDetectContainer;
