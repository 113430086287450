import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  boxColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  fontWeight: {
    fontWeight: 600,
  },
  topbox: {
    display: 'flex',
    backgroundImage: 'linear-gradient(270deg, #760000 1.54%, #FFB800 101.02%);',
    minHeight: 100,
    width: '100%',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bottombox: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 320,
    height: '100%',
    width: '100%',
    boxShadow: '0 0 10px #ccc',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
  },
  avatarBorder: {
    border: `1px dashed #FFFFFF`,
    borderRadius: 100,
    padding: 10,
  },
  colorBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 50,
    width: 150,
  },
  viewButton: {
    justifyContent: 'flex-end',
    paddingBottom: 20,
  },
  rowDescription: {
    fontWeight: 600,
    paddingLeft: 30,
  },
  columnDescription: {
    fontWeight: 600,
    paddingLeft: 0,
    paddingTop: 20,
    paddingBottom: 20,
  },
}));
