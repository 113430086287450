import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  fontWeight: {
    fontWeight: 600,
  },
  upcomingPaper: {
    width: '100%',
    borderRadius: 10,
    height: '476px',
    overflowY: 'scroll',
  },
}));
