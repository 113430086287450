import axios from 'axios';
import { Config } from 'src/config';

export const S3UploadAndInsertDocuments = async (
  file,
  docType,
  dataType,
  dataId,
) => {
  if (file !== undefined) {
    const header = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
    };
    // Split the filename to get the name and type
    let fileParts = file.name.split('.');
    let fileName = fileParts[0];
    let fileType = fileParts[1];

    const respIdSigned = await axios.get(
      `${Config.apiUrl}/signedurl/?name=` +
        fileName +
        '&content-type=application%2F' +
        fileType,
      { headers: header },
    );
    // const { current } = refInputIdUpload;
    console.log('respIdSigned', respIdSigned);

    // console.log("refInputIdUpload", refInputIdUpload);
    var options = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/' + fileType,
      },
    };
    const respUploadFile = await axios.put(
      respIdSigned.data.uploadInfo.uploadURL.toString(),
      file,
      options,
      // {
      //   headers: header,
      // }
    );

    console.log('respUploadFile', respUploadFile.status);

    if (respUploadFile.status === 200) {
      const docData = {
        module_id: 1,
        document_container_id: 1,
        document_type_id: docType, //76 NRIC, 109 Proof of Residential, 110 CDD documents
        document_table_type_id: dataType, //table: Users, 5 UserAddresses, 4 CDD
        table_primary_id: dataId, //user_id, user_address_id, customer_due_diligence_id
        document_url: respIdSigned.data.uploadInfo.fileURL,
      };
      const respDocument = await axios.post(
        `${Config.apiUrl}/documents/`,
        docData,
        { headers: header },
      );

      console.log('respDocument', respDocument);
      return respDocument.data.document[0];
    }
  }
};

export const S3Upload = async (file) => {
  if (file !== undefined) {
    const header = {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
      'Content-Type': 'application/json',
    };
    // Split the filename to get the name and type
    let fileParts = file.name.split('.');
    let fileName = fileParts[0];
    let fileType = fileParts[1];

    const respIdSigned = await axios.get(
      `${Config.apiUrl}/signedurl/?name=` +
        fileName +
        '&content-type=application%2F' +
        fileType,
      { headers: header },
    );

    var options = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Content-Type': 'application/' + fileType,
      },
    };
    const respUploadFile = await axios.put(
      respIdSigned.data.uploadInfo.uploadURL.toString(),
      file,
      options,
      // {
      //   headers: header,
      // }
    );
    console.log('respUploadFile', respUploadFile);
    return respUploadFile;
  }
};
