import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { alpha, withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  InputBase,
  Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import MoreVertOutlinedIcon from '@material-ui/icons/MoreVertOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import NearMeOutlinedIcon from '@material-ui/icons/NearMeOutlined';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import SearchIcon from '@material-ui/icons/Search';

import documentSvc from 'src/services/document';
import authSvc from 'src/services/authentication';

import FormTextField from 'src/components/FormTextField/FormTextField.js';
import FormDropdownTextField from 'src/components/FormDropdownTextField/FormDropdownTextField.js';
import * as Yup from 'yup';
import useBreakpoints from 'src/hooks/useBreakpoints';
import MzButton from 'src/components/MzButton';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import useButtonStyle from 'src/styles/button';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideRequest } from 'src/hooks/useRequests';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import { useProvideProduct } from 'src/hooks/useProduct';
import GetStartedLayout from 'src/components/Corpsec/Documents/GetStartedLayout';
import FilePreviewModal from '../mymodal.component';
import LoadingComponent from '../loading.component';
import EmptyComponent from '../empty.component';

const useStyles = makeStyles((theme) => {
  return {
    dateColumn: {
      // maxWidth: 'auto',
      width: '160px',
    },
    actionColumn: {
      // maxWidth: 'auto',
      width: '90px',
    },
    textDanger: {
      color: theme.palette.error.main,
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      // marginRight: theme.spacing(2),
      // marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        // marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      width: '100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      // [theme.breakpoints.up('md')]: {
      //   width: '20ch',
      // },
      border: '1px solid #000',
      borderColor: theme.palette.grey['500'],
      borderRadius: theme.shape.borderRadius,
    },
  };
});

const DocumentFiles = ({
  toggleShowMenu,
  mainFolder: defaultMainFolder = null,
}) => {
  const classes = useStyles();
  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(false);
  const [folderLoaded, setFolderLoaded] = useState(false);
  const [mainFolder, setMainFolder] = useState(defaultMainFolder);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedFolders, setSelectedFolders] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [searchKeyword, setSearchKeyword] = useState('');
  const [showSearchResult, setShowSearchResult] = useState(false);

  const [showFilePreview, setShowFilePreview] = useState(false);
  const [loadingFilePreview, setLoadingFilePreview] = useState(false);

  const { smBelow, smAbove, mdAbove, mdBelow } = useBreakpoints();

  useEffect(() => {
    const data = authSvc.getAuthData();
    setUser(data.user);
  }, []);

  const handleGetFileType = async (url) => {
    setLoadingFilePreview(true);
    await fetch(url)
      .then((response) => {
        let filePreview = {
          filetype: response.headers
            .get('Content-Type')
            .slice(response.headers.get('Content-Type').lastIndexOf('/') + 1),
          document_url: url,
        };
        setShowFilePreview(filePreview);
        setLoadingFilePreview(false);
      })
      .catch((error) => [setLoadingFilePreview(false)]);
  };

  const handleDownloadFile = (url) => {
    fetch(url).then((response) => {
      // console.log(
      //   'response.headers',
      //   response.headers
      //     .get('Content-Type')
      //     .slice(response.headers.get('Content-Type').lastIndexOf('/') + 1),
      // );
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download =
          'download.' +
          response.headers
            .get('Content-Type')
            .slice(response.headers.get('Content-Type').lastIndexOf('/') + 1);
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  const handleFilePreviewClose = (event, data) => {
    setShowFilePreview(false);
  };

  const filterItemsByKeyword = (items, searchKeyword) => {
    let result = items;
    let isFolder = mainFolder && mainFolder['hasSubFolders'];
    if (searchKeyword) {
      setLoading(true);
      documentSvc.searchFiles(searchKeyword).then((rs) => {
        setFilteredItems(rs);
        setShowSearchResult(true);
        setLoading(false);
      });
      // result = result.filter((anItem) => {
      //   let title = isFolder ? anItem['name'] : anItem['document_type_name'];
      //   if (title) {
      //     if (title.toLowerCase().indexOf(searchKeyword.toLowerCase()) > -1) {
      //       return true;
      //     }
      //   }
      //   return false;
      // });
    } else {
      setFilteredItems(result);
    }
    return result;
  };

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);
  // useEffect(() => {
  //   if (searchKeyword) {
  //     filterItemsByKeyword(items, searchKeyword);
  //   } else {
  //     setFilteredItems(items);
  //   }
  // }, [searchKeyword, items]);

  useEffect(() => {
    setSelectedFolders([]);
    if (defaultMainFolder) {
      setMainFolder(defaultMainFolder);
    } else {
      setMainFolder(null);
    }
  }, [defaultMainFolder]);

  useEffect(() => {
    setSearchKeyword('');
    setShowSearchResult(false);
    if (selectedFolders && selectedFolders.length > 0) {
      let lastFolder = selectedFolders[selectedFolders.length - 1];
      setMainFolder(lastFolder);
    } else {
      setMainFolder(defaultMainFolder);
    }
  }, [selectedFolders]);

  useEffect(() => {
    if (mainFolder) {
      if (mainFolder.hasSubFolders) {
        if (mainFolder.child && mainFolder.child.length > 0) {
          setItems([...mainFolder.child]);
        } else {
          setItems([]);
        }
      } else {
        (async () => {
          setLoading(true);
          await documentSvc
            .getFiles(mainFolder.document_type_name)
            .then((rs) => {
              // folderList = rs;
              // setCrumbs([{ title: 'Documents', data: rs }]);
              setItems(rs);
              setFolderLoaded(true);
              setLoading(false);
            })
            .catch((error) => {
              setItems([]);
              setFolderLoaded(true);
              setLoading(false);
            });
        })();
      }
    } else {
      setFolderLoaded(true);
      setItems([]);
    }
  }, [mainFolder]);

  const handleOpenActionMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseActionMenu = (e, data, index) => {
    setAnchorEl(false);
  };

  const tableFolderColumns = [
    {
      title: mainFolder
        ? mainFolder.document_type_name || mainFolder.name
        : 'Undefined',
      dataIndex: 'name',
      render: (text) => {
        return (
          <Box display="flex" alignItems="center">
            <Box display="flex" mr={1} alignItems="center">
              <FolderOutlinedIcon />
            </Box>
            <Box flex={1}>{text}</Box>
            <Box display="flex" alignItems="center">
              <ChevronRightOutlinedIcon />
            </Box>
          </Box>
        );
      },
      cellProps: {
        component: 'th',
        scope: 'row',
      },
    },
  ];

  const tableFileColumns = [
    {
      title: mainFolder
        ? mainFolder.document_type_name || mainFolder.name
        : 'Undefined',
      dataIndex: 'document_type_name',
      cellProps: {
        component: 'th',
        scope: 'row',
      },
      render: (text) => {
        return (
          <Box display="flex" alignItems="center">
            <Box display="flex" mr={1} alignItems="center">
              <DescriptionOutlinedIcon />
            </Box>
            <Box flex={1}>{text}</Box>
          </Box>
        );
      },
    },
    {
      title: 'Last Opened',
      dataIndex: 'uploaded_date',
      cellProps: {
        className: (mdBelow && smAbove) || mdAbove ? classes.dateColumn : '',
      },
      render: (text) => {
        let result = '-';
        if (text) {
          result = moment(text).format('DD/MM/YYYY');
        }
        return <Typography>{result}</Typography>;
      },
    },
    {
      title: 'Action',
      cellProps: {
        className: classes.actionColumn,
      },
      render: (_, data, index) => {
        const handleOnClickItem = (e) => {
          handleCloseActionMenu(e, data, index);
        };
        // return null;
        return (
          <CustomMenu>
            <MenuItem
              onClick={(e) => {
                // handleOnClickItem(e);
                if (data['document_url']) {
                  handleGetFileType(data['document_url']);
                }
              }}
            >
              <ListItemIcon>
                <VisibilityOutlinedIcon />
              </ListItemIcon>
              Preview
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                // handleOnClickItem(e);
                if (window && window.location && data['document_url']) {
                  let mailToLink = `mailto:?body=${data['document_url']}`;
                  window.location.href = mailToLink;
                }
              }}
            >
              <ListItemIcon>
                <NearMeOutlinedIcon />
              </ListItemIcon>
              Email
            </MenuItem>
            <MenuItem
              onClick={(e) => {
                // handleOnClickItem(e);
                if (data['document_url']) {
                  handleDownloadFile(data['document_url']);
                }
              }}
            >
              <ListItemIcon>
                <CloudDownloadOutlinedIcon />
              </ListItemIcon>
              Download
            </MenuItem>
            {/* <MenuItem
                onClick={(e) => {
                  handleOnClickItem(e);
                }}
                className={classes.textDanger}
              >
                <ListItemIcon className={classes.textDanger}>
                  <DeleteOutlinedIcon />
                </ListItemIcon>
                Remove
              </MenuItem> */}
          </CustomMenu>
        );
      },
    },
  ];

  let breadcrumbItems = [
    {
      ...defaultMainFolder,
      onClick: () => {
        setSelectedFolders([]);
      },
    },
  ];
  if (selectedFolders && selectedFolders.length > 0) {
    selectedFolders.forEach((aFolder, index) => {
      breadcrumbItems.push({
        ...aFolder,
        onClick: () => {
          setSelectedFolders(selectedFolders.slice(0, index + 1));
        },
      });
    });
  }
  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        breadcrumbItems={breadcrumbItems}
        onBack={() => {
          setSelectedFolders(
            selectedFolders.slice(0, selectedFolders.length - 1),
          );
        }}
        header={
          mainFolder ? (
            <Box
              display="flex"
              // justifyContent="flex-end"
              // flexDirection={smBelow ? 'column' : 'row'}
              flexDirection={'row'}
              flexWrap="no-wrap"
              width="100%"
            >
              <Box
                // width={smBelow ? '100%' : 'auto'}
                width={'100%'}
              >
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search for anything..."
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                    value={searchKeyword}
                  />
                </div>
              </Box>
              <Box
                // ml={smBelow ? 0 : 1}
                // mt={smBelow ? 2 : 0}
                ml={1}
                alignSelf={smBelow ? 'flex-end' : 'flex-start'}
              >
                <Button
                  disableElevation
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    filterItemsByKeyword(items, searchKeyword);
                  }}
                >
                  Search
                </Button>
              </Box>
            </Box>
          ) : null
        }
      />
      <Divider />

      {mainFolder ? (
        <Box flex={1}>
          {loading ? (
            <LoadingComponent />
          ) : showSearchResult ? (
            <FilesTable
              columns={tableFileColumns}
              data={filteredItems}
              customCell={StyledTableCell}
            />
          ) : mainFolder.hasSubFolders ? (
            <FilesTable
              columns={tableFolderColumns}
              data={filteredItems}
              customCell={StyledTableCell}
              onClickRow={(rowData) => {
                setSelectedFolders([...selectedFolders, rowData]);
              }}
            />
          ) : (
            <FilesTable
              columns={tableFileColumns}
              data={filteredItems}
              customCell={StyledTableCell}
            />
          )}
        </Box>
      ) : (
        <EmptyComponent>Please select a Folder</EmptyComponent>
      )}
      {loadingFilePreview ? (
        <LoadingComponent />
      ) : (
        showFilePreview && (
          <FilePreviewModal
            filetype={showFilePreview.filetype}
            fileurl={showFilePreview.document_url}
            IsModalOpened={Boolean(showFilePreview)}
            onCloseModal={handleFilePreviewClose}
            onAfterOpen={() => {}}
          />
        )
      )}
    </>
  );
};

export default DocumentFiles;

const useTableStyles = makeStyles((theme) => ({
  pagination: {
    width: '100%',
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
  },
  clickableStyle: {
    cursor: 'pointer',
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.action.hover,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#FAFAFB',
    textTransform: 'uppercase',
  },
}))(TableCell);

const FilesTable = (props) => {
  const { data = [], columns = [], onClickRow, customCell = TableCell } = props;
  const classes = useTableStyles();

  const FilesTableCell = customCell;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setRowsPerPage(10);
    setPage(0);
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  let dataByPage = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="document files table" className={classes.table}>
          {columns && columns.length > 0 ? (
            <>
              <TableHead>
                <TableRow>
                  {columns.map((aColumn, index) => {
                    const {
                      title = '',
                      dataIndex,
                      render,
                      renderHeader,
                      cellProps = {},
                      ...restColumnProps
                    } = aColumn;
                    return (
                      <FilesTableCell key={index} {...cellProps}>
                        {renderHeader ? renderHeader(title, index) : title}
                      </FilesTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {dataByPage && dataByPage.length > 0 ? (
                  dataByPage.map((aData, index) => {
                    const handleOnClickRow = () => {
                      if (onClickRow) {
                        onClickRow(aData, index);
                      }
                    };
                    if (aData.type == 'folder') {
                      return (
                        <FolderRow
                          index={index}
                          columns={columns}
                          data={aData}
                          onClick={onClickRow}
                          className={onClickRow ? classes.clickableStyle : ''}
                          // Cell={StyledTableCell}
                        />
                      );
                    } else {
                      return (
                        <FileRow
                          index={index}
                          columns={columns}
                          data={aData}
                          // Cell={StyledTableCell}
                        />
                      );
                    }
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length}>
                      <EmptyComponent>Files not found</EmptyComponent>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </>
          ) : null}
        </Table>
      </TableContainer>
      {dataByPage && dataByPage.length > 0 && (
        <>
          <Box
            p={2}
          >{`Showing ${dataByPage.length} items out of ${data.length} results found`}</Box>
          <TablePagination
            className={classes.pagination}
            // rowsPerPageOptions={[10, 25, 100]}
            rowsPerPageOptions={[]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelDisplayedRows={({ from, to, count }) => {
              return '';
            }}
          />
        </>
      )}
    </>
  );
};

const FolderRow = (props) => {
  const {
    index,
    columns,
    data,
    className = '',
    onClick,
    Cell = TableCell,
  } = props;

  const handleOnClickRow = () => {
    if (onClick) {
      onClick(data, index);
    }
  };
  return (
    <TableRow onClick={handleOnClickRow} className={`${className}`}>
      {columns.map((aColumn, columnIndex) => {
        const {
          dataIndex,
          cellProps = {},
          render,
          renderHeader,
          ...restColumnProps
        } = aColumn;
        let columnData = data[dataIndex] || '-';

        return (
          <Cell
            key={columnIndex}
            // component="th"
            // scope="row"
            // onClick={handleOnClickRow}
            {...cellProps}
          >
            {render ? render(columnData, data, index) : columnData}
          </Cell>
        );
      })}
    </TableRow>
  );
};

const FileRow = (props) => {
  const {
    index,
    columns,
    data,
    className = '',
    onClick,
    Cell = TableCell,
  } = props;

  return (
    <TableRow className={`${className}`}>
      {columns.map((aColumn, columnIndex) => {
        const {
          dataIndex,
          cellProps = {},
          render,
          renderHeader,
          ...restColumnProps
        } = aColumn;
        let columnData = data[dataIndex] || '-';

        return (
          <Cell
            key={columnIndex}
            // component="th"
            // scope="row"
            // onClick={handleOnClickRow}
            {...cellProps}
          >
            {render ? render(columnData, data, index) : columnData}
          </Cell>
        );
      })}
    </TableRow>
  );
};

const CustomMenu = (props) => {
  const { children, ...restProps } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenActionMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseActionMenu = (e) => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton size="small" onClick={handleOpenActionMenu}>
        <MoreVertOutlinedIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseActionMenu}
      >
        {children}
      </Menu>
    </>
  );
};
