import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  changeButton: {
    backgroundColor: '#F2F3FA',
    textTransform: 'initial',
    paddingLeft: 30,
    paddingRight: 30,
  },
  label: {
    fontWeight: 700,
    color: '#16171C',
    paddingBottom: 5,
  },
  subLabel: {
    color: '#888B94',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    boxShadow: '#191919',
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  fontWeight: {
    fontWeight: 600,
  },
}));
