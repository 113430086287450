import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    mainTitle: {
      paddingBottom: 25,
      fontWeight: 600,
    },
    mainBox: {
      padding: 30,
      backgroundColor: 'rgba(240, 240, 240, 1)',
    },
    iconTitle: {
      paddingTop: 5,
      color: '#484848',
    },
    singleBox: {
      padding: 10,
    },
  }),
);
