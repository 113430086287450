import React from 'react';
import EnhancedDropdown from '../EnhancedDropdown/EnhancedDropdown.js';
import { Field } from 'formik';

const FormDropdown = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, meta }) => (
      <EnhancedDropdown
        {...field}
        {...props}
        error={meta.touched && !!meta.error}
        helperText={
          meta.touched && meta.error ? meta.error : props.helperText || ''
        }
      />
    )}
  </Field>
);

export default FormDropdown;
