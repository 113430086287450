import axios from 'axios';

/**
 * install
 * npm install axios
 *
 * import axios
 * const axios = require("axios").default;
 *
 * usage
 * await photoSearch('office', 1)
 *
 * @param {eg. office} query
 * @param {eg. 1} page
 * @returns
 */
function photoSearch(query, page) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `https://api.unsplash.com/search/photos?client_id=kD-EUdKbX2Nl9dJT4SnYGRgK7L5iNdYefYC5X_aTjzY&page=${page}&query=${query}`,
      )
      .then(
        (rs) => {
          resolve(rs.data);
        },
        (err) => {
          reject(err);
        },
      );
  });
}

export default photoSearch;
