import React, { useState, useContext } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import ServiceLayout from 'src/components/ServiceLayout';
import Stepper from 'src/components/Stepper';
import { useHistory, useParams } from 'react-router-dom';
import DetailLabel from 'src/components/DetailLabel';
import home1 from 'src/assets/webtemplates/qwery/default/home1.png';
import home2 from 'src/assets/webtemplates/qwery/default/home2.png';
import { ConfigContext } from 'src/contexts';

const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: 600,
  },
}));

const tempSteps = [
  {
    id: 1,
    label: 'Domain',
    route: ROUTES.CREATEWEBSITE,
  },
  {
    id: 2,
    label: 'Content',
    route: ROUTES.CREATEWEBSITE_CONTENT,
  },
  {
    id: 3,
    label: 'Corporate Identity',
    route: ROUTES.CREATEWEBSITE_CORPIDENTITY,
  },
  {
    id: 4,
    label: 'Images',
    route: ROUTES.CREATEWEBSITE_IMAGES,
  },
];

const TemplateContent = {
  Page1: {
    name: 'Page1 name example',
    header1: 'Page1 header 1 example',
    header2: 'Page1 header 2 example',
    discover: 'Page1 dicover more link example',
  },
  Page2: {
    name: 'Page2 name example',
    title: 'Page2 title example',
    feature1: 'Page2 feature1 example',
    icon1: 'Page2 icon1 example',
    link1: 'Page2 link1 example',
    title1: 'Page2 title1 example',
    desc1: 'Page2 desc1 example',
    feature2: 'Page2 feature2 example',
    icon2: 'Page2 icon2 example',
    link2: 'Page2 link2 example',
    title2: 'Page2 title2 example',
    desc2: 'Page2 desc2 example',
    feature3: 'Page2 feature3 example',
    icon3: 'Page2 icon3 example',
    link3: 'Page2 link3 example',
    title3: 'Page2 title3 example',
    desc3: 'Page2 desc3 example',
  },
  Page3: {
    name: 'Page3 name example',
    title: 'Page3 title example',
    feature1: 'Page3 feature1 example',
    icon1: 'Page3 icon1 example',
    link1: 'Page3 link1 example',
    title1: 'Page3 title1 example',
    desc1: 'Page3 desc1 example',
    feature2: 'Page3 feature2 example',
    icon2: 'Page3 icon2 example',
    link2: 'Page3 link2 example',
    title2: 'Page3 title2 example',
    desc2: 'Page3 desc2 example',
    feature3: 'Page3 feature3 example',
    icon3: 'Page3 icon3 example',
    link3: 'Page3 link3 example',
    title3: 'Page3 title3 example',
    desc3: 'Page3 desc3 example',
  },
};

const Content = () => {
  const classes = useStyles();
  const { mdBelow, smBelow, xsBelow } = useBreakpoints();
  const history = useHistory();
  const params = useParams();
  const [step, setStep] = useState(1);
  const { addWebInfo } = useContext(ConfigContext);

  const renderChild = () => {
    return (
      <Container>
        <Box p={3} />
        <Stepper
          steps={tempSteps}
          providedStep={step}
          setProvidedStep={(step) => {
            setStep(step);
            history.push(tempSteps[step].route(params.webdevid));
          }}
        />
        <Box p={3} />

        <Box display="flex" alignItems="center" flexDirection="column">
          <Box>
            <Typography
              variant="h5"
              align="left"
              className={classes.fontWeight}
            >
              Page 1: {TemplateContent.Page1.name}
            </Typography>
            <Box p={2} />
            <Box display={smBelow ? 'initial' : 'flex'} alignItems="center">
              <img
                src={home1}
                height={mdBelow ? (xsBelow ? 180 : 250) : 400}
                style={{ display: 'flex' }}
              />
              <Box px={10}>
                <DetailLabel
                  label="Header 1"
                  value={TemplateContent.Page1.header1}
                />
                <DetailLabel
                  label="Header 2"
                  value={TemplateContent.Page1.header2}
                />
                <DetailLabel
                  label="Dicover More Link"
                  value={TemplateContent.Page1.discover}
                />
              </Box>
            </Box>
          </Box>
          <Box p={3} />

          <Box>
            <Typography
              variant="h5"
              align="left"
              className={classes.fontWeight}
            >
              Page 2: {TemplateContent.Page1.name}
            </Typography>
            <Box display={smBelow ? 'initial' : 'flex'} alignItems="center">
              <img
                src={home2}
                height={mdBelow ? (xsBelow ? 180 : 250) : 400}
                style={{ display: 'flex' }}
              />
              <Box px={10}>
                <DetailLabel
                  label="Title"
                  value={TemplateContent.Page2.title}
                />
                <Box p={1} />
                <Typography
                  variant="body1"
                  align="left"
                  className={classes.fontWeight}
                >
                  Feature 1: {TemplateContent.Page2.feature1}
                </Typography>
                <DetailLabel label="Icon" value={TemplateContent.Page2.icon1} />
                <DetailLabel label="Link" value={TemplateContent.Page2.link1} />
                <DetailLabel
                  label="Title"
                  value={TemplateContent.Page2.title1}
                />
                <DetailLabel label="Desc" value={TemplateContent.Page2.desc1} />

                <Box p={1} />
                <Typography
                  variant="body1"
                  align="left"
                  className={classes.fontWeight}
                >
                  Feature 2: {TemplateContent.Page2.feature2}
                </Typography>
                <DetailLabel label="Icon" value={TemplateContent.Page2.icon1} />
                <DetailLabel label="Link" value={TemplateContent.Page2.link1} />
                <DetailLabel
                  label="Title"
                  value={TemplateContent.Page2.title1}
                />
                <DetailLabel label="Desc" value={TemplateContent.Page2.desc1} />

                <Box p={1} />
                <Typography
                  variant="body1"
                  align="left"
                  className={classes.fontWeight}
                >
                  Feature 3: {TemplateContent.Page2.feature3}
                </Typography>
                <DetailLabel label="Icon" value={TemplateContent.Page2.icon1} />
                <DetailLabel label="Link" value={TemplateContent.Page2.link1} />
                <DetailLabel
                  label="Title"
                  value={TemplateContent.Page2.title1}
                />
                <DetailLabel label="Desc" value={TemplateContent.Page2.desc1} />
              </Box>
            </Box>
          </Box>
          <Box p={3} />

          <Box>
            <Typography
              variant="h5"
              align="left"
              className={classes.fontWeight}
            >
              Page 3: {TemplateContent.Page1.name}
            </Typography>
            <Box display={smBelow ? 'initial' : 'flex'} alignItems="center">
              <img
                src={home2}
                height={mdBelow ? (xsBelow ? 180 : 250) : 400}
                style={{ display: 'flex' }}
              />
              <Box px={10}>
                <DetailLabel
                  label="Title"
                  value={TemplateContent.Page3.title}
                />
                <Box p={1} />
                <Typography
                  variant="body1"
                  align="left"
                  className={classes.fontWeight}
                >
                  Feature 1: {TemplateContent.Page3.feature1}
                </Typography>
                <DetailLabel label="Icon" value={TemplateContent.Page3.icon1} />
                <DetailLabel label="Link" value={TemplateContent.Page3.link1} />
                <DetailLabel
                  label="Title"
                  value={TemplateContent.Page3.title1}
                />
                <DetailLabel label="Desc" value={TemplateContent.Page3.desc1} />

                <Box p={1} />
                <Typography
                  variant="body1"
                  align="left"
                  className={classes.fontWeight}
                >
                  Feature 2: {TemplateContent.Page3.feature3}
                </Typography>
                <DetailLabel label="Icon" value={TemplateContent.Page3.icon1} />
                <DetailLabel label="Link" value={TemplateContent.Page3.link1} />
                <DetailLabel
                  label="Title"
                  value={TemplateContent.Page3.title1}
                />
                <DetailLabel label="Desc" value={TemplateContent.Page3.desc1} />

                <Box p={1} />
                <Typography
                  variant="body1"
                  align="left"
                  className={classes.fontWeight}
                >
                  Feature 3: {TemplateContent.Page3.feature3}
                </Typography>
                <DetailLabel label="Icon" value={TemplateContent.Page3.icon1} />
                <DetailLabel label="Link" value={TemplateContent.Page3.link1} />
                <DetailLabel
                  label="Title"
                  value={TemplateContent.Page3.title1}
                />
                <DetailLabel label="Desc" value={TemplateContent.Page3.desc1} />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box p={3} />
        <MzButton
          title={'Continue'}
          onClick={() => {
            addWebInfo({ template_content: JSON.stringify(TemplateContent) });
            history.push(ROUTES.CREATEWEBSITE_CORPIDENTITY(params.webdevid));
          }}
        />
        <Box p={3} />
      </Container>
    );
  };

  return <ServiceLayout title="Create Website" child={renderChild()} />;
};

export default Content;
