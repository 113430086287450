import { makeStyles } from '@material-ui/core/styles';
import { AllServices } from 'src/constants/list';

export default makeStyles((theme) => ({
  box: {
    display: 'flex',
    backgroundImage: 'linear-gradient(270deg, #760000 1.54%, #FFB800 101.02%);',
    minHeight: 100,
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: theme.spacing(4),
  },
  avatarBorder: {
    border: `1px dashed #FFFFFF`,
    borderRadius: 100,
    padding: 10,
  },
}));
