import React from 'react';
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from '@material-ui/core';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';

const useCustomStepIconStyles = makeStyles({
  root: {
    // color: '#eaeaf0',
    display: 'flex',
    width: '24px',
    height: '24px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  connector: {
    '& .MuiStepConnector-vertical': {
      paddingTop: '8px',
    },
  },
});

function CustomStepIcon(props) {
  const classes = useCustomStepIconStyles();
  const { active, completed } = props;
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Box
        // height="12px"
        // width="12px"
        className={classes.circle}
        style={{
          backgroundColor:
            active || completed
              ? theme.palette.primary.main
              : theme.palette.grey['300'],
        }}
      />
    </div>
  );
}

const CustomConnector = withStyles({
  root: {
    paddingTop: '3px',
    paddingBottom: '3px',
  },
})(StepConnector);

const CustomConnectorSmall = withStyles({
  root: {
    paddingTop: '2px',
    paddingBottom: '2px',
    // paddingTop: '0px',
    // paddingBottom: '0px',
  },
  lineVertical: {
    minHeight: '10px',
  },
})(StepConnector);

const StepsSidebar = (props) => {
  const { activeStep, steps, formPage, horizontal = false } = props;

  let result = [];

  if (horizontal) {
    steps.forEach((aStep, index) => {
      result.push(
        <Step key={aStep.id}>
          <StepLabel icon={index + 1} />
        </Step>,
      );
    });
    if (result.length > 0) {
      return (
        <Stepper
          activeStep={activeStep}
          // orientation='vertical'
          connector={null}
        >
          {result}
        </Stepper>
      );
    }
  } else {
    steps.forEach((aStep, index) => {
      let isActive = activeStep == aStep.id;
      result.push(
        <Step key={aStep.id}>
          <StepLabel icon={index + 1}>{aStep.label}</StepLabel>
          {index != steps.length - 1 && <CustomConnector />}
        </Step>,
      );
      if (aStep.children && aStep.children.length > 0 && isActive) {
        aStep.children.forEach((aChildStep, index2) => {
          result.push(
            <Step
              key={aStep.id + '' + index2}
              active={aChildStep.maxPageNum <= formPage}
            >
              <StepLabel StepIconComponent={CustomStepIcon}>
                <Typography variant="caption">{aChildStep.label}</Typography>
              </StepLabel>
              {index2 == aStep.children.length - 1 ? (
                <CustomConnector />
              ) : (
                <CustomConnectorSmall />
              )}
            </Step>,
          );
        });
      }
    });
    if (result.length > 0) {
      return (
        <Stepper
          activeStep={activeStep}
          orientation="vertical"
          // connector={<CustomConnector />}
          connector={null}
        >
          {result}
        </Stepper>
      );
    }
  }
  return null;
};

export default StepsSidebar;
