import React, { createContext } from 'react';
import {
  createDispatchHook,
  createSelectorHook,
  createStoreHook,
  Provider,
} from 'react-redux';
import { storeSvc } from './services/store';
import store from './Store';

export const Context = createContext(null);
export const useStore = createStoreHook(Context);
export const useDispatch = createDispatchHook(Context);
export const useSelector = createSelectorHook(Context);

storeSvc.setStore(store);
const AppProvider = ({ children }) => {
  return (
    <Provider context={Context} store={store}>
      {children}
    </Provider>
  );
};

export const ContextProvider = Context.Provider;
export const ContextConsumer = Context.Consumer;
export default AppProvider;
