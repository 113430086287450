import React from 'react';
import {
  Box,
  Button,
  Dialog,
  Typography,
  Paper,
  ButtonBase,
} from '@material-ui/core';
import EnhancedTextField from 'src/components/EnhancedTextField/EnhancedTextField';
import useStyles from './OtpAlert.styles';

const OtpAlert = ({
  isVisible,
  onBackdropPress,
  onClick,
  onPressResend,
  onPressContactUs,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={isVisible} onClose={onBackdropPress}>
      <Box
        padding={5}
        paddingLeft={12}
        paddingRight={12}
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Typography variant="h1" align="center">
          OTP Verification
        </Typography>
        <Box p={2} />

        <Paper
          variant="outlined"
          style={{
            padding: 20,
            borderRadius: '5px',
            border: '1px solid #FFFFFF',
            backgroundColor: '#CFEEFA',
          }}
        >
          <Typography
            variant="body1"
            align="center"
            style={{
              color: '#888B94',
            }}
            display="inline"
          >
            Please enter the 6-digit verification code we sent to your email
            address -&nbsp;
            <Typography
              variant="body1"
              align="center"
              display="inline"
              style={{ color: '#16171C' }}
            >
              ********gmail.com
            </Typography>
          </Typography>
        </Paper>
        <Box p={1} />

        <EnhancedTextField
          fullWidth
          placeholder="Enter Verification Code"
          shrink
          variant="outlined"
        />
        <Button
          variant="contained"
          size="large"
          className={classes.button}
          onClick={onClick}
        >
          <Typography variant="h5" align="center" color="white">
            Submit
          </Typography>
        </Button>
        <Box p={2} />
        <Typography variant="body1" align="center" style={{ display: 'flex' }}>
          Didn’t receive a code?&nbsp;
          <ButtonBase onClick={onPressResend}>
            <Typography
              variant="body1"
              align="center"
              className={classes.fontWeight}
            >
              Resend
            </Typography>
          </ButtonBase>
        </Typography>

        <Box p={1} />
        <Typography variant="body1" align="center" style={{ display: 'flex' }}>
          Need more help?&nbsp;
          <ButtonBase onClick={onPressContactUs}>
            <Typography variant="body1" align="center" color="primary">
              Contact us
            </Typography>
          </ButtonBase>
        </Typography>
      </Box>
    </Dialog>
  );
};

export default OtpAlert;
