import React, { useState, useContext } from 'react';
import {
  Box,
  Container,
  Typography,
  ButtonBase,
  makeStyles,
} from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import ServiceLayout from 'src/components/ServiceLayout';
import MaterialTable from 'material-table';
import { Icon } from '@iconify/react';
import { ConfigContext } from 'src/contexts';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  fontWeight: {
    fontWeight: 600,
  },
  gradientButton: {
    width: '180px',
    height: '50px',
    border: '2px solid transparent',
    borderRadius: '30px',
    backgroundImage:
      'linear-gradient(white, white), linear-gradient(to left, #760000 1.54%, #FFB800 101.02%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
  },
}));

const WebServicePlan = () => {
  const classes = useStyles();
  const history = useHistory();
  const { smBelow } = useBreakpoints();
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const { addCart, addWebInfo } = useContext(ConfigContext);

  const renderHeaderStyle = {
    border: '1px solid rgba(224, 224, 224, 1)',
  };
  const renderCellStyle = {
    border: '1px solid rgba(224, 224, 224, 1)',
  };
  const renderSelectedHeaderStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    border: '1px solid rgba(0, 0, 0, 0.2)',
  };
  const renderSelectedCellStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
  };

  const renderTick = (
    <Icon icon="teenyicons:tick-small-outline" width="30" height="30" />
  );

  const headerList = [
    { field: 'details' },
    {
      id: '401',
      title: 'VIP',
      subtitle: 'First Priority Support',
      price: '100',
      field: 'vip',
    },
    {
      id: '402',
      title: 'Unlimited',
      subtitle: 'Freelancers',
      price: '80',
      field: 'unlimited',
    },
    {
      id: '403',
      title: 'Combo',
      subtitle: 'Personal User',
      price: '50',
      field: 'combo',
    },
    {
      id: '404',
      title: 'Domain',
      subtitle: 'Most Basic',
      price: '30',
      field: 'domain',
    },
  ];

  const renderHeader = (header, index) => {
    return (
      <>
        {index !== 0 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5" className={classes.fontWeight}>
              {header.title}
            </Typography>
            <Typography variant="body1">{header.subtitle}</Typography>
            <Box p={2} />
            <Typography variant="h5">${header.price}</Typography>
            <Box p={1} />
            {index === selectedIndex && (
              <MzButton
                title="Select"
                size
                style={{
                  width: '180px',
                  height: '50px',
                }}
              />
            )}
            {index !== selectedIndex && (
              <ButtonBase
                type="submit"
                variant="contained"
                className={classes.gradientButton}
                onClick={() => {
                  setSelectedIndex(index);
                  setSelectedPlan(header);
                }}
              >
                <Typography variant="body1" className={classes.fontWeight}>
                  Select
                </Typography>
              </ButtonBase>
            )}
            <Box p={1} />
          </Box>
        )}
      </>
    );
  };

  const renderHeaderColumn = () => {
    return headerList.map((header, index) => ({
      title: renderHeader(header, index),
      field: header.field,
      headerStyle:
        index === selectedIndex ? renderSelectedHeaderStyle : renderHeaderStyle,
      cellStyle:
        index === selectedIndex ? renderSelectedCellStyle : renderCellStyle,
      align: index === 0 ? 'left' : 'center',
    }));
  };

  const renderChild = () => {
    return (
      <Container>
        <Box p={3} />
        <MaterialTable
          options={{
            paging: false,
            toolbar: false,
            sorting: false,
            draggable: false,
          }}
          columns={renderHeaderColumn()}
          data={[
            {
              details: 'Custom Domain',
              vip: renderTick,
              unlimited: renderTick,
              combo: renderTick,
              domain: renderTick,
            },

            {
              details: 'Custom Domain',
              vip: renderTick,
              unlimited: renderTick,
              combo: renderTick,
              domain: '-',
            },
            {
              details: 'Free Domain for 1 Year',
              vip: renderTick,
              unlimited: renderTick,
              combo: renderTick,
              domain: '-',
            },
            {
              details: 'Remove Wix Ads',
              vip: renderTick,
              unlimited: renderTick,
              combo: renderTick,
              domain: renderTick,
            },
            {
              details: 'Free SSL Certificate',
              vip: renderTick,
              unlimited: renderTick,
              combo: renderTick,
              domain: renderTick,
            },
            {
              details: 'Bandwidth',
              vip: 'Unlimited',
              unlimited: 'Unlimited',
              combo: renderTick,
              domain: renderTick,
            },
            {
              details: 'Storage Space',
              vip: renderTick,
              unlimited: renderTick,
              combo: renderTick,
              domain: renderTick,
            },
            {
              details: 'Video Hours',
              vip: renderTick,
              unlimited: renderTick,
              combo: renderTick,
              domain: renderTick,
            },
            {
              details: 'Site Booster App',
              vip: renderTick,
              unlimited: renderTick,
              combo: renderTick,
              domain: renderTick,
            },
            {
              details: 'Visitor Analytics App',
              vip: renderTick,
              unlimited: renderTick,
              combo: renderTick,
              domain: renderTick,
            },
            {
              details: 'Professional Logo',
              vip: renderTick,
              unlimited: renderTick,
              combo: renderTick,
              domain: renderTick,
            },
            {
              details: 'Social Media Logo Files',
              vip: renderTick,
              unlimited: renderTick,
              combo: renderTick,
              domain: renderTick,
            },
            {
              details: 'Customer Care',
              vip: renderTick,
              unlimited: renderTick,
              combo: renderTick,
              domain: renderTick,
            },
          ]}
        />
        <Box p={3} />
        <MzButton
          onClick={() => {
            addWebInfo({ plan: selectedPlan.id });
            addCart({ type: selectedPlan.title, price: selectedPlan.price });
            history.push(ROUTES.PAYMENTCART);
          }}
          title={'Continue'}
          disabled={!selectedIndex}
        />
        <Box p={3} />
      </Container>
    );
  };

  return <ServiceLayout title="Web Services" child={renderChild()} />;
};

export default WebServicePlan;
