import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import {
  TAB_DASHBOARD,
  TAB_DOCUMENTS,
  TAB_PROFILE,
  TAB_TASKLIST,
  TAB_BOARDROOM,
  TAB_CAPTABLE,
  TAB_REQUESTS,
} from 'src/constants/corpsec';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import companySvc from 'src/services/company';
import useStyles from './Sidebar.styles';

const Sidebar = () => {
  const setFirstContent = () => {
    if (location.pathname === ROUTES.CORPSEC) {
      return TAB_DASHBOARD.value;
    }
    if (location.pathname === ROUTES.CORPSEC_DOC) {
      return TAB_DOCUMENTS.value;
    }
    if (location.pathname === ROUTES.CORPSEC_PRO) {
      return TAB_PROFILE.value;
    }
    if (location.pathname === ROUTES.CORPSEC_TASK) {
      return TAB_TASKLIST.value;
    }
    if (location.pathname === ROUTES.CORPSEC_BOARD) {
      return TAB_BOARDROOM.value;
    }
    if (location.pathname === ROUTES.CORPSEC_CAP) {
      return TAB_CAPTABLE.value;
    }
    if (
      location.pathname === ROUTES.CORPSEC_REQ ||
      location.pathname === ROUTES.CORPSEC_REQ_APPT_ADMIN ||
      location.pathname === ROUTES.CORPSEC_REQ_APPT_DIRECTOR ||
      location.pathname === ROUTES.CORPSEC_REQ_RESGN_ADMIN ||
      location.pathname === ROUTES.CORPSEC_REQ_RESGN_DIRECTOR ||
      location.pathname === ROUTES.CORPSEC_REQ_DIRECTOR_LIST ||
      location.pathname === ROUTES.CORPSEC_REQ_CHANGE_OFFICER
    ) {
      return TAB_REQUESTS.value;
    }
  };

  const renderTabList = () => {
    const company = companySvc.getCompany();

    return TABS.map((t) => (
      <React.Fragment key={t.value}>
        <ListItem
          button
          key={t.value}
          selected={tab === t.value}
          className={classes.tab}
          onClick={() => {
            if (t.companyRequired && !company) {
              alert('Select company to proceed');
              return;
            }

            setTab(t.value);
            history.push(t.route);
          }}
        >
          <ListItemText primary={t.name} />
        </ListItem>
        <Divider />
      </React.Fragment>
    ));
  };

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [tab, setTab] = useState(setFirstContent());
  const { mdBelow } = useBreakpoints();

  const TABS = [
    TAB_DASHBOARD,
    TAB_REQUESTS,
    TAB_DOCUMENTS,
    TAB_PROFILE,
    TAB_TASKLIST,
    TAB_BOARDROOM,
    TAB_CAPTABLE,
  ];

  var settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {!mdBelow && (
        <Paper elevation={9} className={classes.list}>
          <List>{renderTabList()}</List>
        </Paper>
      )}

      {mdBelow && <Slider {...settings}>{renderTabList()}</Slider>}
    </>
  );
};

export default Sidebar;
