import React from 'react';
import { Typography, Box, Container, Button } from '@material-ui/core';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import img from 'src/assets/errorpage/scheduledmaintenance.png';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fontWeight: {
    fontWeight: 600,
  },
}));

const ScheduledMaintenance = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container className={classes.root}>
      <Box p={8} />
      <img src={img} height="60%" width="60%" />
      <Box p={2} />

      <Typography variant="h2" className={classes.fontWeight}>
        We are under scheduled maintenance.
      </Typography>
      <Box p={1} />
      <Typography variant="body1">
        Sorry for the inconvenience, we will be back shortly!
      </Typography>
      <Box p={3} />

      <Button
        variant="contained"
        size="large"
        color="primary"
        style={{
          textTransform: 'initial',
        }}
        onClick={() => history.push(ROUTES.DASHBOARD)}
      >
        Go to dashboad
      </Button>
    </Container>
  );
};

export default ScheduledMaintenance;
