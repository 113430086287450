import React from 'react';
import { TextField, Typography, Box } from '@material-ui/core';
import { Icon } from '@iconify/react';

const EnhancedTextField = ({
  children,
  disabled,
  required,
  variant = 'standard',
  shrink = false,
  labelProps = {},
  paddingBottom = true,
  ...props
}) => (
  <>
    <Box style={{ display: 'flex' }}>
      <Icon icon={props.icon} width="20" height="20" />
      <Typography variant="body2" align="left" {...labelProps}>
        {props.labelText}
      </Typography>
      <Typography
        variant="body2"
        align="left"
        style={{ paddingLeft: 5, fontStyle: 'italic', color: '#808080' }}
      >
        {props.optional && '(optional)'}
      </Typography>
      {props.verified && (
        <Typography
          variant="caption"
          align="left"
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 5,
            color: 'white',
            backgroundColor: '#43D249',
            height: 18,
          }}
        >
          {'Verified'}
        </Typography>
      )}
      {props.unverified && (
        <Typography
          variant="caption"
          align="left"
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 5,
            color: 'white',
            backgroundColor: '#F54848',
            height: 18,
          }}
        >
          {'unverified'}
        </Typography>
      )}
    </Box>
    <TextField
      disabled={disabled}
      InputLabelProps={shrink ? { shrink } : {}}
      variant={variant}
      style={{ paddingBottom: paddingBottom ? 30 : 0 }}
      {...props}
    />
  </>
);

export default EnhancedTextField;
