import React from 'react';
import {
  Typography,
  Box,
  Button,
  Modal,
  Fade,
  Backdrop,
  ButtonBase,
  Paper,
} from '@material-ui/core';
import useStyles from './ButtonModal.styles';
import useBreakpoints from 'src/hooks/useBreakpoints';
import CloseIcon from '@material-ui/icons/Close';

const ButtonModal = ({
  isModalOpen,
  onCloseModal,
  label,
  subLabel,
  onPress1,
  onPress2,
  buttonLabel2,
  hideLabel,
  hideButtons,
  modalHeight,
  children,
}) => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();

  return (
    <Modal
      className={classes.modal}
      open={isModalOpen}
      onClose={onCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <>
          <Paper
            className={classes.modalPaper}
            style={{ height: modalHeight ? modalHeight : '50%' }}
          >
            <>
              {!hideLabel && (
                <Box p={4}>
                  <Box display="flex">
                    <Typography variant="h2" className={classes.fontWeight}>
                      {label}
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <ButtonBase onClick={onCloseModal}>
                      <CloseIcon style={{ color: '#84818A' }} />
                    </ButtonBase>
                  </Box>
                  <Typography variant="body1">{subLabel}</Typography>
                </Box>
              )}
              {children}
              <div style={{ flexGrow: 1 }} />
              {!hideButtons && (
                <Box
                  p={3}
                  style={{
                    backgroundColor: '#FAFAFD',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    style={{
                      textTransform: 'initial',
                      width: '15%',
                    }}
                    onClick={onPress1}
                  >
                    Cancel
                  </Button>
                  <Box p={1} />
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    style={{
                      textTransform: 'initial',
                      width: '15%',
                    }}
                    onClick={onPress2}
                  >
                    {buttonLabel2 ? buttonLabel2 : 'Confirm'}
                  </Button>
                </Box>
              )}
            </>
          </Paper>
        </>
      </Fade>
    </Modal>
  );
};

export default ButtonModal;
