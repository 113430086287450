import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { SNACKBAR_MESSAGE } from '../constants/messages';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Icon } from '@iconify/react';

const SetSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const action = useCallback(
    (key) => (
      <>
        <IconButton
          aria-label="close"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <Icon icon="eva:close-outline" width="20" height="20" color="white" />
        </IconButton>
      </>
    ),
    [closeSnackbar],
  );

  const showErrorSnackbar = useCallback(
    (newMessage = '', timeout = 10000) => {
      enqueueSnackbar(newMessage || SNACKBAR_MESSAGE.error, {
        variant: 'error',
        autoHideDuration: timeout,
        action,
      });
    },
    [enqueueSnackbar, action],
  );

  const showWarningSnackbar = useCallback(
    (newMessage = '', timeout = 5000) => {
      enqueueSnackbar(newMessage || SNACKBAR_MESSAGE.warning, {
        variant: 'warning',
        autoHideDuration: timeout,
        action,
      });
    },
    [enqueueSnackbar, action],
  );

  const showSuccessSnackbar = useCallback(
    (newMessage = '', timeout = 5000) => {
      enqueueSnackbar(newMessage || SNACKBAR_MESSAGE.success, {
        variant: 'success',
        autoHideDuration: timeout,
        action,
      });
    },
    [enqueueSnackbar, action],
  );

  return { showErrorSnackbar, showWarningSnackbar, showSuccessSnackbar };
};

export default SetSnackbar;
