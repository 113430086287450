import React from 'react';
import { Box, FormHelperText, InputLabel, Typography } from '@material-ui/core';

const FieldContainer = (props) => {
  const {
    label,
    error,
    className = '',
    style = {},
    children,
    ...restProps
  } = props;
  return (
    <Box
      className={`${className}`}
      style={{
        ...style,
      }}
      {...restProps}
    >
      <Box>
        <Typography variant="body1">{label}</Typography>
      </Box>
      {children}
      <FormHelperText hidden={!error} error>
        {error}
      </FormHelperText>
    </Box>
  );
};

export default FieldContainer;
