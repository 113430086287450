import React, { useEffect, useState, useContext } from 'react';
import { Box, Container, OutlinedInput, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import MzButton from 'src/components/MzButton';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import ServiceLayout from 'src/components/ServiceLayout';
import ImageTitleList from 'src/components/ImageTitleList/ImageTitleList';
import Stepper from 'src/components/Stepper';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FormFileDropzone } from 'src/components/Form';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Images } from 'src/constants/list';
import SearchIcon from '@material-ui/icons/Search';
import AlertBox from 'src/components/AlertBox';
import photoSearch from 'src/services/unsplashApi';
import { useHistory, useParams } from 'react-router-dom';
import { ConfigContext } from 'src/contexts';
import { useProvideWebDev } from 'src/hooks/useWebDev';
import { Theme } from 'src/constants/list';
import { S3UploadAndInsertDocuments } from 'src/utils/aws';
import useSnackbar from 'src/hooks/useSnackbar';

const useStyles = makeStyles((theme) => ({}));

const tempSteps = [
  {
    id: 1,
    label: 'Domain',
    route: ROUTES.CREATEWEBSITE,
  },
  {
    id: 2,
    label: 'Content',
    route: ROUTES.CREATEWEBSITE_CONTENT,
  },
  {
    id: 3,
    label: 'Corporate Identity',
    route: ROUTES.CREATEWEBSITE_CORPIDENTITY,
  },
  {
    id: 4,
    label: 'Images',
    route: ROUTES.CREATEWEBSITE_IMAGES,
  },
];

const Background = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const { smBelow } = useBreakpoints();
  const [imageUrl, setImageUrl] = useState('');
  const [step, setStep] = useState(3);
  const [keyword, setKeyword] = useState(null);
  const [alert, showAlert] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [keywordImage, setKeywordImage] = useState(null);
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(10);
  const { addWebInfo, webInfo } = useContext(ConfigContext);
  const { UpdateWebDev, ListWebDevById, webdev } = useProvideWebDev();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();

  const validationSchema = Yup.object({
    backgroundFiles: Yup.array().when('imageUrl', {
      is: () => imageUrl === '',
      then: Yup.array().min(1),
    }),
  });

  useEffect(() => {
    if (!webInfo.webdev_id) {
      ListWebDevById(params.webdevid);
    }
  }, []);

  useEffect(() => {
    let initKeyword = Theme.find((category) => category.id === webdev.theme);
    setKeyword(initKeyword?.title);
    handleSearch();
  }, [webdev]);

  const formikOnSubmit = async (values, actions) => {
    setSubmitting(true);
    values.webdev_category_id = 13;
    if (webInfo.corporate_identity_attachment) {
      const corpLogo = await S3UploadAndInsertDocuments(
        webInfo.corporate_identity_attachment,
        110,
        4,
        'customer_due_diligence_id',
      );
      values.corporate_identity_attachment_id = corpLogo.document_id;
    }

    if (values.backgroundFiles.length > 0) {
      const backgroundImage = await S3UploadAndInsertDocuments(
        values.backgroundFiles[0],
        110,
        4,
        'customer_due_diligence_id',
      );
      values.image_attachment_id = backgroundImage.document_id;
    }

    if (imageUrl) {
      values.list_of_images = imageUrl;
    }

    let newWebInfo = {
      ...webdev,
      ...webInfo,
      ...values,
    };
    const res = await UpdateWebDev(newWebInfo, params.webdevid);
    setSubmitting(false);

    if (res.status === 'success') {
      showSuccessSnackbar('Application submitted.');
    } else {
      showErrorSnackbar(res.message);
      return;
    }

    // showAlert(true);
  };

  const handleSearch = async (event, page) => {
    let keywordImage = [];
    const selectedPage = page ? page : 1;
    setPage(selectedPage);
    const res = await photoSearch(keyword, selectedPage);
    setTotalPage(res.total_pages);

    for (let i = 0; i < res.results.length; i++) {
      keywordImage.push({
        id: res.results[i].id,
        img: res.results[i].urls.full,
      });
    }

    setKeywordImage(keywordImage);
  };

  const renderChild = () => {
    return (
      <Container>
        <AlertBox
          title="Success"
          isVisible={alert}
          onBackdropPress={() => showAlert(false)}
          topButtonAction={() => showAlert(false)}
          topButtonLabel="OK"
          iconImg="teenyicons:tick-circle-outline"
          iconColor="green"
        />
        <Box p={3} />
        <Stepper
          steps={tempSteps}
          providedStep={step}
          setProvidedStep={(step) => {
            setStep(step);
            history.push(tempSteps[step].route(params.webdevid));
          }}
        />
        <Box p={3} />
        <Formik
          initialValues={{
            backgroundFiles: [],
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                width="100%"
                alignItems="center"
              >
                <Typography
                  variant="h5"
                  align="left"
                  style={{ fontWeight: 600 }}
                  className={classes.mainTitle}
                >
                  Images
                </Typography>
                <Box p={2} />
                <OutlinedInput
                  style={{
                    borderRadius: '50px',
                  }}
                  placeholder="Search files"
                  onChange={(e) => {
                    setKeyword(e.target.value);
                  }}
                  value={keyword}
                  startAdornment={
                    <InputAdornment>
                      <SearchIcon />
                    </InputAdornment>
                  }
                  fullWidth
                  labelWidth={0}
                />
                <Box p={2} />
                {!smBelow && (
                  <MzButton title={'Search'} onClick={handleSearch} />
                )}
              </Box>
              {smBelow && (
                <>
                  <Box p={1} />
                  <MzButton title={'Search'} onClick={handleSearch} />
                </>
              )}
              <Box p={3} />
              <ImageTitleList
                name="image"
                itemData={!!keywordImage ? keywordImage : Images}
                overlay
                onClick={(item) => {
                  setImageUrl(item.img);
                }}
              />
              <Box p={1} />
              {keywordImage && (
                <Pagination
                  count={totalPage}
                  page={page}
                  color="secondary"
                  onChange={handleSearch}
                />
              )}
              <Box p={3} />
              <FormFileDropzone
                name="backgroundFiles"
                labelText="My Images"
                containerWidth="80%"
              />
              <Box p={3} />
              <MzButton
                type="submit"
                loading={submitting}
                title={'Continue'}
                disabled={!((props.isValid && props.dirty) || imageUrl)}
                onClick={() => {}}
              />
            </Form>
          )}
        </Formik>
        <Box p={3} />
      </Container>
    );
  };

  return <ServiceLayout title="Create Website" child={renderChild()} />;
};

export default Background;
