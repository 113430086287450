import React from 'react';
import { Box } from '@material-ui/core';
import { generate } from 'shortid';
import { Skeleton } from '@material-ui/lab';

const EnhancedSkeleton = ({ layout }) => {
  return (
    <Box>
      {layout.map((element) => {
        const { loaders = [], repeat, ...rest } = element;
        return [...new Array(element.repeat || 1)].map(() => (
          <Box {...rest} key={generate()}>
            {loaders.map((content) => (
              <Skeleton {...content} key={generate()} />
            ))}
          </Box>
        ));
      })}
    </Box>
  );
};

export default EnhancedSkeleton;
