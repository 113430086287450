import {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { APIRequest } from 'src/utils/api';
import { Config } from 'src/config';

const userAddressesContext = createContext({
  userAddressess: [],
  userAddressesError: '',
  userAddressesLoading: false,
  userAddressesSubmitting: false,
  ListUserAddressess: () => Promise.resolve({}),
  ReadUserAddress: () => Promise.resolve({}),
});

export function useProvideUserAddresses() {
  const [userAddressess, setUserAddressess] = useState([]);

  const [userAddressesError, setUserAddressesError] = useState();
  const [userAddressesLoading, setUserAddressesLoading] = useState(false);
  const [userAddressesSubmitting, setUserAddressesSubmitting] = useState(false);

  const ListUserAddressess = async (userID) => {
    const res = await APIRequest({
      setError: setUserAddressesError,
      setLoading: setUserAddressesLoading,
      path: `${Config.addressURL}/users/${userID}/addresses`,
      method: 'get',
    });
    if (res.status === 'success') {
      setUserAddressess(res.body.userAddresses);
    }
    return res;
  };

  const ReadUserAddress = async (userID, addressID) => {
    const res = await APIRequest({
      setError: setUserAddressesError,
      setLoading: setUserAddressesLoading,
      path: `${Config.addressURL}/users/${userID}/addresses/${addressID}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setUserAddressess([res.body.userAddresses]);
    }
    return res;
  };

  return {
    userAddressess,
    userAddressesError,
    userAddressesLoading,
    userAddressesSubmitting,
    ListUserAddressess,
    ReadUserAddress,
  };
}

export const useUserAddressesContext = () => useContext(userAddressesContext);

export function ProvideUserAddresses({ children }) {
  const userAddresses = useProvideUserAddresses();
  return (
    <userAddressesContext.Provider value={userAddresses}>
      {children}
    </userAddressesContext.Provider>
  );
}
