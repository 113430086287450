import React from 'react';
import {
  Card,
  CardContent,
  Box,
  Typography,
  Avatar,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import { useHistory } from 'react-router-dom';
import SignInLayout from '../components/SignIn/Layout';
import ResetPasswordForm from '../components/SignUp/ResetPasswordForm';
import useBreakpoints from 'src/hooks/useBreakpoints';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.primary.main,
  },
}));

function AccountActivated(props) {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();

  const routeHistory = useHistory();
  const name = 'someone';

  return (
    <SignInLayout>
      <Card
        variant="outlined"
        style={{
          borderRadius: 16,
          border: smBelow ? 'none' : '',
          width: smBelow ? '100%' : '400px',
          maxWidth: '100%',
        }}
      >
        <CardContent>
          <Box display="flex" justifyContent="center" mt={4}>
            <Avatar className={classes.large}>
              <CheckIcon />
            </Avatar>
          </Box>
          <Box fontWeight="700" fontSize="30px" textAlign="center" mt={3}>
            Account activated
          </Box>
          <Box
            mb={4}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            // width={smBelow ? 'auto' : '275px'}
            width="100%"
            mt={3}
          >
            <Box maxWidth="300px" width={'100%'}>
              <Typography color="textSecondary" variant="body2">
                {`Thank you ${name}, your mobile number has been verified. Your account is now activated. Please use button below to sign in your account`}
              </Typography>
            </Box>
            <Box mt={4}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  routeHistory.push('/signin');
                }}
                style={{
                  color: '#FFFFFF',
                  textTransform: 'initial',
                  fontWeight: 700,
                }}
                size="large"
                fullWidth={true}
                disableElevation
              >
                Sign in to your account
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </SignInLayout>
  );
}

export default AccountActivated;
