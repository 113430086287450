import { Icon } from '@iconify/react';
import { Avatar, Badge, Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import MzButton from 'src/components/MzButton';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import companySvc from 'src/services/company';
import useStyles from './ProfileBox.styles';

const ColorBoxText = ({ title, description, color, DoA, DoC }) => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  return (
    <>
      <Box
        display="flex"
        flexDirection={smBelow ? 'column' : 'row'}
        justifyContent="space-between"
        paddingTop={3}
      >
        <Box
          display="flex"
          flexDirection={smBelow ? 'column' : 'row'}
          alignItems={smBelow ? 'left' : 'center'}
        >
          <Box
            className={classes.colorBox}
            style={{
              backgroundColor: color,
            }}
          >
            <Typography variant="body1">{title}</Typography>
          </Box>
          <Typography
            align="left"
            variant="body2"
            className={
              smBelow ? classes.columnDescription : classes.rowDescription
            }
          >
            {description}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={smBelow ? 'center' : 'flex-end'}
        >
          {DoA && <Typography variant="body1">{'DoA: ' + DoA}</Typography>}
          {DoC && (
            <Typography variant="body1" style={{ paddingLeft: 30 }}>
              {'DoC: ' + DoC}
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

const IconBadge = ({ showBadge }) => {
  if (showBadge) {
    return (
      <Badge color="error" overlap="circular" badgeContent=" ">
        <Icon icon="clarity:bell-solid" color="white" width="30" height="30" />
      </Badge>
    );
  }
  return (
    <Icon icon="clarity:bell-solid" color="white" width="30" height="30" />
  );
};

const ProfileBox = ({
  companyId,
  companyName,
  UEN,
  officer,
  shareholder,
  DoA,
  DoC,
  showBadge,
  styles,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { smBelow } = useBreakpoints();

  const handleViewCompany = () => {
    companySvc.setCompany({ companyName, companyId, uen: UEN });
    history.push(ROUTES.CORPSEC);
  };

  return (
    <Box style={styles} width="100%" paddingY={2}>
      <Container className={classes.topbox}>
        <Box display="flex" alignItems="center" color="#FFFFFF">
          <Box className={classes.avatarBorder}>
            <Avatar />
          </Box>
          <Typography
            variant="h5"
            className={classes.fontWeight}
            style={{ paddingLeft: 15, paddingRight: 15 }}
          >
            {companyName}
            {smBelow && <Typography variant="body1">UEN: {UEN}</Typography>}
          </Typography>
          {!smBelow && <Typography variant="body1">UEN: {UEN}</Typography>}
        </Box>
        <IconBadge showBadge={showBadge} />
      </Container>
      <Container className={classes.bottombox}>
        {officer && (
          <ColorBoxText
            title="Officer"
            description={officer}
            color="#99EDFF"
            DoA={DoA}
            DoC={DoC}
          />
        )}
        {shareholder && +shareholder >= 0 ? (
          <ColorBoxText
            title="Shareholder"
            description={shareholder}
            color="#BAFF99"
          />
        ) : null}
        <div style={{ flexGrow: 1 }} />
        <Box display="flex" classes={{ root: classes.viewButton }}>
          <MzButton
            title={'View company'}
            style={{
              borderRadius: 10,
              justifyContent: 'space-between',
              minWidth: 100,
            }}
            icon="grommet-icons:form-next"
            iconHeight={50}
            onClick={handleViewCompany}
          />
        </Box>
      </Container>
    </Box>
  );
};
export default ProfileBox;
