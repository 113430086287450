import React from 'react';
import { Select, MenuItem, InputBase, Typography } from '@material-ui/core';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { Field } from 'formik';
import FormFieldContainer from '../FormFieldContainer';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    // border: '1px solid #ced4da',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

const CustomSelectField = (props) => {
  const {
    label,
    name,
    options = [],
    placeholder,
    multiple = false,
    containerProps: defaultContainerProps = {},
    helperText,
    children,
    ...restProps
  } = props;

  const theme = useTheme();

  let fieldProps = {
    name: name,
    variant: 'outlined',
    fullWidth: true,
    size: 'small',
    input: <BootstrapInput />,
    multiple,
    defaultValue: multiple ? [] : undefined,
    displayEmpty: true,
    renderValue: (value) => {
      let result = (
        <span style={{ color: theme.palette.grey['500'] }}>
          {placeholder || ''}
        </span>
      );
      if (multiple) {
        if (value && value.length > 0) {
          let multipleResult = [];
          value.forEach((aValue) => {
            let foundValue = options.find((anOption) => {
              return anOption.value == aValue;
            });
            if (foundValue) {
              multipleResult.push(foundValue.label);
            }
          });
          result = multipleResult.join(', ');
        }
      } else {
        let foundValue = options.find((anOption) => {
          return anOption.value == value;
        });
        if (foundValue) {
          result = foundValue.label;
        }
      }
      return result;
    },
    ...restProps,
  };

  return (
    <Field name={name}>
      {({ field, meta }) => {
        let error = meta.touched && !!meta.error;
        return (
          <FormFieldContainer
            label={label}
            error={error}
            helperText={meta.touched && meta.error ? meta.error : ''}
            {...defaultContainerProps}
          >
            <Select {...field} error={error} {...fieldProps}>
              {options &&
                options.length > 0 &&
                options.map((anOption, index) => {
                  return (
                    <MenuItem key={anOption.value} value={anOption.value}>
                      {anOption.label}
                    </MenuItem>
                  );
                })}
              {children}
            </Select>
            {children}
          </FormFieldContainer>
        );
      }}
    </Field>
  );
};

export default CustomSelectField;
