import React, { useState, useContext, useEffect } from 'react';
import { Typography, Box, Button, Paper } from '@material-ui/core';
import { Formik, Form } from 'formik';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import * as Yup from 'yup';
import MzButton from 'src/components/MzButton';
import { useHistory } from 'react-router-dom';
import ROUTES from 'src/constants/routes';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import { useProvideRequest } from 'src/hooks/useRequests';
import useSnackbar from 'src/hooks/useSnackbar';
import { ConfigContext } from 'src/contexts';
import { useProvideProduct } from 'src/hooks/useProduct';
import FormDropdown from 'src/components/FormDropdown/FormDropdown.js';
import { useProvideData } from 'src/hooks/useData';
import { useProvideCompany } from 'src/hooks/useCompany';
import GetStartedLayout from 'src/components/Corpsec/Requests/GetStartedLayout';
import useBreakpoints from 'src/hooks/useBreakpoints';

const ChangeCompanyAddress = ({ toggleShowMenu }) => {
  // const classes = useStyles();
  const { smBelow } = useBreakpoints();
  const history = useHistory();
  const [step, setStep] = useState(1);

  const { countries, ListCountries } = useProvideData();
  const {
    CreateCompanyAddressApplication,
    requestError,
    requestSubmitting,
  } = useProvideRequest();
  const { showSuccessSnackbar, showErrorSnackbar } = useSnackbar();
  const {
    addCart,
    user,
    cartSubmitting,
    company,
    setConfirmationDialog,
  } = useContext(ConfigContext);
  const { ReadProduct, product, productError } = useProvideProduct();
  const {
    ReadCompanyAddresses,
    companyAddressess,
    companyError,
  } = useProvideCompany();

  const validationSchema = Yup.object({
    address_line_1: Yup.string().required('Address Line 1 is required'),
    postal_code: Yup.string().required('Postal Code is required'),
    country_id: Yup.string().required('Country is required'),
  });

  const getStarted = {
    one: {
      content: ['Change the Registered Address for your business'],
    },
    two: {
      content: ['New Registered Address for the business'],
    },
    three: {
      content: [
        'Create Request',
        'Meyzer360 Review',
        'Sign Resolution',
        'ACRA Filing',
      ],
    },
    four: {
      content: ['Basic Plan: SGD50', 'Unlimited Plan: Free'],
    },
    five: {
      content: [
        'Time to fill form: < 2 minutes.',
        'Average processing time: 3-5 days',
      ],
    },
  };

  useEffect(() => {
    // get latest product
    ReadProduct(14);
    ListCountries();
  }, []);

  useEffect(() => {
    if (company.company_id) {
      ReadCompanyAddresses(company.company_id);
    }
  }, [company.company_id]);

  useEffect(() => {
    if (productError) {
      showErrorSnackbar(`Error loading product. ${productError}`);
    }
  }, [productError]);

  useEffect(() => {
    if (companyError) {
      showErrorSnackbar(`Error loading address. ${companyError}`);
    }
  }, [companyError]);

  useEffect(() => {
    if (requestError) {
      showErrorSnackbar(`Error submitting application. ${requestError}`);
    }
  }, [requestError]);

  const getCompanyBusinessAddr = () => {
    let addr = [];
    addr = companyAddressess.find(
      (addr) =>
        addr.address_type_id === 2 || addr.address_type_name === 'Business',
    );

    return addr !== undefined
      ? [
          addr.floor_unit_no,
          addr.address_line_1,
          addr.address_line_2,
          addr.address_line_3,
          addr.postal_code,
          addr.country_name,
        ]
          .filter((addr) => addr)
          .join(', ')
      : 'N/A';
  };

  const formikOnSubmit = async (values, actions) => {
    values.company_id = company.company_id;
    values.company_application_status_id = 11; // in cart status
    let res = await CreateCompanyAddressApplication(values);

    if (res.status === 'success') {
      res = await addCart({
        price: product.price,
        product_id: product.id,
        quantity: 1,
        user_id: user.user_id,
        product_detail_id: res.body.applications.company_address_application_id,
        company_id: company.company_id,
      });
      if (res.status === 'success') {
        actions.resetForm();
        setConfirmationDialog({
          isVisible: true,
          title: 'Item added to cart',
          type: 'success',
          cancelButtonLabel: 'Back to Request',
          confirmButtonLabel: 'View your cart',
          confirmButtonAction: () => {
            history.push(ROUTES.PAYMENTCART);
            setConfirmationDialog({
              isVisible: false,
            });
          },
          cancelButtonAction: () => {
            history.push(ROUTES.CORPSEC_REQ);
            setConfirmationDialog({
              isVisible: false,
            });
          },
        });
      }
    }
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          postal_code: '',
          floor_unit_no: '',
          address_line_1: '',
          address_line_2: '',
          address_line_3: '',
          country_id: '',
        }}
        onSubmit={formikOnSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Box
              p={3}
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
            >
              <Typography variant="caption">
                Current Registered Address
              </Typography>
              <Typography variant="h6">{`${getCompanyBusinessAddr()}`}</Typography>
              <Box py={1} />
              <FormTextField
                id="postal_code"
                name="postal_code"
                labelText="Postal Code *"
                placeholder="Postal Code"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormTextField
                name="floor_unit_no"
                labelText="Floor/ Unit No"
                placeholder="Unit No"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormTextField
                name="address_line_1"
                labelText="Address Line 1 *"
                placeholder="Address Line 1"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormTextField
                name="address_line_2"
                labelText="Address Line 2"
                placeholder="Address Line 2"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormTextField
                name="address_line_3"
                labelText="Address Line 3"
                placeholder="Address Line 3"
                containerWidth="50%"
                shrink
                variant="outlined"
              />
              <FormDropdown
                autoCompleteStyle={{ width: '50%' }}
                name="country_id"
                onChange={(event, newValue) => {
                  props.setFieldValue('country_id', newValue?.value);
                }}
                optionList={countries}
                labelText="Country *"
                placeholder="Select One"
                fullWidth
                shrink
                variant="outlined"
              />
              <Box display="flex" pt={3} justifyContent="flex-start">
                <MzButton
                  fullWidth={smBelow ? true : false}
                  type="submit"
                  title={'SUBMIT'}
                  onClick={() => {}}
                  loading={requestSubmitting || cartSubmitting}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <GetStartedLayout
        toggleShowMenu={toggleShowMenu}
        step={step}
        setStep={setStep}
        getStarted={getStarted}
        header={
          <>
            <Typography variant="h2">
              {step === 1 && 'Change Company Address'}
              {step === 2 && 'Fill in Information for Change Company Address'}
            </Typography>
            <BreadcrumbsNav
              pathList={[
                { path: 'Requests', route: ROUTES.CORPSEC_REQ },
                {
                  path: 'Change Company Particulars',
                  route: ROUTES.CORPSEC_REQ_CHANGE_COMPANY_PARTICULARS,
                },
                {
                  path: 'Change Company Address',
                  color: 'primary',
                },
              ]}
            />
          </>
        }
      >
        {step === 2 && (
          <>
            <Box px={4} py={4} style={{ borderTop: '0.01rem solid #E4E4E4' }}>
              {renderForm()}
            </Box>
          </>
        )}
        <Box py={5} />
      </GetStartedLayout>

      {/* {step === 2 && (
        <>
          <Typography variant="h6" color="secondary">
            Change Company Address
          </Typography>
          <Box py={2} />
          {renderForm()}
        </>
      )}
      <Box py={5} /> */}
    </>
  );
};

export default ChangeCompanyAddress;
