import React from 'react';
import {
  Box,
  Typography,
  Button,
  IconButton,
  Divider,
  Grid,
  Breadcrumbs,
  Link,
} from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

import useBreakpoints from 'src/hooks/useBreakpoints';

const StyledBreadcrumbItem = withStyles((theme) => ({
  root: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
}))(Link);

const GetStartedLayout = ({
  children,
  toggleShowMenu,
  step,
  setStep,
  getStarted,
  header,
  breadcrumbItems = [],
  onBack,
}) => {
  const { smBelow } = useBreakpoints();
  const theme = useTheme();

  let activeBreadcrumb = null;
  if (breadcrumbItems && breadcrumbItems.length > 0) {
    activeBreadcrumb = breadcrumbItems[breadcrumbItems.length - 1];
  }

  return (
    <Box>
      <Box
        // border="1px solid #00ff00"
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexWrap="no-wrap"
          // alignItems="center"
          px={smBelow ? 2 : 3}
          py={smBelow ? 2 : 3}
          flex={1}
          // minHeight={smBelow ? 'auto' : '115px'}
          minHeight={'115px'}
        >
          <Box
            flex={1}
            display="flex"
            flexDirection={smBelow ? 'column' : 'row'}
          >
            <Box mr={2}>
              <IconButton
                size="medium"
                onClick={() => {
                  if (toggleShowMenu) toggleShowMenu();
                }}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                <MenuIcon fontSize="medium" />
              </IconButton>
            </Box>
            {activeBreadcrumb && (
              <Box display="flex" flexWrap="no-wrap">
                <Box flex={1}>
                  <Typography variant="h2">
                    {activeBreadcrumb.document_type_name ||
                      activeBreadcrumb.name}
                  </Typography>
                  <Box mt={1}>
                    <Breadcrumbs
                      separator={
                        <NavigateNextIcon
                          fontSize="small"
                          style={{ color: 'black' }}
                        />
                      }
                    >
                      {breadcrumbItems &&
                        breadcrumbItems.length > 0 &&
                        breadcrumbItems.map((aBreadcrumbItem, index) => {
                          const title =
                            aBreadcrumbItem.document_type_name ||
                            aBreadcrumbItem.name;
                          let isActive = breadcrumbItems.length == index + 1;
                          if (isActive) {
                            return (
                              <Typography
                                key={index}
                                color="primary"
                                style={{ fontWeight: 'bold' }}
                                variant="h6"
                              >
                                {title}
                              </Typography>
                            );
                          }
                          return (
                            <StyledBreadcrumbItem
                              key={index}
                              color="default"
                              onClick={() => {
                                if (aBreadcrumbItem.onClick) {
                                  aBreadcrumbItem.onClick();
                                }
                              }}
                              variant="h6"
                            >
                              {title}
                            </StyledBreadcrumbItem>
                          );
                        })}
                    </Breadcrumbs>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
          {breadcrumbItems && breadcrumbItems.length > 1 && (
            <Box display="flex" alignItems="flex-start">
              <Button
                startIcon={<ArrowBackOutlinedIcon />}
                onClick={() => {
                  if (onBack) {
                    onBack();
                  }
                }}
                style={{ height: '48px' }}
              >
                Back
              </Button>
            </Box>
          )}
        </Box>
        {header && (
          <>
            {/* {!smBelow && <Divider />} */}

            <Box
              px={smBelow ? 2 : 3}
              // py={smBelow ? 1 : 2}
              pb={2}
              display="flex"
              // justifyContent="flex-end"
            >
              {header}
            </Box>
          </>
        )}
        {children}
      </Box>
    </Box>
  );

  return (
    <Box>
      <Box
        // border="1px solid #00ff00"
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexWrap="no-wrap"
          alignItems="center"
          px={smBelow ? 2 : 3}
          py={smBelow ? 2 : 3}
          flex={1}
          // minHeight={smBelow ? 'auto' : '115px'}
          minHeight={'115px'}
        >
          <Box mr={1}>
            <IconButton
              size="medium"
              onClick={() => {
                if (toggleShowMenu) toggleShowMenu();
              }}
              style={{ paddingLeft: 0 }}
            >
              <MenuIcon fontSize="medium" />
            </IconButton>
          </Box>
          <Box flex={1} display="flex" flexDirection="column">
            {activeBreadcrumb && (
              <Box display="flex" flexWrap="no-wrap">
                <Box flex={1}>
                  <Typography variant="h2">
                    {activeBreadcrumb.document_type_name ||
                      activeBreadcrumb.name}
                  </Typography>
                  <Box mt={1}>
                    <Breadcrumbs
                      separator={
                        <NavigateNextIcon
                          fontSize="small"
                          style={{ color: 'black' }}
                        />
                      }
                    >
                      {breadcrumbItems &&
                        breadcrumbItems.length > 0 &&
                        breadcrumbItems.map((aBreadcrumbItem, index) => {
                          const title =
                            aBreadcrumbItem.document_type_name ||
                            aBreadcrumbItem.name;
                          let isActive = breadcrumbItems.length == index + 1;
                          if (isActive) {
                            return (
                              <Typography
                                key={index}
                                color="primary"
                                style={{ fontWeight: 'bold' }}
                                variant="h6"
                              >
                                {title}
                              </Typography>
                            );
                          }
                          return (
                            <StyledBreadcrumbItem
                              key={index}
                              color="default"
                              onClick={() => {
                                if (aBreadcrumbItem.onClick) {
                                  aBreadcrumbItem.onClick();
                                }
                              }}
                              variant="h6"
                            >
                              {title}
                            </StyledBreadcrumbItem>
                          );
                        })}
                    </Breadcrumbs>
                  </Box>
                </Box>
                {breadcrumbItems && breadcrumbItems.length > 1 && (
                  <Box display="flex" flexWrap="no-wrap" alignItems="center">
                    <Button
                      startIcon={<ArrowBackOutlinedIcon />}
                      onClick={() => {
                        if (onBack) {
                          onBack();
                        }
                      }}
                    >
                      Back
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </Box>
        {header && (
          <>
            {/* {!smBelow && <Divider />} */}

            <Box
              px={smBelow ? 2 : 3}
              // py={smBelow ? 1 : 2}
              pb={2}
              display="flex"
              // justifyContent="flex-end"
            >
              {header}
            </Box>
          </>
        )}
        {children}
      </Box>
    </Box>
  );
};

export default GetStartedLayout;
