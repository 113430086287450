import React from 'react';

import SignInLayout from '../components/SignIn/Layout';
import OTPVerificationForm from '../components/SignUp/OTPVerificationForm';

function OTPVerification(props) {
  return (
    <SignInLayout>
      <OTPVerificationForm />
    </SignInLayout>
  );
}

export default OTPVerification;
