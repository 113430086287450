import React from 'react';
import { Typography, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { EnhancedIcon } from 'src/components/Enhanced';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const DottedLabel = ({ label, value }) => {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center">
      <EnhancedIcon
        icon={FiberManualRecordIcon}
        iconStyle={{
          fill: theme.palette.primary.main,
          fontSize: 15,
        }}
      />
      <Box pr={1} />
      {label && (
        <Typography variant="h5" style={{ color: '#808191' }}>
          {label}&nbsp;
        </Typography>
      )}
      {value && (
        <Typography variant="h5" style={{ color: '#000000' }}>
          {value}
        </Typography>
      )}
    </Box>
  );
};

export default DottedLabel;
