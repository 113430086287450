import React, { useEffect, useState, useContext } from 'react';
import { Paper, Box, Divider, Grid } from '@material-ui/core';
import {
  useLocation,
  useParams,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';
import useBreakpoints from 'src/hooks/useBreakpoints';
import ReqLayout from 'src/components/Corpsec/Documents/Layout';
import ReqMenu from 'src/components/Corpsec/Documents/Menu';
import {
  CorpsecDashboard,
  CorpsecDocuments,
  CorpsecNameBox,
  CorpsecProfile,
  CorpsecRequests,
  CorpsecRequestsApptAdmin,
  CorpsecRequestsApptDirector,
  CorpsecRequestsChangeCompanyParticulars,
  CorpsecRequestsChangeCompanyName,
  CorpsecRequestsChangeCompanyFinancialYearEnd,
  CorpsecRequestsChangeCompanyAddress,
  CorpsecRequestsChangeBusinessActivities,
  CorpsecRequestsChangeShareInformation,
  CorpsecRequestsAllotmentShares,
  CorpsecRequestsTransferShares,
  CorpsecRequestsChangeOtherResolutions,
  CorpsecRequestsDeclareInterimDividend,
  CorpsecRequestsOpenBankAccount,
  CorpsecRequestsCloseBankAccount,
  CorpsecRequestsOtherRequest,
  CorpsecRequestsChangeDirectorParticulars,
  CorpsecRequestsChangeOfficers,
  CorpsecRequestsChangePersonalParticulars,
  CorpsecRequestsChangeShareholderParticulars,
  CorpsecRequestsDirectorList,
  CorpsecRequestsResgnAdmin,
  CorpsecRequestsResgnAdminForm,
  CorpsecRequestsResgnDirector,
  CorpsecRequestsResgnDirectorForm,
  CorpsecRequestsShareholderList,
  CorpsecSidebar,
  CorpsecTaskList,
  CorpsecBoardRoom,
  CorpsecCapTable,
  CorpsecDocumentFiles,
} from 'src/components/Corpsec';
import { ConfigContext } from 'src/contexts';

import ROUTES from 'src/constants/routes';

const Document = () => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(true);
  const params = useParams();
  const { smBelow } = useBreakpoints();
  const [selectedFolder, setSelectedFolder] = useState(null);
  const { company, companyLoading } = useContext(ConfigContext);

  useEffect(() => {
    setSelectedFolder(null);
  }, [company]);

  const toggleShowMenu = () => {
    setShowMenu((prevState) => {
      return !prevState;
    });
  };

  useEffect(() => {
    if (location.pathname === ROUTES.CORPSEC_DOC) {
      setShowMenu(true);
    }
  }, [location]);

  return (
    <ReqLayout>
      <Paper
        elevation={1}
        style={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'row',
        }}
      >
        {/* Desktop View */}
        {showMenu && (
          <Box flex={2}>
            <ReqMenu
              toggleShowMenu={toggleShowMenu}
              onClickMenuItem={(value) => setSelectedFolder({ ...value })}
              mainFolder={selectedFolder}
            />
          </Box>
        )}
        {/* Mobile View, either show menu or show content */}
        {smBelow && !showMenu && (
          <>
            {/* <Box flex={8} maxWidth="100%">
              <CorpsecDocumentFiles
                toggleShowMenu={toggleShowMenu}
                mainFolder={selectedFolder}
              />
            </Box> */}
          </>
        )}

        {/* Desktop View */}
        {!smBelow && (
          <>
            {/* <Divider orientation="vertical" flexItem />
            <Box flex={8} maxWidth="100%">
              <CorpsecDocumentFiles
                toggleShowMenu={toggleShowMenu}
                mainFolder={selectedFolder}
              />
            </Box> */}
          </>
        )}

        {!smBelow && <Divider orientation="vertical" flexItem />}
        {(smBelow && !showMenu) || !smBelow ? (
          <Box flex={6} maxWidth="100%">
            <CorpsecDocumentFiles
              toggleShowMenu={toggleShowMenu}
              mainFolder={selectedFolder}
            />
          </Box>
        ) : null}
      </Paper>
    </ReqLayout>
  );
};

export default Document;
