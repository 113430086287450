import React, { useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './TaskList.styles';
import { Icon } from '@iconify/react';
import useBreakpoints from 'src/hooks/useBreakpoints';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useProvideTasks } from 'src/hooks/useTasks';

const settings = {
  infinite: false,
  arrows: false,
  slidesToShow: 3,
  speed: 500,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2.1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TaskList = () => {
  const classes = useStyles();
  const { mdBelow } = useBreakpoints();
  const { ListTasks, tasks } = useProvideTasks();

  var WaitingList = 0;
  var WorkingList = 0;
  var CompletedList = 0;

  if (tasks.length > 0) {
    WaitingList = tasks.filter((task) => task.task_status_id === 1);
    WorkingList = tasks.filter((task) => task.task_status_id === 2);
    CompletedList = tasks.filter((task) => task.task_status_id === 3);
  }

  useEffect(() => {
    ListTasks();
  }, []);

  const renderCard = (cards, emptyLabel, emptyLabel2) => {
    return (
      <Box py={1}>
        {cards.length > 0
          ? cards.map((task, index) => (
              <Box key={`${task.task_name}${index}`} py={1}>
                <Box
                  style={{
                    padding: 30,
                    borderRadius: 5,
                    boxShadow: '0 0 10px #ccc',
                  }}
                >
                  <Typography
                    variant="body1"
                    align="left"
                    className={classes.fontWeight}
                  >
                    {task.task_name}
                  </Typography>
                  <Typography variant="body1" align="left">
                    {task.remarks}
                  </Typography>
                  <Typography variant="body1" align="right">
                    2 days ago
                  </Typography>
                </Box>
              </Box>
            ))
          : null}

        {cards.length === 0 && (
          <>
            <Box p={4} />
            <Typography variant="body1">{emptyLabel}</Typography>
            {!!emptyLabel2 && (
              <Typography variant="body1">{emptyLabel2}</Typography>
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Slider {...settings}>
      <Box px={1}>
        <Box
          style={{
            backgroundColor: '#000328',
            color: '#FFFFFF',
            padding: 30,
            borderRadius: 5,
            display: 'flex',
            flexDirection: mdBelow ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon icon="bx:bxs-time-five" color="white" width="20" height="20" />
          <Box p={1} />
          <Typography variant="body1">{'AWAITING YOUR RESPONSE'}</Typography>
        </Box>
        {renderCard(WaitingList, "You're all caught up!")}
      </Box>
      <Box px={1}>
        <Box
          style={{
            backgroundColor: '#000328',
            color: '#FFFFFF',
            padding: 30,
            borderRadius: 5,
            display: 'flex',
            flexDirection: mdBelow ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon icon="ic:baseline-work" color="white" width="20" height="20" />
          <Box p={1} />
          <Typography variant="body1">{"WE'RE WORKING ON IT"}</Typography>
        </Box>
        {renderCard(WorkingList, "You're all caught up!")}
      </Box>
      <Box px={1}>
        <Box
          style={{
            backgroundColor: '#000328',
            color: '#FFFFFF',
            padding: 30,
            borderRadius: 5,
            display: 'flex',
            flexDirection: mdBelow ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Icon
            icon="teenyicons:tick-circle-solid"
            color="white"
            width="20"
            height="20"
          />
          <Box p={1} />
          <Typography variant="body1">{'DONE'}</Typography>
        </Box>
        {renderCard(
          CompletedList,
          "You've yet to complete a Task,",
          "but I'm sure you will soon!",
        )}
      </Box>
    </Slider>
  );
};

export default TaskList;
