import React from 'react';
import { Typography, Paper, Grid } from '@material-ui/core';
import useBreakpoints from 'src/hooks/useBreakpoints';

const RowTable = ({ label, latest, balance }) => {
  const { smBelow } = useBreakpoints();
  return (
    <Paper
      variant="outlined"
      square
      style={{
        width: '100%',
        padding: '10px',
      }}
    >
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={6}>
          <Typography variant="body1" align="left">
            {label}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" align={smBelow ? 'center' : 'left'}>
            {latest}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" align={smBelow ? 'center' : 'left'}>
            {balance}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default RowTable;
