import React from 'react';
import { Button, Typography, Box } from '@material-ui/core';
import { Icon } from '@iconify/react';

const DetailLabel = ({
  label,
  value,
  fileType = false,
  withoutTopBox = false,
  labelColor = 'rgb(99, 93, 93)',
  valueColor = 'rgb(99, 93, 93)',
}) => {
  return (
    <Box>
      {!!value && (
        <Box display="flex" alignItems="flex-start" flexDirection="column">
          {!withoutTopBox && <Box p={1} />}
          <Typography variant="caption" style={{ color: labelColor }}>
            {label}
          </Typography>
          {!fileType && (
            <Typography
              variant="subtitle1"
              align="left"
              style={{ color: valueColor }}
            >
              {value}
            </Typography>
          )}

          {fileType && (
            <Button
              variant="outlined"
              size="small"
              style={{ display: 'flex' }}
              onClick={() => {
                window.open(value, '_blank');
              }}
            >
              <Icon
                icon="icon-park-outline:preview-open"
                width="25"
                height="25"
              />
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DetailLabel;
