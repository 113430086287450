import React, { useContext } from 'react';
import { Paper } from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import Avatar from '@material-ui/core/Avatar';
import { Box, Typography } from '@material-ui/core';
import useBreakpoints from 'src/hooks/useBreakpoints';
import useStyles from './InfoBox.styles';
import { ConfigContext } from 'src/contexts';

function truncate(str, num) {
  if (str) return str.length > 10 ? str.substring(0, num) + '...' : str;
}
const InfoBox = ({ vertical, center, width }) => {
  const { mdBelow } = useBreakpoints();
  const classes = useStyles();
  const { company } = useContext(ConfigContext);
  let centerMobileView = center && mdBelow;
  let noCompany = Object.keys(company).length === 0;

  return (
    <Box
      display="flex"
      flexDirection={vertical ? 'column' : 'row'}
      width={width ? width : '100%'}
    >
      <Paper
        className={classes.paper}
        style={{
          height: centerMobileView ? 183 : 148,
          flexDirection: centerMobileView ? 'column' : 'row',
          justifyContent: centerMobileView ? 'center' : 'flex-start',
        }}
      >
        <Box pr={3} />
        <Avatar className={classes.avatar1}>
          <DescriptionOutlinedIcon fontSize="large" />
        </Avatar>
        <Box
          pl={centerMobileView ? 0 : 2}
          display="flex"
          flexDirection="column"
        >
          <Box p={centerMobileView ? 1 : 0}>
            <Typography
              noWrap
              variant="h3"
              numberOfLines={1}
              align={centerMobileView ? 'center' : 'left'}
              style={{ fontWeight: 600 }}
            >
              {!noCompany ? truncate(company.company_name, 13) : 'Main Account'}
            </Typography>
          </Box>
          <Typography
            variant="h5"
            align={centerMobileView ? 'center' : 'left'}
            style={{ color: '#8F96AD' }}
          >
            UEN: {!noCompany ? company.company_registration_no : 0}
          </Typography>
        </Box>
      </Paper>
      <Box p={1} />
      <Paper
        className={classes.paper}
        style={{
          height: centerMobileView ? 183 : 148,
          flexDirection: centerMobileView ? 'column' : 'row',
          justifyContent: centerMobileView ? 'center' : 'flex-start',
        }}
      >
        <Box pr={3} />
        <Avatar className={classes.avatar2}>
          <AttachMoneyRoundedIcon fontSize="large" />
        </Avatar>
        <Box
          pl={centerMobileView ? 0 : 2}
          display="flex"
          flexDirection="column"
        >
          <Box p={centerMobileView ? 1 : 0}>
            <Typography
              variant="h3"
              align={centerMobileView ? 'center' : 'left'}
              style={{ fontWeight: 600 }}
            >
              $ {!noCompany ? '87,256' : '0'}
            </Typography>
          </Box>
          <Typography
            variant="h5"
            align={centerMobileView ? 'center' : 'left'}
            style={{ color: '#8F96AD' }}
          >
            {'Share Capital'}
          </Typography>
        </Box>
      </Paper>
      <Box p={1} />
      <Paper
        className={classes.paper}
        style={{
          height: centerMobileView ? 183 : 148,
          flexDirection: centerMobileView ? 'column' : 'row',
          justifyContent: centerMobileView ? 'center' : 'flex-start',
        }}
      >
        <Box pr={3} />
        <Avatar className={classes.avatar3}>
          <PersonOutlineOutlinedIcon fontSize="large" />
        </Avatar>
        <Box
          pl={centerMobileView ? 0 : 2}
          display="flex"
          flexDirection="column"
        >
          <Box p={centerMobileView ? 1 : 0}>
            <Typography
              variant="h3"
              align={centerMobileView ? 'center' : 'left'}
              style={{ fontWeight: 600 }}
            >
              {!noCompany ? '156' : '0'}
            </Typography>
          </Box>
          <Typography
            variant="h5"
            align={centerMobileView ? 'center' : 'left'}
            style={{ color: '#8F96AD' }}
          >
            {'Total Employees'}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default InfoBox;
