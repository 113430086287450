import { makeStyles } from '@material-ui/core/styles';
import { AllServices } from 'src/constants/list';

export default makeStyles((theme) => ({
  appbar: {
    backgroundImage: `url(${AllServices[0].img})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  toolbar: {
    minHeight: 200,
    alignSelf: 'baseline',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
}));
