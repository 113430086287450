import React, { useState, useContext } from 'react';
import './message.css';
import {
  Typography,
  Box,
  Container,
  Button,
  ButtonBase,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import ROUTES from '../constants/routes';
import FormTextField from 'src/components/FormTextField/FormTextField.js';
import useBreakpoints from '../hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@iconify/react';
import { useHistory } from 'react-router-dom';
import { AllServices } from 'src/constants/list';
import { CorpsecNameBox } from 'src/components/Corpsec';
import AuthLayout from 'src/components/AuthLayout';
import DetailLabel from 'src/components/DetailLabel';
import { useEffect } from 'react';
import { useProvideWebDev } from 'src/hooks/useWebDev';
import { ConfigContext } from 'src/contexts';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
  },
  gradientButton: {
    minWidth: '230px',
    minHeight: '50px',
    border: '2px solid transparent',
    borderRadius: '30px',
    backgroundImage:
      'linear-gradient(white, white), linear-gradient(to left, #760000 1.54%, #FFB800 101.02%)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
  },
  fontWeight: {
    fontWeight: 600,
  },

  appbar: {
    backgroundImage: `url(${AllServices[0].img})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  toolbar: {
    minHeight: 200,
    alignSelf: 'baseline',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      // mobile view
      flexDirection: 'column',
      flexGrow: 0,
    },
  },
  paymentButton: {
    width: 130,
    textTransform: 'initial',
  },
  paymentActiveButton: {
    width: 130,
    textTransform: 'initial',
    backgroundColor: '#C5C5C5',
  },
}));

const WebDev = () => {
  const classes = useStyles();
  const history = useHistory();
  const { xsBelow } = useBreakpoints();
  const [step, setStep] = useState(1);
  const [order, selectOrder] = useState();
  const { ListWebDev, webdevs } = useProvideWebDev();
  const { addWebInfo } = useContext(ConfigContext);

  useEffect(() => {
    ListWebDev();
  }, []);

  const cartList = [
    {
      price: 50,
      type: 'Appointment of Director',
      status: 'completed',
      details: {
        residency: 'Local',
        name: 'Edison',
        id: '1234',
        mobile: '0123456789',
        email: 'abc@abcd.com',
        date: '12/12/2012',
        proofId: 'https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK',
      },
    },
    {
      price: 50,
      type: 'Appointment of Director',
      status: 'paid',
      details: {
        residency: 'Foreigner',
        name: 'Edison',
        id: '1234',
        mobile: '0123456789',
        email: 'abc@abcd.com',
        date: '12/12/2012',
        passport: 'https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK',
        proofResidency: 'https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK',
      },
    },
    {
      price: 50,
      type: 'Appointment of Director',
      status: 'processing',
      details: {
        residency: 'Local',
        name: 'Edison',
        id: '1234',
        mobile: '0123456789',
        email: 'abc@abcd.com',
        date: '12/12/2012',
        proofId: 'https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK',
      },
    },
    {
      price: 50,
      type: 'Appointment of Director',
      status: 'failed',
      details: {
        residency: 'Foreigner',
        name: 'Edison',
        id: '1234',
        mobile: '0123456789',
        email: 'abc@abcd.com',
        date: '12/12/2012',
        passport: 'https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK',
        proofResidency: 'https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK',
      },
    },
  ];

  const TypographyStatus = ({ status }) => {
    let statusColor, textLabel;
    switch (status) {
      case 'completed':
        statusColor = '#24FF00';
        textLabel = 'Completed';
        break;
      case 'paid':
        statusColor = '#041DFF';
        textLabel = 'Paid';
        break;
      case 'processing':
        statusColor = '#FF7F09';
        textLabel = 'Processing';
        break;
      case 'failed':
        statusColor = '#FF4D00';
        textLabel = 'Failed';
        break;
    }
    return (
      <Typography
        variant="body1"
        align="right"
        style={{ fontWeight: 600, color: statusColor }}
      >
        {textLabel}
      </Typography>
    );
  };

  const renderStep1 = () => {
    return (
      <Box
        style={{
          width: '100%',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h6"
            align="left"
            color="secondary"
            style={{
              fontWeight: 600,
            }}
          >
            Contents
          </Typography>

          {webdevs.length !== 0 &&
            webdevs.map((item, index) => (
              <>
                <Box pt={3} />
                <ButtonBase key={`${item.type}${index}`}>
                  <Box
                    style={{
                      padding: 30,
                      borderRadius: 25,
                      boxShadow: '0 0 10px #ccc',
                      width: '100%',
                    }}
                    onClick={() => {
                      setStep(2);
                      selectOrder(item);
                    }}
                  >
                    <Box
                      display={xsBelow ? 'initial' : 'flex'}
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="body1"
                        align="left"
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        {item.plan}
                      </Typography>
                      {/* <Typography
                        variant="body1"
                        align={'right'}
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        S${item.price}
                      </Typography> */}
                    </Box>

                    <Box
                      display={xsBelow ? 'initial' : 'flex'}
                      justifyContent="space-between"
                    >
                      <Box display={xsBelow ? 'initial' : 'flex'}>
                        <Typography variant="body1" align="left">
                          {item.webdev_id}
                        </Typography>
                        <Box pr={4} />
                        <Typography variant="body1" align="left">
                          Date: 02/09/2021
                        </Typography>
                      </Box>

                      <TypographyStatus status={item.status} />
                    </Box>
                  </Box>
                </ButtonBase>
              </>
            ))}
        </Box>
        <Box pb={5} />
      </Box>
    );
  };

  const renderStep2 = () => {
    return (
      <>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'rgba(222, 173, 0, 1)',
          }}
          onClick={() => {
            setStep(1);
          }}
        >
          <Icon icon="eva:arrow-ios-back-fill" width="20" height="20" />
          <Typography
            variant="body1"
            align="left"
            style={{
              fontWeight: 600,
            }}
          >
            Back
          </Typography>
        </Box>
        <Box pt={3} />

        <Typography
          variant="h6"
          align="left"
          color="secondary"
          style={{
            fontWeight: 600,
          }}
        >
          Content Details
        </Typography>
        <Box pt={3} />
        <Box
          style={{
            padding: 30,
            borderRadius: 25,
            boxShadow: '0 0 10px #ccc',
            width: '100%',
          }}
          onClick={() => setStep(2)}
        >
          <Box
            display={xsBelow ? 'initial' : 'flex'}
            justifyContent="space-between"
          >
            <Typography
              variant="body1"
              align="left"
              style={{
                fontWeight: 600,
              }}
            >
              {order.plan}
            </Typography>
            {/* <Typography
              variant="body1"
              align={'right'}
              style={{
                fontWeight: 600,
              }}
            >
              S${order.price}
            </Typography> */}
          </Box>

          <Box
            display={xsBelow ? 'initial' : 'flex'}
            justifyContent="space-between"
          >
            <Box display={xsBelow ? 'initial' : 'flex'}>
              <Typography variant="body1" align="left">
                {order.webdev_id}
              </Typography>
              <Box pr={4} />
              <Typography variant="body1" align="left">
                Date: 02/09/2021
              </Typography>
            </Box>

            <TypographyStatus status={order.status} />
          </Box>

          <Box pt={2} />
          <DetailLabel
            label="Template Content"
            value={order.template_content}
          />
          <DetailLabel label="Theme" value={order.theme} />
          <DetailLabel
            label="Category Name"
            value={order.webdev_category_name}
          />
        </Box>
        <Box p={1} />
        <Box display="flex" justifyContent="flex-end">
          <MzButton
            title={'Update'}
            onClick={() => {
              addWebInfo({ ...order });
              history.push(ROUTES.CREATEWEBSITE(order.webdev_id));
            }}
          />
        </Box>
        <Box pb={8} />
      </>
    );
  };

  return (
    <AuthLayout
      contentStyle={{
        minWidth: 0,
        minHeight: 0,
      }}
    >
      <Box display="flex" height="calc(100% - 64px)" flexDirection="column">
        <AppBar
          position="static"
          classes={{
            root: classes.appbar,
          }}
        >
          <Toolbar className={classes.toolbar}>
            <Typography className={classes.title} variant="h5" noWrap>
              WEB DEV
            </Typography>
          </Toolbar>
        </AppBar>

        <Box
          display="flex"
          flexDirection="row"
          flexGrow={1}
          className={classes.content}
        >
          <Container>
            {/* <CorpsecNameBox /> */}
            <Box pt={3} display="flex" flexDirection="column">
              {step === 1 && renderStep1()}
              {step === 2 && renderStep2()}
            </Box>
          </Container>
        </Box>
      </Box>
    </AuthLayout>
  );
};

export default WebDev;
