import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';
import './App.css';
import AppTheme from 'src/styles/AppTheme';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import { ConfigContextProvider } from 'src/contexts';

function App() {
  return (
    <div className="App">
      <Router>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          preventDuplicate
        >
          <ConfigContextProvider>
            <AppTheme>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Routes />
              </MuiPickersUtilsProvider>
            </AppTheme>
          </ConfigContextProvider>
        </SnackbarProvider>
      </Router>
    </div>
  );
}

export default App;
