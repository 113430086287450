import React from 'react';
import {
  makeStyles,
  AppBar,
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HeroContent from '../components/HeroContent/HeroContent';
import bgImg from '../assets/homepage/homebg.png';
import servicesBgImg from '../assets/homepage/services.png';
import onemlogo from '../assets/m360.png';
import Features from '../components/Features';
import Features2 from '../components/Features2';
import { useHistory } from 'react-router-dom';
import ROUTES from '../constants/routes';
import useBreakpoints from '../hooks/useBreakpoints';
import digitalisingImg from '../assets/homepage/digitalising.png';
import digitalImg from '../assets/homepage/digital.png';
import marketplaceImg from '../assets/homepage/marketplace.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  menu: {
    backgroundColor: '#BE9629',
    color: '#FFFFFF',
  },
}));

function DesktopMenu() {
  const history = useHistory();

  return (
    <>
      <ButtonBase
        onClick={(e) => {
          e.preventDefault();
          history.push(ROUTES.SIGNINPAGE);
        }}
      >
        <Typography
          variant="body1"
          style={{
            fontWeight: 600,
            paddingRight: 20,
          }}
        >
          Login |
        </Typography>
      </ButtonBase>
      <Button
        color="primary"
        variant="contained"
        style={{
          color: '#FFFFFF',
          borderRadius: '6px',
          backgroundColor: '#BE9629',
          textTransform: 'initial',
          fontWeight: 600,
        }}
        onClick={(e) => {
          e.preventDefault();
          history.push(ROUTES.SIGNUPPAGE);
        }}
      >
        Open Account
      </Button>
    </>
  );
}

function MobileMenu(anchorEl, setAnchorEl, classes) {
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        color="inherit"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={{ paper: classes.menu }}
      >
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            history.push(ROUTES.SIGNINPAGE);
          }}
        >
          Login
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            history.push(ROUTES.SIGNUPPAGE);
          }}
        >
          Open Account
        </MenuItem>
      </Menu>
    </>
  );
}

function Homepage() {
  const classes = useStyles();
  const history = useHistory();
  const { xsBelow } = useBreakpoints();
  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{ backgroundColor: 'black' }}>
        <Toolbar>
          <a href="/">
            <img src={onemlogo} height={xsBelow ? 20 : 40} />
          </a>
          <Box className={classes.title} />
          {!xsBelow && DesktopMenu()}
          {xsBelow && MobileMenu(anchorEl, setAnchorEl, classes)}
        </Toolbar>
      </AppBar>
      <>
        <HeroContent
          bgImg={bgImg}
          overlay
          centeredTitle
          textTitle={
            'To thrive in the new digital era, every organisation must reimagine how their businesses operate, embracing innovation and digital-first models.'
          }
          textDesc={'Let Meyzer360 jumpstart your business in the digital era.'}
        />
        <Features
          mainTitle="Unified Digital Service Enterprise"
          styles={{
            paddingTop: 30,
            paddingBottom: 50,
            backgroundColor: '#E5E5E5',
          }}
          itemData={[
            {
              img: digitalisingImg,
              title: 'Digitalising Business Operations',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae amet vulputate in tempus in donec faucibus. ',
            },
            {
              img: digitalImg,
              title: 'Digital Tools to Service Clients',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae amet vulputate in tempus in donec faucibus. ',
            },
            {
              img: marketplaceImg,
              title: 'Digital Marketplace',
              description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vitae amet vulputate in tempus in donec faucibus. ',
            },
          ]}
        />
        <HeroContent bgImg={servicesBgImg} containBg />
        <Features2
          textTitle={
            'To thrive in the new digital era, every organisation must reimagine how their businesses operate, embracing innovation and digital-first models.'
          }
          textDesc={'Let Meyzer360 jumpstart your business in the digital era.'}
          styles={{ paddingTop: 80, paddingBottom: 80 }}
        />
      </>
    </div>
  );
}

export default Homepage;
