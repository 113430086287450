import { Button, CircularProgress } from '@material-ui/core';
import React from 'react';
import useStyles from './MzButton.styles';
import { useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useTheme } from '@material-ui/core/styles';

function MzButton({
  title,
  page,
  onClick,
  size,
  icon,
  iconHeight,
  style = {},
  loading = false,
  ...props
}) {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles();

  // set default style
  let buttonStyle = {
    color: '#FFFFFF',
    // borderRadius: '50px',
    textTransform: 'initial',
    // fontWeight: 600,
  };

  if (!size) {
    buttonStyle.minHeight = 60;
    buttonStyle.minWidth = 180;
  }

  // allow override
  buttonStyle = { ...buttonStyle, ...style };

  return (
    <Button
      color="primary"
      variant="contained"
      style={buttonStyle}
      className={props.disabled ? null : classes.button}
      onClick={(e) => {
        if (onClick) return onClick(e);
        e.preventDefault();
        history.push(page);
      }}
      size={size}
      {...props}
    >
      {loading ? (
        <CircularProgress
          style={{
            color: theme.palette.common.white,
          }}
          size={theme.spacing(3)}
        />
      ) : (
        <>
          {title}
          {icon && <Icon icon={icon} color="white" height={iconHeight} />}
        </>
      )}
    </Button>
  );
}
export default MzButton;
