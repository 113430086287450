import React from 'react';
import {
  Box,
  CardActions,
  CardContent,
  CardActionArea,
  Card,
  CardMedia,
  makeStyles,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
// import FastfoodIcon from '@material-ui/icons/Fastfood';
// import LocationCityIcon from '@material-ui/icons/LocationCity';
import useBreakpoints from 'src/hooks/useBreakpoints';

const useStyles = makeStyles((theme) => ({
  whiteBox: {
    padding: 50,
    borderRadius: 25,
    boxShadow: '0 0 10px #ccc',
    backgroundColor: '#FFFFFF',
  },
  title: {
    fontWeight: 600,
    padding: theme.spacing(3),
  },
}));

const Features = ({ styles, mainTitle, itemData }) => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();

  return (
    <Box style={styles}>
      <Container>
        <Typography
          variant={smBelow ? 'h4' : 'h3'}
          align="center"
          style={{ paddingBottom: 30 }}
        >
          {mainTitle}
        </Typography>

        <Box className={classes.whiteBox}>
          <Grid
            container
            style={{
              justifyContent: 'center',
            }}
          >
            {itemData.map((item, index) => (
              <Grid
                key={item.title}
                item
                sm={smBelow ? 8 : 4}
                style={{
                  padding: 20,
                }}
              >
                <Box
                  style={{
                    height: smBelow ? 'auto' : 260,
                  }}
                >
                  <img src={item.img} height={128} />
                  <Typography variant="h5" className={classes.title}>
                    {item.title}
                  </Typography>
                </Box>
                <Typography variant="body1">{item.description}</Typography>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Features;
