import React, { useState, useEffect } from 'react';
import {
  InputAdornment,
  Typography,
  Box,
  OutlinedInput,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Link,
  IconButton,
} from '@material-ui/core';
// import useStyles from './Requests.styles';
import SearchIcon from '@material-ui/icons/Search';
import MzButton from 'src/components/MzButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { Icon } from '@iconify/react';
import ROUTES from 'src/constants/routes';
import { useHistory } from 'react-router-dom';
import useButtonStyle from 'src/styles/button';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import appointDirector from 'src/assets/requests/Appointment of Director.svg';
import appointAdmin from 'src/assets/requests/Appointment of Administrator.svg';

const OFFICERS_LIST = [
  {
    name: 'Change Director Particulars',
    icon: appointDirector,
    route: ROUTES.CORPSEC_REQ_DIRECTOR_LIST,
  },
  {
    name: 'Change Shareholder Particulars',
    icon: appointAdmin,
    route: ROUTES.CORPSEC_REQ_SHAREHOLDER_LIST,
  },
];

const ChangePersonalParticulars = () => {
  // const classes = useStyles();
  const buttonClasses = useButtonStyle();

  const history = useHistory();

  const renderList = () =>
    OFFICERS_LIST.map((r, index) => (
      <ListItem
        key={`${r.name}${index}`}
        style={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          minHeight: '60px',
          marginBottom: '15px',
        }}
        onClick={() => {
          history.push(r.route);
        }}
        button
      >
        <ListItemIcon>
          <img src={r.icon} height={40} />
        </ListItemIcon>
        <ListItemText primary={r.name} />
        <ListItemSecondaryAction>
          <IconButton>
            <ChevronRightIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ));

  return (
    <>
      <Button
        startIcon={<ChevronLeftIcon />}
        className={buttonClasses.backButton}
        onClick={() => {
          history.push(ROUTES.CORPSEC_REQ);
        }}
      >
        Back
      </Button>
      <BreadcrumbsNav
        pathList={[
          { path: 'Requests', route: ROUTES.CORPSEC_REQ },
          {
            path: 'Change Personal Particulars',
          },
        ]}
        styles={{ paddingTop: 30, paddingBottom: 30 }}
      />
      <Box py={1} />
      <Typography variant="h6" color="secondary">
        Change Personal Particulars
      </Typography>
      <List
        style={{
          width: '100%',
        }}
      >
        {renderList()}
      </List>
    </>
  );
};

export default ChangePersonalParticulars;
