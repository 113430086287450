import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { generate } from 'shortid';
import { Skeleton } from '@material-ui/lab';

const EnhancedIcon = ({ icon, iconStyle, ...props }) => {
  return (
    <SvgIcon
      viewBox="0 0 24 24"
      component={icon}
      style={{
        fill: iconStyle?.fill ? iconStyle.fill : 'black',
        ...iconStyle,
      }}
      {...props}
    />
  );
};

export default EnhancedIcon;
