import { useContext, useState, createContext } from 'react';
import { Config } from 'src/config';
import { APIRequest } from 'src/utils/api';

const cartContext = createContext({
  carts: [],
  error: '',
  loading: false,
  submitting: false,
  AddWebDev: () => Promise.resolve({}),
  ListWebDev: () => Promise.resolve({}),
  ListWebDevById: () => Promise.resolve({}),
  UpdateWebDev: () => Promise.resolve({}),
  DomainChecker: () => Promise.resolve({}),
});

export function useProvideWebDev() {
  const [carts, setCarts] = useState([]);
  const [error, setError] = useState();
  const [loading, setloading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [order, setOrder] = useState({});
  const [webdevs, setWebdevList] = useState([]);
  const [webdev, setWebdev] = useState([]);
  const [domainAvail, setDomainAvail] = useState();

  const AddWebDev = async (info = {}) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setSubmitting,
      path: `${Config.apiURL}/webdev/webdev`,
      method: 'post',
      body: info,
    });
    return res;
  };

  const ListWebDev = async () => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setSubmitting,
      path: `${Config.apiURL}/webdev/webdev`,
      method: 'get',
    });
    if (res.status === 'success') {
      setWebdevList(res.body.webdev);
    }
    return res;
  };

  const ListWebDevById = async (id) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setSubmitting,
      path: `${Config.apiURL}/webdev/webdev/${id}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setWebdev(res.body.webdev);
    }
    return res;
  };

  const UpdateWebDev = async (info = {}, id) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setSubmitting,
      path: `${Config.apiURL}/webdev/webdev/${id}`,
      method: 'put',
      body: info,
    });
    return res;
  };

  const DomainChecker = async (domainname) => {
    const res = await APIRequest({
      setError: setError,
      setLoading: setSubmitting,
      path: `${Config.apiURL}/thirdparty/domaincheck?domain_name=${domainname}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setDomainAvail(res.body.data.available);
    }
    if (res.status === 'rejected') {
      setDomainAvail(false);
    }
    return res;
  };

  return {
    carts,
    error,
    loading,
    submitting,
    webdevs,
    webdev,
    domainAvail,
    AddWebDev,
    ListWebDev,
    ListWebDevById,
    UpdateWebDev,
    DomainChecker,
  };
}

export const useCartContext = () => useContext(cartContext);

export function ProvideWebDev({ children }) {
  const cart = useProvideWebDev();
  return <cartContext.Provider value={cart}>{children}</cartContext.Provider>;
}
