import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    alternativeLabel: {
      '& .MuiStepConnector-alternativeLabel': {
        top: 20,
      },
    },
    color: {
      '& .MuiStepLabel-label': {
        color: '#A21D23',
      },
      '& .MuiStepIcon-root.MuiStepIcon-active': {
        color: '#A21D23',
        stroke: 'transparent',
      },
      '& .MuiSvgIcon-root': {
        stroke: '#A21D23',
        // fontSize: '1.4em',
        overflow: 'inherit',
        height: '1.4em',
        width: '1.4em',
      },
      '& .MuiStepIcon-root': {
        color: 'transparent',
      },
    },
  }),
);
