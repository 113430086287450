import {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { APIRequest } from 'src/utils/api';
import { Config } from 'src/config';

const companyContext = createContext({
  agm: {},
  businessActivity: {},
  companyAddressess: [],
  financialYearEnd: {},

  companyError: '',
  companyLoading: false,
  companySubmitting: false,
  GetAGM: () => Promise.resolve({}),
  GetBusinessActivity: () => Promise.resolve({}),
  GetFinancialYearEnd: () => Promise.resolve({}),
  ListCompanys: () => Promise.resolve({}),
  ReadCompany: () => Promise.resolve({}),
  ReadCompanyAddressess: () => Promise.resolve({}),
});

export function useProvideCompany() {
  const [agm, setAgm] = useState({});
  const [businessActivity, setBusinessActivity] = useState({});
  const [companyAddressess, setCompanyAddressess] = useState([]);
  const [financialYearEnd, setFinancialYearEnd] = useState({});

  const [companyError, setCompanyError] = useState();
  const [companyLoading, setCompanyLoading] = useState(false);
  const [companySubmitting, setCompanySubmitting] = useState(false);

  const GetAGM = async (companyID) => {
    const res = await APIRequest({
      setError: setCompanyError,
      setLoading: setCompanyLoading,
      path: `${Config.apiURL}/company/timeline/company/${companyID}/event/1/latest`,
      method: 'get',
    });
    if (res.status === 'success') {
      setAgm(res.body.timeline);
    }
    return res;
  };

  const GetBusinessActivity = async (companyID) => {
    const res = await APIRequest({
      setError: setCompanyError,
      setLoading: setCompanyLoading,
      path: `${Config.apiURL}/bizactivity/companies/${companyID}/businessactivities`,
      method: 'get',
    });
    if (res.status === 'success') {
      setBusinessActivity(res.body.business_activities);
    }
    return res;
  };

  const GetFinancialYearEnd = async (companyID) => {
    const res = await APIRequest({
      setError: setCompanyError,
      setLoading: setCompanyLoading,
      path: `${Config.apiURL}/company/timeline/company/${companyID}/event/2`,
      method: 'get',
    });
    if (res.status === 'success') {
      setFinancialYearEnd(res.body.timeline[0]);
    }
    return res;
  };

  const ReadCompanyAddresses = async (companyID) => {
    const res = await APIRequest({
      setError: setCompanyError,
      setLoading: setCompanyLoading,
      path: `${Config.apiURL}/address/companies/${companyID}/addresses`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCompanyAddressess(res.body.companyAddresses);
    }
    return res;
  };

  return {
    agm,
    businessActivity,
    companyAddressess,
    financialYearEnd,
    companyError,
    companyLoading,
    companySubmitting,
    GetAGM,
    GetBusinessActivity,
    GetFinancialYearEnd,
    ReadCompanyAddresses,
  };
}

export const useCompanyContext = () => useContext(companyContext);

export function ProvideCompany({ children }) {
  const company = useProvideCompany();
  return (
    <companyContext.Provider value={company}>
      {children}
    </companyContext.Provider>
  );
}
