import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import ROUTES from 'src/constants/routes';
import { makeStyles } from '@material-ui/core/styles';
import ServiceLayout from 'src/components/ServiceLayout';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import useButtonStyle from 'src/styles/button';
import { Theme } from 'src/constants/list';

const useStyles = makeStyles((theme) => ({}));

const WebServiceTheme = () => {
  const history = useHistory();
  const params = useParams();
  const buttonClasses = useButtonStyle();
  const selectedTheme = Theme.find((theme) => theme.id === params.themeid);

  const renderChild = () => {
    return (
      <>
        <Button
          startIcon={<ChevronLeftIcon />}
          className={buttonClasses.backButton}
          onClick={() => {
            history.push(
              ROUTES.WEBSERVICE_THEME(params.packageid, params.categoryid),
            );
          }}
          style={{ width: 'fit-content' }}
        >
          Back
        </Button>
        <div>
          <iframe src={selectedTheme.src} height="550%" width="100%" />
        </div>
      </>
    );
  };

  return (
    <ServiceLayout
      title="Web Services"
      showAppbar={false}
      child={renderChild()}
    />
  );
};

export default WebServiceTheme;
