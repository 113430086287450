import React from 'react';
import Box from '@material-ui/core/Box';

const Empty = (props) => {
  const { children } = props;
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="200px"
      // height="100%"
      width="100%"
    >
      {children}
    </Box>
  );
};

export default Empty;
