import {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { Config } from 'src/config';
import { APIRequest } from 'src/utils/api';

const shareholderContext = createContext({
  shareholder: [],
  shareholders: [],
  newshareholder: '',
  shareholderError: '',
  shareholderLoading: false,
  shareholderSubmitting: false,
  ListShareholders: () => Promise.resolve({}),
  GetShareholderByUserID: () => Promise.resolve({}),
  ApplyNewShareholder: () => Promise.resolve({}),
});

export function useProvideShareholder() {
  const [shareholders, setShareholders] = useState([]);
  const [shareholder, setShareholder] = useState([]);
  const [newshareholder, setNewShareholder] = useState();
  const [shareholderError, setShareholderError] = useState();
  const [shareholderLoading, setShareholderLoading] = useState(false);
  const [shareholderSubmitting, setShareholderSubmitting] = useState(false);

  const ListShareholders = async (companyID) => {
    const res = await APIRequest({
      setError: setShareholderError,
      setLoading: setShareholderLoading,
      path: `${Config.apiURL}/shareholder/companies/${companyID}/shareholders`,
      method: 'get',
    });
    if (res.status === 'success') {
      setShareholders(res.body.shares);
    }
    return res;
  };

  const GetShareholderByUserID = async (userID) => {
    const res = await APIRequest({
      setError: setShareholderError,
      setLoading: setShareholderLoading,
      path: `${Config.apiURL}/shareholder/users/${userID}/shareholders`,
      method: 'get',
    });
    if (res.status === 'success') {
      setShareholder(res.body.shares);
    }
    return res;
  };

  const ApplyNewShareholder = async (applicationform) => {
    const res = await APIRequest({
      setError: setShareholderError,
      setLoading: setShareholderLoading,
      path: `${Config.apiURL}/application/newshareholderapplications`,
      method: 'post',
      body: applicationform,
    });
    if (res.status === 'success') {
      setNewShareholder(res.body.applications);
    }
    return res;
  };

  return {
    shareholder,
    shareholders,
    newshareholder,
    shareholderError,
    shareholderLoading,
    shareholderSubmitting,
    ListShareholders,
    GetShareholderByUserID,
    ApplyNewShareholder,
  };
}

export const useShareholderContext = () => useContext(shareholderContext);

export function ProvideShareholder({ children }) {
  const shareholder = useProvideShareholder();
  return (
    <shareholderContext.Provider value={shareholder}>
      {children}
    </shareholderContext.Provider>
  );
}
