import React, { useState, useContext } from 'react';
import { Box, Container } from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import { WebServicePackage } from 'src/constants/list';
import ServiceLayout from 'src/components/ServiceLayout';
import ImageTitleList from 'src/components/ImageTitleList/ImageTitleList';
import { AllCategory } from 'src/constants/list';
import { useParams } from 'react-router-dom';
import { ConfigContext } from 'src/contexts';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({}));

const WebServiceCategory = () => {
  const classes = useStyles();
  const params = useParams();
  const { xsBelow } = useBreakpoints();
  const [selected, setSelected] = useState();
  const history = useHistory();
  const { addWebInfo } = useContext(ConfigContext);

  const renderChild = () => {
    return (
      <Container>
        <Box p={4} />
        <ImageTitleList
          title="All Category"
          itemData={AllCategory}
          overlay
          onClick={(item) => setSelected(item.id)}
        />
        <Box p={3} />
        <MzButton
          title={'Continue'}
          disabled={!selected}
          onClick={() => {
            addWebInfo({ webdev_category_id: selected });
            history.push(ROUTES.WEBSERVICE_THEME(params.packageid, selected));
          }}
        />
        <Box p={3} />
      </Container>
    );
  };

  return <ServiceLayout title="Web Services" child={renderChild()} />;
};

export default WebServiceCategory;
