import { Box, Typography, Button, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import useBreakpoints from 'src/hooks/useBreakpoints';
import MenuIcon from '@material-ui/icons/Menu';
import GetStartedManual from 'src/components/GetStartedManual';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

const GetStartedLayout = ({
  children,
  toggleShowMenu,
  step,
  setStep,
  getStarted,
  header,
}) => {
  const { smBelow } = useBreakpoints();
  const theme = useTheme();

  return (
    <>
      <Box pt={smBelow ? 1 : 4} pb={1} px={smBelow ? 1 : 4}>
        <Box
          display="flex"
          flexDirection={smBelow ? 'column' : 'row'}
          alignItems="flex-start"
        >
          {smBelow && (
            <Box
              display="flex"
              flexDirection={'row'}
              alignItems="center"
              width="100%"
            >
              <IconButton
                size="medium"
                onClick={() => {
                  if (toggleShowMenu) toggleShowMenu();
                }}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
              <Box flexGrow={1} />
              {step !== 1 && smBelow && (
                <Button
                  startIcon={<ChevronLeftIcon />}
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Back
                </Button>
              )}
            </Box>
          )}

          {!smBelow && (
            <>
              <IconButton
                size="medium"
                onClick={() => {
                  if (toggleShowMenu) toggleShowMenu();
                }}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
              {step !== 1 && smBelow && (
                <Button
                  startIcon={<ChevronLeftIcon />}
                  onClick={() => {
                    setStep(step - 1);
                  }}
                >
                  Back
                </Button>
              )}
            </>
          )}

          {header && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              pb={2}
              pl={smBelow ? 2 : 1}
            >
              {header}
            </Box>
          )}

          <Box flexGrow={1} />
          {step !== 1 && !smBelow && (
            <Button
              startIcon={<ChevronLeftIcon />}
              onClick={() => {
                setStep(step - 1);
              }}
            >
              Back
            </Button>
          )}
        </Box>
      </Box>
      {step === 1 && (
        <Box px={smBelow ? 0 : 4}>
          <GetStartedManual
            onClick={() => {
              setStep(2);
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              });
            }}
            {...getStarted}
          />
        </Box>
      )}
      {children}
    </>
  );
};

export default GetStartedLayout;
