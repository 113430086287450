import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    backButton: {
      fontWeight: 'bold',
      color: '#DEAD00',
      textTransform: 'initial',
    },
    badgeSizeLarge: {
      padding: '10px 10px',
    },
  }),
);
