import React, { useEffect, forwardRef, useContext } from 'react';
import { Typography, Box, Paper } from '@material-ui/core';
import useStyles from './BoardRoom.styles';
import useBreakpoints from 'src/hooks/useBreakpoints';
import MaterialTable from 'material-table';
import FilterList from '@material-ui/icons/FilterList';
import { useProvideBoardroom } from 'src/hooks/useBoardroom';
import TitleLayout from 'src/components/TitleLayout';
import ROUTES from 'src/constants/routes';
import DisplayBox from 'src/components/DisplayBox';
import DetailsIcon from '@material-ui/icons/Details';
import { ConfigContext } from 'src/contexts';

const BoardRoom = () => {
  const classes = useStyles();
  const { smBelow } = useBreakpoints();
  const { ListBoardrooms, boardrooms } = useProvideBoardroom();
  const { company } = useContext(ConfigContext);

  useEffect(() => {
    ListBoardrooms(company.company_id);
  }, [company.company_id]);

  const mapBoardrooms = () => {
    return boardrooms.map((board, index) => ({
      name: board.first_name + ' ' + board.last_name,
      role: board.company_role_name,
      status: 1,
      appointmentDate: board.date_of_appointment.substring(0, 10),
      resignationDate: board.date_of_appointment.substring(0, 10),
    }));
  };

  const renderDisplayBox = () => {
    return (
      <DisplayBox
        itemList={[
          { number: boardrooms.length, label: 'Serving Board Member(s)' },
          { number: 0, label: 'Pending Board Member(s)' },
          { number: 0, label: 'Post Board Member(s)' },
        ]}
      />
    );
  };

  return (
    <TitleLayout
      title="Boardroom"
      pathList={[
        { path: 'Home', route: ROUTES.DASHBOARD },
        {
          path: 'Corporate Secretary',
        },
        {
          path: 'Boardroom',
          color: 'primary',
        },
      ]}
    >
      {smBelow && (
        <>
          {renderDisplayBox()}
          <Box p={1} />
        </>
      )}
      <Paper
        style={{
          width: '100%',
          borderRadius: 10,
          padding: smBelow ? 20 : 40,
        }}
      >
        {!smBelow && (
          <>
            {renderDisplayBox()}
            <Box p={3} />
          </>
        )}
        <Typography variant="h5" style={{ color: '#979797' }}>
          Boardroom Table
        </Typography>
        <Box p={1} />
        <MaterialTable
          options={{
            filtering: false,
            paging: false,
            toolbar: false,
            draggable: false,
            headerStyle: {
              backgroundColor: '#F9F9F9',
            },
            rowStyle: (x) => {
              if (x.tableData.id % 2) {
                return { backgroundColor: '#FFFFFF' };
              }
              return { backgroundColor: '#F9F9F9' };
            },
          }}
          style={{ width: '100%' }}
          icons={{
            SortArrow: forwardRef((props, ref) => (
              <DetailsIcon {...props} ref={ref} fontSize="small" />
            )),
            Filter: forwardRef((props, ref) => (
              <FilterList {...props} ref={ref} />
            )),
          }}
          columns={[
            { title: 'DIRECTOR NAME', field: 'name' },
            { title: 'USER ROLE', field: 'role' },
            {
              title: 'STATUS',
              field: 'status',
              lookup: { 1: 'Pending', 2: 'Passed', 3: 'Failed' },
            },
            {
              title: 'APPOINTMENT DATE',
              field: 'appointmentDate',
            },
            {
              title: 'RESIGNATION DATE',
              field: 'resignationDate',
            },
          ]}
          data={mapBoardrooms()}
        />
        <Box p={smBelow ? 1 : 5} />
      </Paper>
    </TitleLayout>
  );
};

export default BoardRoom;
