import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { Field } from 'formik';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
  },
}));

function FormRadioGroup({ name, label, options = [], ...props }) {
  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <>
            <FormControl error={meta.touched && !!meta.error}>
              <Typography
                variant="body1"
                align="left"
                color={meta.touched && !!meta.error ? 'error' : 'textPrimary'}
              >
                {label}
              </Typography>
              <RadioGroup {...field} {...props}>
                {options.map((opt) => (
                  <FormControlLabel
                    key={opt.value}
                    value={opt.value}
                    control={<Radio color="primary" />}
                    label={opt.label}
                  />
                ))}
              </RadioGroup>
              {meta.touched && !!meta.error && (
                <FormHelperText>{meta.error}</FormHelperText>
              )}
            </FormControl>
          </>
        );
      }}
    </Field>
  );
}

export default FormRadioGroup;
