import React, { useState } from 'react';
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
  Box,
} from '@material-ui/core';
import useStyles from './ImageTitleList.styles';
import useBreakpoints from '../../hooks/useBreakpoints';
import { useHistory } from 'react-router-dom';
import { Icon } from '@iconify/react';

const ImageTitleList = ({
  itemData,
  title,
  overlay,
  cols,
  centeredTitle,
  styles,
  onClick,
  onView,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { mdBelow } = useBreakpoints();
  const [selected, setSelected] = useState();

  return (
    <Box style={styles}>
      {!!title && (
        <Typography variant="h5" align="left" className={classes.mainTitle}>
          {title}
        </Typography>
      )}
      <ImageList
        imgheight={200}
        gap={15}
        cols={cols ? cols : mdBelow ? 2 : 4}
        style={{ cursor: 'pointer' }}
      >
        {itemData.map((item, index) => (
          <ImageListItem
            key={'ImageTitleList' + index}
            onClick={() => {
              onClick(item);
              setSelected(index);
            }}
          >
            {overlay && <div className={classes.backdrop} />}
            <div className={classes.hover} />
            <div className={selected === index && classes.selected} />
            <div
              className={classes.background}
              style={{
                backgroundImage: `url(${item.img})`,
              }}
            />
            <ImageListItemBar
              title={item.title}
              actionIcon={
                onView && (
                  <Icon
                    icon="carbon:view"
                    color="white"
                    width="40"
                    height="40"
                    style={{ paddingRight: 10 }}
                    onClick={() => onView(item)}
                  />
                )
              }
              classes={{
                root: classes.titleBar,
                title: centeredTitle ? classes.centeredTitle : classes.title,
              }}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Box>
  );
};

export default ImageTitleList;
