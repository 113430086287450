import {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { Config } from 'src/config';
import { APIRequest } from 'src/utils/api';

const dataContext = createContext({
  countries: [],
  idTypes: [],
  ssic: [],
  dataError: '',
  dataLoading: false,
  dataSubmitting: false,
  ListCountries: () => Promise.resolve({}),
  ListIDType: () => Promise.resolve({}),
  ListSSIC: () => Promise.resolve({}),
});

export function useProvideData() {
  const [countries, setCountries] = useState([]);
  const [idTypes, setIDTypes] = useState([]);
  const [ssic, setSSIC] = useState([]);
  const [dataError, setDataError] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [dataSubmitting, setDataSubmitting] = useState(false);

  const ListCountries = async () => {
    const res = await APIRequest({
      setError: setDataError,
      setLoading: setDataLoading,
      path: `${Config.dataURL}/country`,
      method: 'get',
    });
    if (res.status === 'success') {
      setCountries(
        res.body.countries.map((c) => {
          return {
            ...c,
            label: c.country_name,
            value: c.country_id,
          };
        }),
      );
    }
    return res;
  };

  const ListIDType = async () => {
    const res = await APIRequest({
      setError: setDataError,
      setLoading: setDataLoading,
      path: `${Config.dataURL}/identificationtype`,
      method: 'get',
    });
    if (res.status === 'success') {
      setIDTypes(
        res.body.identificationtypes.map((type) => {
          return {
            label: type.identification_name,
            value: type.identification_type_id,
          };
        }),
      );
    }
    return res;
  };

  const ListSSIC = async (search = '') => {
    const res = await APIRequest({
      setError: setDataError,
      setLoading: setDataLoading,
      path: `${Config.apiURL}/common/ssiccode?search=${search}`,
      method: 'get',
    });
    if (res.status === 'success') {
      setSSIC(
        res.body.ssic_codes.map((ssic) => {
          return {
            label: ssic.ssic_name,
            value: ssic.ssic_id,
          };
        }),
      );
    }
    return res;
  };

  return {
    countries,
    idTypes,
    ssic,
    dataError,
    dataLoading,
    dataSubmitting,
    ListCountries,
    ListIDType,
    ListSSIC,
  };
}

export const useDataContext = () => useContext(dataContext);

export function ProvideData({ children }) {
  const data = useProvideData();
  return <dataContext.Provider value={data}>{children}</dataContext.Provider>;
}
