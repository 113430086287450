import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) =>
  createStyles({
    background: {
      position: 'relative',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      zIndex: -2,
      minWidth: 350,
      height: 200,
    },
    title: {
      color: '#FFFFFF',
      textAlign: 'left',
      fontWeight: 'bold',
      whiteSpace: 'normal',
      fontSize: 18,
    },
    subtitle: {
      color: '#FFFFFF',
      textAlign: 'left',
      whiteSpace: 'normal',
      fontSize: 14,
    },
    backdrop: {
      display: 'flex',
      alignItems: 'flex-end',
      backgroundColor: 'rgba(118, 118, 118, 0.5)',
      width: '100%',
      height: '100%',
      paddingBottom: 20,
    },
    itembar: {
      backgroundColor: 'transparent',
    },
    gap: {
      paddingRight: 15,
    },
  }),
);
