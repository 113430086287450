import {
  useContext,
  useState,
  createContext,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';
import { APIRequest } from 'src/utils/api';
import { Config } from 'src/config';

const oAuthContext = createContext({
  oAuthError: '',
  oAuthLoading: false,
  Authorize: () => Promise.resolve({}),
});

export function useProvideOAuth() {
  const [oAuthError, setOAuthError] = useState();
  const [oAuthLoading, setOAuthLoading] = useState(false);

  const Authorize = async (email) => {
    window.location.href = `${Config.oAuthURL}/oauth/authorize?client_id=jgyaFRqB8Xz6uzZhjUaUGKj&redirect_uri=https%3A%2F%2Fwww.appteststg.meyzer360.com%2F&email=${email}`;
  };

  return {
    oAuthError,
    oAuthLoading,
    Authorize,
  };
}

export const useOAuthContext = () => useContext(oAuthContext);

export function ProvideOAuth({ children }) {
  const oAuth = useProvideOAuth();
  return (
    <oAuthContext.Provider value={oAuth}>{children}</oAuthContext.Provider>
  );
}
