import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Typography,
  Icon,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const ConfirmationDialog = ({
  title,
  isVisible,
  onBackdropPress,
  confirmButtonAction,
  cancelButtonAction,
  confirmButtonLabel,
  cancelButtonLabel,
  confirmButtonStyles,
  cancelButtonStyles,
  type = 'success',
  showIcon = true,
  icon,
  maxWidth = 'sm',
}) => {
  return (
    <Dialog
      maxWidth={maxWidth}
      open={isVisible}
      onClose={onBackdropPress}
      fullWidth
    >
      <Box
        padding={3}
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Box display="flex" alignItems="center" flexDirection="row">
          {showIcon &&
            type === 'success' &&
            (icon ? (
              <icon />
            ) : (
              <Icon fontSize="large" style={{ color: 'green' }}>
                <CheckCircleIcon fontSize="large" />
              </Icon>
            ))}
          <Box p={1} />
          <Typography variant="h5" align="center">
            {title}
          </Typography>
        </Box>

        <Box p={2} flexGrow={1} />
        <DialogActions
          style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
        >
          <Box flexGrow={1} />
          {cancelButtonLabel && (
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={cancelButtonAction}
            >
              {cancelButtonLabel}
            </Button>
          )}
          {confirmButtonLabel && (
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={confirmButtonAction}
            >
              {confirmButtonLabel}
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ConfirmationDialog;
