import React from 'react';
import { Typography, Box, Badge, Paper, Divider } from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import { useTheme, withStyles } from '@material-ui/core/styles';
import BreadcrumbsNav from 'src/components/BreadcrumbsNav';
import useBreakpoints from 'src/hooks/useBreakpoints';

const StyledBadge = withStyles((theme) => ({
  badge: {
    left: 5,
    bottom: 12,
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 4px',
    // paddingLeft: '30px',
  },
}))(Badge);

const GetStartedManual = ({
  one = {
    content: [],
  },
  two = {
    content: [],
  },
  three = {
    content: [],
  },
  four = {
    content: [],
  },
  five = {
    content: [],
  },
  onClick,
}) => {
  const theme = useTheme();
  const { smBelow } = useBreakpoints();

  const generateSteps = () => {
    return three.content.map((content, index) => {
      return {
        component: (
          <StyledBadge
            color="primary"
            badgeContent={index + 1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            key={index}
          >
            <Box pl={2} />
            <Typography variant="h6">{content}</Typography>
          </StyledBadge>
        ),
      };
    });
  };

  const renderContent = () => (
    <>
      {smBelow && <Divider flexItem style={{ height: '1px' }} />}
      <Box p={3}>
        <Typography variant="h4">What can I do in this request?</Typography>
        <ul style={{ color: theme.palette.primary.main }}>
          {one.content.map((content) => (
            <li key={content}>
              <Typography align="justify" variant="body2" color="textPrimary">
                {content}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Divider flexItem style={{ height: '1px' }} />
      <Box p={3}>
        <Typography variant="h4">
          What information/document(s) do I need?
        </Typography>
        <ul style={{ color: theme.palette.primary.main }}>
          {two.content.map((content) => (
            <li key={content}>
              <Typography align="justify" variant="body2" color="textPrimary">
                {content}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Divider flexItem style={{ height: '1px' }} />

      <Box p={3}>
        <Typography variant="h4">What are the steps?</Typography>
        <Box pl={1} pt={3}>
          <BreadcrumbsNav pathList={generateSteps()} />
        </Box>
      </Box>
      <Divider flexItem style={{ height: '1px' }} />

      <Box p={3}>
        <Typography variant="h4">How much will it cost?</Typography>
        <ul style={{ color: theme.palette.primary.main }}>
          {four.content.map((content) => (
            <li key={content}>
              <Typography align="justify" variant="body2" color="textPrimary">
                {content}
              </Typography>
            </li>
          ))}
        </ul>
      </Box>
      <Divider flexItem style={{ height: '1px' }} />
      <Box p={3}>
        <Typography variant="h4">How long does it take?</Typography>
        <ul style={{ color: theme.palette.primary.main }}>
          {five.content.map((content) => (
            <li key={content}>
              <Typography align="justify" variant="body2" color="textPrimary">
                {content}
              </Typography>
            </li>
          ))}
        </ul>
        <Box p={3} />
        <MzButton
          fullWidth={smBelow ? true : false}
          title={'Get Started'}
          onClick={() => {
            if (onClick) onClick();
          }}
        />
      </Box>
    </>
  );
  return smBelow ? (
    renderContent()
  ) : (
    <Paper
      elevation={0}
      variant="outlined"
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        borderRadius: smBelow ? '0px' : '12px',
      }}
    >
      {renderContent()}
    </Paper>
  );
};

export default GetStartedManual;
