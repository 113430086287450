import React, { useEffect, useState, useContext } from 'react';
import { Box, Container } from '@material-ui/core';
import MzButton from 'src/components/MzButton';
import ROUTES from 'src/constants/routes';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { makeStyles } from '@material-ui/core/styles';
import ServiceLayout from 'src/components/ServiceLayout';
import Stepper from 'src/components/Stepper';
import { FormFileDropzone } from 'src/components/Form';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useHistory, useParams } from 'react-router-dom';
import { ConfigContext } from 'src/contexts';
import { useProvideWebDev } from 'src/hooks/useWebDev';

const useStyles = makeStyles((theme) => ({}));

const validationSchema = Yup.object({
  logoFiles: Yup.array().min(1),
});

const tempSteps = [
  {
    id: 1,
    label: 'Domain',
    route: ROUTES.CREATEWEBSITE,
  },
  {
    id: 2,
    label: 'Content',
    route: ROUTES.CREATEWEBSITE_CONTENT,
  },
  {
    id: 3,
    label: 'Corporate Identity',
    route: ROUTES.CREATEWEBSITE_CORPIDENTITY,
  },
  {
    id: 4,
    label: 'Images',
    route: ROUTES.CREATEWEBSITE_IMAGES,
  },
];

const CorporateIdentity = () => {
  const classes = useStyles();
  const [step, setStep] = useState(2);
  const history = useHistory();
  const params = useParams();
  const { addWebInfo, webInfo } = useContext(ConfigContext);
  const { ListWebDevById, webdev } = useProvideWebDev();

  useEffect(() => {
    if (!webInfo.webdev_id) {
      ListWebDevById(params.webdevid);
    }
  }, []);

  const formikOnSubmit = async (values, actions) => {
    try {
      if (values.logoFiles.length > 0) {
        addWebInfo({ corporate_identity_attachment: values.logoFiles[0] });
        history.push(ROUTES.CREATEWEBSITE_IMAGES(params.webdevid));
      }
    } catch (err) {
      showErrorSnackbar('Error uploading documents');
      return;
    }
  };

  const renderChild = () => {
    return (
      <Container>
        <Box p={3} />
        <Stepper
          steps={tempSteps}
          providedStep={step}
          setProvidedStep={(step) => {
            setStep(step);
            history.push(tempSteps[step].route(params.webdevid));
          }}
        />
        <Box p={3} />
        <Formik
          initialValues={{
            logoFiles: [],
          }}
          onSubmit={formikOnSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <FormFileDropzone
                name="logoFiles"
                labelText="Corporate Identity (LOGO)"
                containerWidth="70%"
              />
              <Box p={7} />
              <MzButton
                title={'Continue'}
                disabled={!(props.isValid && props.dirty)}
                type="submit"
                onClick={() => {}}
              />
            </Form>
          )}
        </Formik>
      </Container>
    );
  };

  return <ServiceLayout title="Create Website" child={renderChild()} />;
};

export default CorporateIdentity;
